import React, { useCallback } from "react";
import { Service } from "../../../../types/Service";
import {
  EvaporatorProductCapacities,
  EvaporatorProductCapacity,
} from "../../../../types/EvaporatorProductCapacity";
import EvaporatorProductItem from "./EvaporatorProductItem";
import { ProductFilter } from "../../../../types/ProductFilter";
import FiltersLoadingPlaceholder from "../../../../components/Filters/FiltersLoadingPlaceholder";
import TableLoadingPlaceholder from "../../../../components/Table/TableLoadingPlaceholder";
import Error from "../../../../components/Error/Error";
import TablePlaceholder from "../../../../components/TablePlaceholder/TablePlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { default as actionSelector } from "../../../duck/selectors";
import {
  sortProductFilterArray,
  sortFilterOptionArray,
} from "../../../../helpers/sortArray";

interface Props {
  evaporatorService: Service<EvaporatorProductCapacities>;
  handleEvaporatorSelect: (
    selectedCondensingUnit: EvaporatorProductCapacity,
    triggerLoading: boolean
  ) => void;
  selectedEvaporator: EvaporatorProductCapacity;
}

const EvaporatorProductItemContainer: React.FC<Props> = ({
  evaporatorService,
  handleEvaporatorSelect,
  selectedEvaporator,
}) => {
  const dispatch = useDispatch();

  const productFilters: ProductFilter[] = useSelector(
    (state: any) => state.product.evaporatorFilters
  );

  if (productFilters && productFilters.length > 0) {
    productFilters.sort((a, b) => sortProductFilterArray(a, b));
    productFilters.forEach((pf) => {
      pf.options.sort((a, b) => sortFilterOptionArray(a, b));
    });
  }

  const selectEvaporatorFilter = useCallback(
    (filterType: string, selection: string) => {
      dispatch(actionSelector.selectEvaporatorFilter(filterType, selection));
    },
    [dispatch]
  );

  const resetEvaporatorFilters = () => {
    dispatch(actionSelector.resetEvaporatorFilters());
  };

  return (
    <div>
      {evaporatorService.status === "loading" && (
        <>
          <FiltersLoadingPlaceholder />
          <TableLoadingPlaceholder />
        </>
      )}
      {evaporatorService.status === "bad_request" && (
        <div>Enter product filter values.</div>
      )}
      {evaporatorService.status === "init" && (
        <TablePlaceholder
          heading="Nothing to display"
          subheading="Add details of equipments to see matching products"
        />
      )}
      {evaporatorService.status === "loaded" && (
        <EvaporatorProductItem
          products={evaporatorService.payload.results}
          selectedEvaporator={selectedEvaporator}
          handleEvaporatorSelect={handleEvaporatorSelect}
          evaporatorFilters={productFilters}
          filterSelections={evaporatorService.payload.filterSelections}
          setFilterValues={selectEvaporatorFilter}
          resetEvaporatorFilters={resetEvaporatorFilters}
        />
      )}
      {evaporatorService.status === "error" && <Error />}
    </div>
  );
};

export default EvaporatorProductItemContainer;
