import React from "react";
import { A } from "hookrouter";
import styles from "./NavigationBar.module.css";
import Image from "../Image/Image";

export interface NavigationBarProps {
  linkName: string;
  linkURL: string;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ linkName, linkURL }) => {
  return (
    <div className={styles.navigationBarContainer}>
      <A href={linkURL} className={styles.navigationLink}>
        <Image filename="arrow_back" altText={`Go back to ${linkURL}`} />
        <span className={styles.linkText}>{linkName}</span>
      </A>
    </div>
  );
};

export default NavigationBar;
