import React, { FC } from "react";
import { useSelector } from "react-redux";
import InfoText from "../../../components/InfoText/InfoText";
import ForkliftDetailsContainer from "./ForkliftDetails/ForkliftDetailsContainer";
import LightingDetailsContainer from "./LightingDetails/LightingDetailsContainer";
import PeopleDetailsContainer from "./PeopleDetails/PeopleDetailsContainer";

const AdditionalLoadsContainer: FC = () => {
  const additionalLoadsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.additionalLoadsEnabled;
  });

  return (
    <div data-testid="additional-loads">
      {additionalLoadsEnabled && (
        <>
          <div>
            <p className="section-description">
              Provide additional details that impact your heat load.
            </p>
          </div>

          <PeopleDetailsContainer />

          <LightingDetailsContainer />

          <ForkliftDetailsContainer />
        </>
      )}

      {!additionalLoadsEnabled && (
        <InfoText testid="no-additional-loads">
          Add your Additional loads after you’ve defined your room and
          transmission details.
        </InfoText>
      )}
    </div>
  );
};

export default AdditionalLoadsContainer;
