import types from "./types";

const INITIAL_STORE_STATE: any = {
  room: {},
  roomDimensions: {
    walls: [{}, {}, {}, {}],
    ceiling: {},
    floor: { surfaceArea: 0 },
    volume: 0,
  },
  walls: [],
  ceiling: {},
  floor: {},
  doors: [],
  productLines: [],
  safetyAndRuntime: {
    equipmentRuntime: 0,
    fanFactor: 0,
    safetyFactor: 0,
    otherLoads: 0,
  },
  heatLoadCapacity: {
    productLoad: { sensible: 0, latent: 0 },
    transmissionLoad: { sensible: 0, latent: 0 },
    infiltrationLoad: { sensible: 0, latent: 0 },
    internalLoad: { sensible: 0, latent: 0 },
    totalLoad: { sensible: 0, latent: 0 },
    otherLoad: { total: 0 },
    safetyLoad: { safetyFactor: 0, total: 0 },
    fanLoad: { fanFactor: 0, total: 0 },
    runTimeTotal: { runtime: 0, total: 0 },
  },
  formHeatLoadFilters: {},
  formSections: {
    roomDetailsEnabled: true,
    transmissionDetailsEnabled: false,
    doorDetailsEnabled: false,
    productDetailsEnabled: false,
    additionalLoadsEnabled: false,
    safetyAndRuntimeDetailsEnabled: true,
  },
};

const heatLoadReducer = (state = INITIAL_STORE_STATE, action: any) => {
  switch (action.type) {
    case types.SAVE_STANDARD_ROOM_DETAILS: {
      return {
        ...state,
        room: action.room,
      };
    }
    case types.SAVE_WALL_DETAILS: {
      return {
        ...state,
        walls: action.walls,
      };
    }
    case types.SAVE_CEILING_DETAILS: {
      return {
        ...state,
        ceiling: action.ceiling,
      };
    }
    case types.SAVE_FLOOR_DETAILS: {
      return {
        ...state,
        floor: action.floor,
      };
    }
    case types.SAVE_ROOM_DIMENSIONS: {
      return {
        ...state,
        roomDimensions: action.roomDimensions,
      };
    }
    case types.SAVE_PRODUCT_DETAILS: {
      return {
        ...state,
        productLines: action.products,
      };
    }
    case types.SAVE_HEAT_LOAD_CAPACITY: {
      return {
        ...state,
        heatLoadCapacity: action.heatLoadCapacity,
      };
    }
    case types.SAVE_HEAT_LOAD_FILTERS: {
      return {
        ...state,
        formHeatLoadFilters: action.formHeatLoadFilters,
      };
    }
    case types.SAVE_DOOR_DETAILS: {
      return {
        ...state,
        doors: action.doors,
      };
    }
    case types.SAVE_PEOPLE_DETAILS: {
      return {
        ...state,
        people: action.people,
      };
    }
    case types.SAVE_LIGHTING_DETAILS: {
      return {
        ...state,
        lighting: action.lighting,
      };
    }
    case types.SAVE_SAFETY_AND_RUNTIME: {
      return {
        ...state,
        safetyAndRuntime: action.safetyAndRuntime,
      };
    }
    case types.SAVE_FORKLIFT_DETAILS: {
      return {
        ...state,
        forklift: action.forklift,
      };
    }
    case types.SAVE_VENTILATION_DETAILS: {
      return {
        ...state,
        ventilation: action.ventilationDetails,
      };
    }
    case types.SAVE_FORM_SECTION_STATE: {
      return {
        ...state,
        formSections: {
          ...state.formSections,
          ...action.formSectionState,
        },
      };
    }
    case types.CLEAR_ALL: {
      return INITIAL_STORE_STATE;
    }

    default:
      return state;
  }
};

export default heatLoadReducer;
