import React, { FC, useEffect } from "react";
import SafetyAndRuntimeDetails from "./SafetyAndRuntimeDetails";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { StandardRoom } from "../../../types/HeatLoad";
import {
  safetyAndRuntimeDetailsChanged,
  safetyAndRuntimeDetailsHasNoErrors,
} from "./SafetyAndRuntimeDetailsValidation";
import actionSelector from "../../duck/selectors";
import isNaNReturnDefault from "../../../helpers/isNaNReturnNumber";

export interface SafetyAndRuntimeDetailsForm {
  otherLoads: number;
  equipmentRuntime: number;
  safetyFactor: number;
  fanFactor: number;
}

const SafetyAndRuntimeDetailsContainer: FC<{}> = () => {
  const methods = useForm<SafetyAndRuntimeDetailsForm>({
    mode: "onBlur",
  });

  const dispatch = useDispatch();
  const { watch, setValue } = methods;
  const { errors, dirtyFields } = methods.formState;

  const safetyAndRuntimeDetailsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.safetyAndRuntimeDetailsEnabled;
  });

  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const safetyAndRuntimeDetails = watch();

  useEffect(() => {
    if (safetyAndRuntimeDetailsEnabled) {
      if (!dirtyFields.equipmentRuntime) {
        setValue("equipmentRuntime", roomDetails.defaultRuntime);
      }
      if (!dirtyFields.fanFactor) {
        setValue("fanFactor", roomDetails.defaultFanFactor);
      }
      if (!dirtyFields.safetyFactor) {
        setValue("safetyFactor", 10, {
          shouldDirty: true,
        });
      }
    }
  }, [roomDetails, dirtyFields, setValue, safetyAndRuntimeDetailsEnabled]);

  useEffect(() => {
    if (
      safetyAndRuntimeDetailsChanged(dirtyFields) &&
      safetyAndRuntimeDetailsHasNoErrors(errors)
    ) {
      let saveDetails = {
        equipmentRuntime: isNaNReturnDefault(
          safetyAndRuntimeDetails.equipmentRuntime,
          24
        ),
        safetyFactor: isNaNReturnDefault(safetyAndRuntimeDetails.safetyFactor),
        fanFactor: isNaNReturnDefault(+safetyAndRuntimeDetails.fanFactor),
        otherLoads: isNaNReturnDefault(safetyAndRuntimeDetails.otherLoads),
      };

      dispatch(actionSelector.saveSafetyAndRuntime(saveDetails));
    }
  }, [safetyAndRuntimeDetails, dirtyFields, errors, dispatch]);

  return (
    <FormProvider {...methods}>
      <div>
        <form>
          <SafetyAndRuntimeDetails
            formEnabled={safetyAndRuntimeDetailsEnabled}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default SafetyAndRuntimeDetailsContainer;
