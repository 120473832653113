import React, { FC, ReactNode } from "react";

export interface AccordionProps {
  children: ReactNode[];
}

const Accordion: FC<AccordionProps> = ({ children }) => {
  return <div>{children}</div>;
};

export default Accordion;
