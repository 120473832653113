import React from "react";
import styles from "./Table.module.css";

export const Table: React.FC<{}> = ({ children }) => (
  <table className={styles.table}>{children}</table>
);

export const TableNoBorders: React.FC<{}> = ({ children }) => (
  <table className={styles.tableNoBorders}>{children}</table>
);

export const Header: React.FC<{}> = ({ children }) => (
  <thead className={styles.header}>{children}</thead>
);

export const Body: React.FC<{}> = ({ children }) => (
  <tbody className={styles.body}>{children}</tbody>
);

interface RowProps {
  onClick?: (e: any) => void;
  selected?: boolean;
}

interface ColProps {
  columnSpan?: number;
  textAlign?: textAlignType;
  width?: number;
}

export const Row: React.FC<RowProps> = ({ children, selected, ...props }) => {
  const rowClasses = [styles.row]
    .concat(selected ? styles.selectedRow : [])
    .join(" ");

  return (
    <tr className={rowClasses} {...props}>
      {children}
    </tr>
  );
};

export const HeaderCell: React.FC<ColProps> = ({
  columnSpan = 1,
  textAlign = "left",
  children,
  width,
}) => (
  <th
    className={styles.th}
    colSpan={columnSpan}
    style={{
      textAlign: textAlign as textAlignType,
      width: width ? `${width}%` : "auto",
    }}
  >
    {children}
  </th>
);

type textAlignType = "left" | "right" | "center" | "justify";
type cellStyle = "normal" | "nopadding" | "noborder";
interface CellProps {
  textAlign?: textAlignType;
  rowSpan?: number;
  columnSpan?: number;
  cellStyle?: cellStyle;
  width?: number;
  dataTestId?: string;
}

export const Cell: React.FC<CellProps> = ({
  textAlign = "left",
  rowSpan = 1,
  columnSpan = 1,
  cellStyle = "normal",
  width,
  dataTestId,
  children,
}) => {
  let cellStyling =
    cellStyle === "nopadding"
      ? styles.cellNoPadding
      : cellStyle === "noborder"
      ? styles.cellNoBorder
      : styles.cell;
  return (
    <td
      className={cellStyling}
      style={{
        textAlign: textAlign as textAlignType,
        width: width ? `${width}%` : "auto",
      }}
      rowSpan={rowSpan}
      colSpan={columnSpan}
      data-testid={dataTestId}
    >
      {children}
    </td>
  );
};
