import React, { FC, useCallback, useEffect, useState } from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import { SelectOption } from "../../../types/SelectOption";
import gridStyles from "../../../css/GridForm.module.css";
import styles from "../CapitalEquipmentFilter/CapitalEquipmentFilter.module.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {
  loadCapacityRules,
  ambientRules,
  liquidSubcoolingRules,
} from "../CapitalEquipmentFilter/CapitalEquipmentValidation";
import Select from "../../../components/Select/Select";
import { standardsRatingOptions } from "../../../types/StandardsRating";
import RadioGroup from "../../../components/RadioGroup/RadioGroup";
import { referenceTemperatureMethod } from "../../../types/ReferenceTemperatureMethod";
import calculateSuperheatAndLiquidSubcooling from "../../../calculations/superheatAndLiquidSubcooling/calculateSuperheatAndLiquidSubcooling";
import { CondensingUnitFilterModel } from "../../../types/CondensingUnitFilterModel";
import { logPageEvent } from "../../../helpers/amplitude";

interface CondensingUnitSearchForm {
  standardsRating: string;
  capacity: string;
  refrigerant: string;
  mid_or_dew: string;
  sst: string;
  compressorInletSuperheat: string;
  liquidSubcooling: string;
  ambient: string;
}

const defaultValues: CondensingUnitSearchForm = {
  capacity: "3600",
  refrigerant: "R404A",
  standardsRating: "EN12900",
  mid_or_dew: "mid",
  sst: "-4",
  ambient: "32",
  compressorInletSuperheat: "15",
  liquidSubcooling: "2",
};

const CondensingUnitSearchForm: FC<{
  scrollToEquipmentSelection: () => void;
}> = ({ scrollToEquipmentSelection }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<CondensingUnitSearchForm>({
    defaultValues,
    mode: "onBlur",
  });
  let dispatch = useDispatch();
  const watchStandardsRating = watch("standardsRating");
  const watchSST = watch("sst");
  const [customStandardsRating, setCustomStandardsRating] = useState(false);

  const submit = (values: CondensingUnitSearchForm) => {
    let submitValues: CondensingUnitFilterModel = {
      refrigerant: values.refrigerant,
      capacity: +values.capacity,
      ambient: +values.ambient,
      sst: +values.sst,
      mid_or_dew: values.mid_or_dew,
      standardsRating: values.standardsRating,
      compressorInletSuperheat: +values.compressorInletSuperheat,
      liquidSubcooling: +values.liquidSubcooling,
    };
    dispatch(actionSelector.loadingBalancePerformance(true));
    dispatch(actionSelector.searchSingleCondensingUnit(submitValues));
    scrollToEquipmentSelection();
  };

  const refrigerantOptions: SelectOption[] = useSelector(
    (state: any) => state.product.equipmentRefrigerantOptions
  );

  useEffect(() => {
    setValue("refrigerant", "R404A");
  }, [refrigerantOptions, setValue]);

  const compressorInletSuperheatRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Must be a number",
    },
    validate: {
      maxToBeCheckedForCustomOnly: (value) => {
        let standardsRating = getValues("standardsRating");

        return (
          (standardsRating === "Custom" && value <= 25) ||
          standardsRating !== "Custom" ||
          `Must be between 0 and +25 Kelvin`
        );
      },
    },
    min: {
      value: 0,
      message: "Must be between 0 and +25 Kelvin",
    },
    valueAsNumber: true,
  };

  const sstRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^-?\d*\.?\d*$/,
      message: "Must be a number",
    },
    min: {
      value: -50,
      message: "Must be between -50\u00b0 and 20\u00b0 Celsius",
    },
    max: {
      value: 20,
      message: "Must be between -50\u00b0 and 20\u00b0 Celsius",
    },
    valueAsNumber: true,
  };

  const setInletSuperheatAndLiquidSubcooling = useCallback(() => {
    let standardsRating = getValues("standardsRating");
    let sst = getValues("sst");

    setCustomStandardsRating(standardsRating === "Custom");

    let { compressorInletSuperheat, liquidSubcooling } =
      calculateSuperheatAndLiquidSubcooling(standardsRating, +sst);

    setValue("liquidSubcooling", liquidSubcooling.toString());
    setValue("compressorInletSuperheat", compressorInletSuperheat.toString());
  }, [getValues, setValue]);

  useEffect(() => {
    setInletSuperheatAndLiquidSubcooling();
  }, [watchSST, setInletSuperheatAndLiquidSubcooling]);

  useEffect(() => {
    setInletSuperheatAndLiquidSubcooling();
  }, [watchStandardsRating, setInletSuperheatAndLiquidSubcooling]);

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.formContainer}>
      <div className={gridStyles.gridContainer}>
        <div>
          <div className="section-title">Refrigeration heat load details</div>
          <p className="section-description">
            Provide energy rating, capacity & refrigerant details.
          </p>

          <Select
            {...register("standardsRating", { required: "Required" })}
            labelText="Standards rating"
            options={standardsRatingOptions}
            hasErrors={errors.standardsRating !== undefined}
            errorMessage={errors.standardsRating?.message}
          />

          <Input
            {...register("capacity", loadCapacityRules)}
            labelText="Operating Capacity"
            placeholderText="0 to 999000"
            innerLabel="Watts"
            hasErrors={errors.capacity !== undefined}
            errorMessage={errors.capacity?.message}
          />

          <Select
            {...register("refrigerant", { required: "Required" })}
            labelText="Refrigerant"
            options={refrigerantOptions}
            hasErrors={errors.refrigerant !== undefined}
            errorMessage={errors.refrigerant?.message}
          />
        </div>
        <div>
          <div className="section-title">Temperature details</div>
          <p className="section-description">
            Provide design temperature details.
          </p>

          <RadioGroup
            {...register("mid_or_dew", { required: "Required" })}
            labelText="Reference temperature method"
            values={referenceTemperatureMethod}
            hasErrors={errors.mid_or_dew !== undefined}
            errorMessage={errors.mid_or_dew?.message}
          />

          <Input
            {...register("sst", sstRules)}
            labelText="Saturated Suction Temperature"
            placeholderText="-50 to 20"
            innerLabel="Celsius"
            hasErrors={errors.sst !== undefined}
            errorMessage={errors.sst?.message}
          />

          <Input
            {...register(
              "compressorInletSuperheat",
              compressorInletSuperheatRules
            )}
            labelText="Compressor Inlet Superheat"
            placeholderText="0 to 25"
            innerLabel="Kelvin"
            hasErrors={errors.compressorInletSuperheat !== undefined}
            errorMessage={errors.compressorInletSuperheat?.message}
            disabled={!customStandardsRating}
          />

          <Input
            {...register("liquidSubcooling", liquidSubcoolingRules)}
            labelText="Liquid Subcooling"
            placeholderText="0 to 15"
            innerLabel="Kelvin"
            hasErrors={errors.liquidSubcooling !== undefined}
            errorMessage={errors.liquidSubcooling?.message}
            disabled={!customStandardsRating}
          />
        </div>
        <div>
          <div className="section-title">Ambient conditions</div>
          <p className="section-description">
            Provide ambient temperature the condensing unit is exposed to.
          </p>
          <Input
            {...register("ambient", ambientRules)}
            labelText="Ambient temperature on condensing unit"
            innerLabel="Celsius"
            placeholderText="20° to 43°"
            hasErrors={errors.ambient !== undefined}
            errorMessage={errors.ambient?.message}
          />
        </div>
      </div>
      <div>
        <Button
          buttonStyle="primary"
          buttonType="submit"
          dataTestId="display_equipment"
          onClickHandler={(e) => logPageEvent("Display Condensing Units only")}
        >
          Display equipment
        </Button>
      </div>
    </form>
  );
};

export default CondensingUnitSearchForm;
