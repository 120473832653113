import { FieldError, RegisterOptions } from "react-hook-form";

interface LightingDetailsErrors {
  lightingType?: FieldError | undefined;
  heatGenerated?: FieldError | undefined;
  lightUsage?: FieldError | undefined;
}

interface LightingDetailsDirtyFields {
  lightingType?: boolean | undefined;
  heatGenerated?: boolean | undefined;
  lightUsage?: boolean | undefined;
}

const usageRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Must be between 0 and 24",
  },
  max: {
    value: 24,
    message: "Must be between 0 and 24",
  },
  valueAsNumber: true,
};

const heatGainRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 1,
    message: "Must be between 1 and 10,000",
  },
  max: {
    value: 10000,
    message: "Must be between 1 and 10,000",
  },
  valueAsNumber: true,
};

const isErrorLighting = (errors: LightingDetailsErrors): boolean => {
  return errors.lightingType !== undefined || errors.lightUsage !== undefined;
};

const allLightingFieldsDirty = (
  dirtyFields: LightingDetailsDirtyFields
): boolean => {
  let lightingDirtyFlag =
    dirtyFields.lightingType !== undefined &&
    dirtyFields.lightUsage !== undefined;
  return lightingDirtyFlag ?? false;
};

const validateLightingDetails = (
  errors: LightingDetailsErrors,
  dirtyFields: LightingDetailsDirtyFields
): boolean => {
  const isValid = !isErrorLighting(errors);
  const allDirtyFields = allLightingFieldsDirty(dirtyFields);

  return isValid && allDirtyFields;
};

export { usageRules, validateLightingDetails, heatGainRules };
