import React, { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import {
  wallThicknessRules,
  surfaceTempRules,
} from "../SurfaceDetailsValidation";
import { ProductFilter } from "../../../../types/ProductFilter";
import Switch from "../../../../components/Switch/Switch";
import { RoomDimensions } from "../../../types/Room";
import FormRow from "../../../../components/FormRow/FormRow";
import { logPageEventWithData } from "../../../../helpers/amplitude";

const WallDetails: FC<{
  wallInsulationFilter: ProductFilter;
  roomDimensions: RoomDimensions;
  switchWallsViewHandler: (wallsAreSame: string) => void;
}> = ({ wallInsulationFilter, roomDimensions, switchWallsViewHandler }) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "walls",
  });

  const wallInsulationOptions = wallInsulationFilter.options;
  let remainingWalls = roomDimensions.walls.slice(
    1,
    roomDimensions.walls.length
  );
  let defaultWallIds = remainingWalls.map((w) => {
    return {
      wallId: w.id,
    };
  });

  const setWallFieldsArray = () => {
    const allWallsAreTheSame = watch("wallsAreTheSame");
    if (fields.length > 1 && allWallsAreTheSame === "yes") {
      remove([1, 2, 3]);
    }

    if (fields.length === 1 && allWallsAreTheSame === "no") {
      append(defaultWallIds);
    }

    logPageEventWithData("Heat Load - All walls are the same clicked", {
      allWallsAreTheSame,
    });

    // Got to be a better way to do this
    // Walls are not updated in time, so needed to put a wait before calling
    // this function in the container
    setTimeout(() => switchWallsViewHandler(allWallsAreTheSame), 10);
  };

  return (
    <div className={heatLoadStyles.narrowFormContainer}>
      <div>
        <Switch
          {...register("wallsAreTheSame", {
            required: "Required",
            onChange: (e) => setWallFieldsArray(),
          })}
          values={[
            { label: "All walls are same", value: "yes" },
            { label: "Each wall is different", value: "no" },
          ]}
          hasErrors={errors.wallsAreTheSame}
          errorMessage={errors.wallsAreTheSame?.message}
        />

        {fields.map((field, index) => {
          return (
            <div key={field.id}>
              <label className="labelBoldText">Wall {index + 1}</label>

              <input type="hidden" {...register(`walls.${index}.wallId`)} />

              <Select
                {...register(`walls.${index}.material`, {
                  required: "Required",
                })}
                labelText="Material"
                options={wallInsulationOptions}
                hasErrors={errors.walls?.[index]?.material}
                errorMessage={errors.walls?.[index]?.material?.message}
              />

              <FormRow>
                <Input
                  {...register(`walls.${index}.thickness`, wallThicknessRules)}
                  labelText="Thickness"
                  placeholderText="30 to 250"
                  innerLabel="mm"
                  hasErrors={errors.walls?.[index]?.thickness}
                  errorMessage={errors.walls?.[index]?.thickness?.message}
                  fieldSize="medium"
                />

                <Input
                  {...register(`walls.${index}.ambientTemp`, surfaceTempRules)}
                  labelText="Ambient temp."
                  placeholderText="-40° to +45°"
                  innerLabel="°C"
                  hasErrors={errors.walls?.[index]?.ambientTemp}
                  errorMessage={errors.walls?.[index]?.ambientTemp?.message}
                  fieldSize="medium"
                />
              </FormRow>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WallDetails;
