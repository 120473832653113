import React, { useEffect } from "react";
import styles from "./Filters.module.css";
import { ProductFilter } from "../../types/ProductFilter";
import { FilterValues } from "../../types/FilterValues";
import { filterValuesAreDefault } from "../../helpers/filterValuesAreDefault";
import { useForm } from "react-hook-form";
import Select from "../Select/Select";

interface FilterProps {
  filters: ProductFilter[];
  filterValues: FilterValues;
  setFilterValues: (filterType: string, value: string) => void;
  handleReset: () => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  filterValues,
  setFilterValues,
  handleReset,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<any>({
    mode: "onBlur",
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterValues(e.target.name, e.target.value);
  };

  // Need to set default for filters
  useEffect(() => {
    filters.forEach((filter) => {
      setValue(filter.type, filterValues[filter.type]);
    });
  });

  return (
    <div className={styles.secondaryFilterContainer}>
      <form>
        <div className={styles.form}>
          {filters.map((filter: ProductFilter) => (
            <div className={styles.selectFieldContainer} key={filter.name}>
              <Select
                labelText={filter.name}
                {...register(filter.type, {
                  onChange: (e) => {
                    handleFilterChange(e);
                  },
                })}
                hasErrors={errors && errors[filter.type]}
                errorMessage={errors && errors[filter.type]?.message}
                options={filter.options.map((filterOption) => ({
                  key: filterOption.key,
                  value: filterOption.value,
                }))}
                hideDefaultOption
              />
            </div>
          ))}
          <button
            disabled={filterValuesAreDefault(filterValues)}
            className={styles.resetButton}
            onClick={handleReset}
          >
            Reset filters
          </button>
        </div>
      </form>
    </div>
  );
};

export default Filters;
