import React, { FC } from "react";
import SelectedProductPlaceholder from "../../components/SelectedProductPlaceholder/SelectedProductPlaceholder";
import {
  PipeSizeCalculations,
  UnitTypes,
} from "../../types/PipeSizeCalculationResponse";
import styles from "./SelectedPipes.module.css";
import isAnEmptyObject from "../../helpers/emptyObject";
import ProductCard from "../../components/ProductCard/ProductCard";

interface SelectedPipesProps {
  selectedLiquidLineHorizontalPipe: PipeSizeCalculations;
  selectedSuctionLineHorizontalPipe: PipeSizeCalculations;
  selectedDischargeLineHorizontalPipe: PipeSizeCalculations;
  selectedLiquidLineVerticalPipe: PipeSizeCalculations;
  selectedSuctionLineVerticalPipe: PipeSizeCalculations;
  selectedDischargeLineVerticalPipe: PipeSizeCalculations;
  isLoading: boolean;
  unitTypes: UnitTypes;
}

interface IndividualPipeProps {
  title: string;
  selectedPipe: PipeSizeCalculations;
}

const SelectedPipes: FC<SelectedPipesProps> = ({
  selectedLiquidLineHorizontalPipe,
  selectedSuctionLineHorizontalPipe,
  selectedDischargeLineHorizontalPipe,
  selectedLiquidLineVerticalPipe,
  selectedSuctionLineVerticalPipe,
  selectedDischargeLineVerticalPipe,
  isLoading,
  unitTypes,
}) => {
  const IndividualPipe: FC<IndividualPipeProps> = ({ title, selectedPipe }) => {
    let productDescriptionValues = [
      {
        label: "Product code",
        value: selectedPipe.id.toString(),
      },
      {
        label: "Outer diameter",
        value: `${selectedPipe.od_mm} ${unitTypes["od_mm"]}`,
      },
      {
        label: "Thickness",
        value: `${selectedPipe.wall_thickness} ${unitTypes["wall_thickness"]}`,
      },
    ];

    return (
      <div className={styles.productHeading}>
        <b>{title}</b>

        <ProductCard
          title={`${selectedPipe.od_in} ${unitTypes["od_in"]}`}
          productDescriptionValues={productDescriptionValues}
        />
      </div>
    );
  };

  const SelectedPipeCards = () => {
    return (
      <>
        {!isAnEmptyObject(selectedLiquidLineHorizontalPipe) && (
          <IndividualPipe
            title="Liquid line - Horizontal"
            selectedPipe={selectedLiquidLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedLiquidLineVerticalPipe) && (
          <IndividualPipe
            title="Liquid line - Vertical"
            selectedPipe={selectedLiquidLineVerticalPipe}
          />
        )}
        {!isAnEmptyObject(selectedSuctionLineHorizontalPipe) && (
          <IndividualPipe
            title="Suction line - Horizontal"
            selectedPipe={selectedSuctionLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedSuctionLineVerticalPipe) && (
          <IndividualPipe
            title="Suction line - Vertical"
            selectedPipe={selectedSuctionLineVerticalPipe}
          />
        )}
        {!isAnEmptyObject(selectedDischargeLineHorizontalPipe) && (
          <IndividualPipe
            title="Discharge line - Horizontal"
            selectedPipe={selectedDischargeLineHorizontalPipe}
          />
        )}
        {!isAnEmptyObject(selectedDischargeLineVerticalPipe) && (
          <IndividualPipe
            title="Discharge line - Vertical"
            selectedPipe={selectedDischargeLineVerticalPipe}
          />
        )}
      </>
    );
  };

  return (
    <section>
      <div className="section-header">Selected Pipes</div>
      <p className={styles.subheading}>List of pipes selected for the quote</p>
      {isLoading ? (
        <SelectedProductPlaceholder
          heading="No pipes selected"
          subheading="Add details for pipe size calculation to select pipes"
        />
      ) : (
        <SelectedPipeCards />
      )}
    </section>
  );
};

export default SelectedPipes;
