import React from "react";
import Layout from "../../components/Layout/Layout";
import DashboardCard, {
  DashboardCardProps,
} from "../../components/DashboardCard/DashboardCard";
import styles from "./Dashboard.module.css";

const Dashboard: React.FC = () => {
  const cards: DashboardCardProps[] = [
    {
      title: "Equipment Selection",
      link: "/equipment-selection",
      imageFilename: "equipments.svg",
    },
    {
      title: "Pipe size Calculation",
      link: "/pipe-size-calculation",
      imageFilename: "pipeCalculation.svg",
    },
    {
      title: "Heat load Calculation",
      link: "/heat-load",
      middleLabelText: "BETA",
      imageFilename: "heatloadCalculation.svg",
    },
    {
      title: "Accessories Selection",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "accessories.svg",
    },
    {
      title: "Product Documents",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "productDocumentation.svg",
    },
  ];

  const templateCards: DashboardCardProps[] = [
    {
      title: "Cool Room",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "coolRoom.svg",
    },
    {
      title: "Freezer Room",
      link: "/",
      disabled: true,
      middleLabelText: "Coming soon",
      imageFilename: "freezerRoom.svg",
    },
  ];

  let givenName = sessionStorage.getItem("ve_user_given_name");
  let welcomeMessage = givenName !== null ? `Hi ${givenName}` : "Good day";

  return (
    <Layout>
      <h5>{welcomeMessage}</h5>

      <p>Tools that you can use today.</p>

      <div className={styles.dashboardCardContainer}>
        {cards.map((c) => {
          return (
            <DashboardCard
              title={c.title}
              link={c.link}
              disabled={c.disabled}
              imageFilename={c.imageFilename}
              middleLabelText={c.middleLabelText}
              key={c.title}
            />
          );
        })}
      </div>

      <p>Get started quickly with these templates.</p>

      <div className={styles.dashboardCardContainer}>
        {templateCards.map((c) => {
          return (
            <DashboardCard
              title={c.title}
              link={c.link}
              disabled={c.disabled}
              imageFilename={c.imageFilename}
              middleLabelText={c.middleLabelText}
              key={c.title}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default Dashboard;
