import React, { MouseEvent } from "react";
import cx from "classnames";
import styles from "./Button.module.css";
import Image from "../Image/Image";

type ButtonStyleTypes =
  | "primary"
  | "secondary"
  | "destructive"
  | "secondary_red";
type ButtonTypes = "submit" | "button" | "reset";

export interface ButtonProps {
  buttonStyle?: ButtonStyleTypes;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  buttonType?: ButtonTypes;
  dataTestId?: string;
  imageFilename?: string;
}

const Button: React.FC<ButtonProps> = ({
  buttonStyle = "primary",
  onClickHandler,
  isDisabled = false,
  buttonType = "button",
  dataTestId,
  imageFilename,
  children,
}) => {
  return (
    <div>
      <button
        onClick={onClickHandler}
        className={cx(
          buttonStyle === "primary" && styles.primaryButton,
          buttonStyle === "secondary" && styles.secondaryButton,
          buttonStyle === "destructive" && styles.destructiveButton,
          buttonStyle === "secondary_red" && styles.secondaryRedButton
        )}
        disabled={isDisabled}
        type={buttonType}
        data-testid={dataTestId}
      >
        {imageFilename && <Image filename={imageFilename} altText="" />}
        {children}
      </button>
    </div>
  );
};

export default Button;
