export interface AuthToken {
  idToke: string;
  accessToke: string;
  refreshToken: string;
  expireIn: string;
  tokenType: string;
}

interface VEUser {
  userId: string | null;
  email: string;
  givenName: string;
}

const readVEUser = (): VEUser => {
  if (read("ave_userid") === "") storeVEUser();
  return {
    userId: read("ave_userid"),
    email: read("ve_user_email"),
    givenName: read("ve_user_given_name"),
  };
};

const storeVEUser = (user: VEUser | null = null) => {
  if (!user) {
    let currentUserId = read("ave_userid");
    if (!currentUserId) {
      currentUserId = Math.random().toString();
    }
    user = {
      userId: currentUserId,
      email: "",
      givenName: "",
    };
  }
  if (user && user.userId) write("ave_userid", user.userId);
  if (user && user.email) write("ve_user_email", user.email);
  if (user && user.givenName) write("ve_user_given_name", user.givenName);
};

const clearVEUser = () => {
  clear("ave_userid");
  clear("ve_user_email");
  clear("ve_user_given_name");
};

const uniqUserReference = () => {
  let veUser = readVEUser();
  return veUser.email === "" ? veUser.userId : veUser.email;
};

const setAndGetMonitor = (monitor: string | null) => {
  if (monitor !== null) {
    write("monitor", monitor);
    return monitor === "true";
  }
  return false;
};

const storeAuthToken = (token: AuthToken) => {
  write("cognito_id_token", token.idToke);
  write("cognito_access_token", token.accessToke);
  write("cognito_refresh_token", token.refreshToken);
  write("cognito_expires_in", token.expireIn);
  write("cognito_token_type", token.tokenType);
};

const readAuthToken = () => {
  let token: AuthToken = {
    idToke: read("cognito_id_token"),
    accessToke: read("cognito_access_token"),
    refreshToken: read("cognito_refresh_token"),
    expireIn: read("cognito_expires_in"),
    tokenType: read("cognito_token_type"),
  };
  return token;
};

const clearAuthToken = () => {
  clear("cognito_id_token");
  clear("cognito_access_token");
  clear("cognito_refresh_token");
  clear("cognito_expires_in");
  clear("cognito_token_type");
};

const storeRedirectUrl = (url: string) => {
  write("veRedirectUrl", url);
};

const readRedirectUrl = () => {
  return read("veRedirectUrl");
};

const write = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};
const read = (key: string) => {
  return sessionStorage.getItem(key) ?? "";
};
const clear = (key: string) => {
  return sessionStorage.removeItem(key);
};

export {
  uniqUserReference,
  setAndGetMonitor,
  storeAuthToken,
  readAuthToken,
  clearAuthToken,
  readVEUser,
  storeVEUser,
  clearVEUser,
  storeRedirectUrl,
  readRedirectUrl,
};
