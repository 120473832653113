import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ProductFilter } from "../../../../types/ProductFilter";
import { LightingDetails as LightingDetailsModel } from "../../../types/Room";
import LightingDetails from "./LightingDetails";
import actionSelector from "../../../duck/actions";
import { validateLightingDetails } from "./LightingDetailsValidation";
import { EMPTY_FILTER } from "../../../hooks/ExtractHeatloadFilters";

export interface LightingDetailsForm {
  lightingType: string;
  heatGenerated?: number;
  lightUsage?: number;
}

const defaultValues: LightingDetailsForm = {
  lightingType: "",
  heatGenerated: undefined,
  lightUsage: undefined,
};

const LightingDetailsContainer: FC = () => {
  const methods = useForm<LightingDetailsForm>({
    defaultValues,
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const { watch, formState } = methods;
  const { errors, dirtyFields, isValidating } = formState;

  const lightingOptions: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.miscellaneous
      ? state.heatLoad.formHeatLoadFilters.miscellaneous["lighting_type"]
      : EMPTY_FILTER
  );

  useEffect(() => {
    const lightingDetails = watch();

    if (
      isValidating &&
      lightingDetails.lightUsage !== undefined &&
      lightingDetails.heatGenerated !== undefined &&
      validateLightingDetails(errors, dirtyFields)
    ) {
      const lighting: LightingDetailsModel = {
        lightingType: lightingDetails.lightingType,
        hours: lightingDetails.lightUsage,
        heatGenerated: lightingDetails.heatGenerated,
      };

      dispatch(actionSelector.saveLightingDetails(lighting));
    }
  }, [errors, isValidating, dirtyFields, dispatch, watch]);

  return (
    <FormProvider {...methods}>
      <form>
        <LightingDetails lightingOptions={lightingOptions} />
      </form>
    </FormProvider>
  );
};

export default LightingDetailsContainer;
