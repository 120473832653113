import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import {
  wallThicknessRules,
  surfaceTempRules,
  floorHeatingRules,
} from "../SurfaceDetailsValidation";
import { ProductFilter } from "../../../../types/ProductFilter";
import { StandardRoom } from "../../../../types/HeatLoad";
import FormRow from "../../../../components/FormRow/FormRow";

const FloorDetails: FC<{
  floorMaterialFilter: ProductFilter;
  floorInsulationFilter: ProductFilter;
  floorHeatingFilter: ProductFilter;
  roomDetails: StandardRoom;
}> = ({
  floorMaterialFilter,
  floorInsulationFilter,
  floorHeatingFilter,
  roomDetails,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const floorMaterialOptions = floorMaterialFilter.options;
  const floorInsulationOptions = floorInsulationFilter.options;
  const floorHeatingOptions = floorHeatingFilter.options;

  let hasFloorMaterial = () => {
    return watch(`floor.material`) !== "";
  };

  let hasFloorInsulation = () => {
    let insulationMaterial = watch(`floor.insulationMaterial`);
    return insulationMaterial !== undefined && insulationMaterial !== "none";
  };

  let isRoomTempBelowOrEqualZero = () => {
    return roomDetails.temperature <= 0;
  };

  let hasCustomFloorHeating = () => {
    return watch(`floor.heating`) === "custom";
  };

  return (
    <div className={heatLoadStyles.narrowFormContainer}>
      <div>
        <label className="labelBoldText">Floor</label>

        <Select
          {...register("floor.material", { required: "Required" })}
          labelText="Material"
          options={floorMaterialOptions}
          hasErrors={errors.floor?.material}
          errorMessage={errors.floor?.material?.message}
          fieldSize="large"
        />

        <Input
          {...register("floor.thickness", wallThicknessRules)}
          labelText="Thickness"
          placeholderText="30 to 250"
          innerLabel="mm"
          hasErrors={errors.floor?.thickness}
          errorMessage={errors.floor?.thickness?.message}
          fieldSize="medium"
        />

        <Input
          {...register("floor.groundTemp", surfaceTempRules)}
          labelText="Ground temp."
          placeholderText="-40° to +45°"
          innerLabel="°C"
          hasErrors={errors.floor?.groundTemp}
          errorMessage={errors.floor?.groundTemp?.message}
          fieldSize="small"
        />

        <FormRow>
          {hasFloorMaterial() && (
            <Select
              {...register("floor.insulationMaterial", {
                required: "Required",
              })}
              labelText="Floor insulation"
              options={floorInsulationOptions}
              hasErrors={errors.floor?.insulationMaterial}
              errorMessage={errors.floor?.insulationMaterial?.message}
              hideDefaultOption
              fieldSize="large"
            />
          )}

          {hasFloorMaterial() && hasFloorInsulation() && (
            <Input
              {...register("floor.insulationThickness", wallThicknessRules)}
              labelText="Thickness"
              placeholderText="30 to 250"
              innerLabel="mm"
              hasErrors={errors.floor?.insulationThickness}
              errorMessage={errors.floor?.insulationThickness?.message}
              fieldSize="small"
            />
          )}
        </FormRow>

        <FormRow>
          {hasFloorMaterial() && isRoomTempBelowOrEqualZero() && (
            <Select
              {...register("floor.heating", { required: "Required" })}
              labelText="Floor heating"
              options={floorHeatingOptions}
              hasErrors={errors.floor?.heating}
              errorMessage={errors.floor?.heating?.message}
              hideDefaultOption
              fieldSize="medium"
            />
          )}

          {hasCustomFloorHeating() && (
            <Input
              {...register("floor.customWattage", floorHeatingRules)}
              labelText="Wattage"
              innerLabel="W/m²"
              placeholderText="1 to 100"
              hasErrors={errors.floor?.customWattage}
              errorMessage={errors.floor?.customWattage?.message}
              fieldSize="small"
            />
          )}
        </FormRow>
      </div>
    </div>
  );
};

export default FloorDetails;
