import {
  SAVE_STANDARD_ROOM_DETAILS,
  SAVE_WALL_DETAILS,
  SAVE_CEILING_DETAILS,
  SAVE_FLOOR_DETAILS,
  SAVE_PRODUCT_DETAILS,
  SAVE_HEAT_LOAD_CAPACITY,
  SAVE_ROOM_DIMENSIONS,
  SAVE_DOOR_DETAILS,
  SAVE_PEOPLE_DETAILS,
  SAVE_SAFETY_AND_RUNTIME,
  SAVE_LIGHTING_DETAILS,
  SAVE_FORKLIFT_DETAILS,
  SAVE_VENTILATION_DETAILS,
  SAVE_FORM_SECTION_STATE,
  SAVE_HEAT_LOAD_FILTERS,
  CLEAR_ALL,
} from "./types";
import {
  CeilingDetails,
  DoorDetails,
  FloorDetails,
  ForkliftDetails,
  LightingDetails,
  PeopleDetails,
  RoomDimensions,
  VentilationDetails,
  WallDetails,
} from "../types/Room";
import { ProductLine } from "../types/products";
import { StandardRoom } from "../../types/HeatLoad";
import { SectionFilterMap } from "../../types/ProductFilter";
import { HeatLoadTotalCapacity } from "../types/HeatLoadCapacity";
import { SafetyAndRuntime } from "../types/SafetyAndRuntime";
import { HeatLoadFormState } from "../types/HeatLoadFormState";

const saveStandardRoomDetails = (room: StandardRoom) => {
  return {
    type: SAVE_STANDARD_ROOM_DETAILS,
    room,
  };
};

const saveWallDetails = (walls: WallDetails[]) => {
  return {
    type: SAVE_WALL_DETAILS,
    walls,
  };
};

const saveCeilingDetails = (ceiling: CeilingDetails) => {
  return {
    type: SAVE_CEILING_DETAILS,
    ceiling,
  };
};

const saveFloorDetails = (floor: FloorDetails) => {
  return {
    type: SAVE_FLOOR_DETAILS,
    floor,
  };
};

// room dimensions
const saveRoomDimensions = (roomDimensions: RoomDimensions) => {
  return {
    type: SAVE_ROOM_DIMENSIONS,
    roomDimensions,
  };
};

const saveProductDetails = (products: ProductLine[]) => {
  return {
    type: SAVE_PRODUCT_DETAILS,
    products,
  };
};

const saveHeatLoadCapacity = (heatLoadCapacity: HeatLoadTotalCapacity) => {
  return {
    type: SAVE_HEAT_LOAD_CAPACITY,
    heatLoadCapacity,
  };
};

const saveHeatLoadFilters = (formHeatLoadFilters: SectionFilterMap) => {
  return {
    type: SAVE_HEAT_LOAD_FILTERS,
    formHeatLoadFilters,
  };
};

const saveDoorDetails = (doors: DoorDetails[]) => {
  return {
    type: SAVE_DOOR_DETAILS,
    doors,
  };
};

const savePeopleDetails = (people: PeopleDetails) => {
  return {
    type: SAVE_PEOPLE_DETAILS,
    people,
  };
};

const saveLightingDetails = (lighting: LightingDetails) => {
  return {
    type: SAVE_LIGHTING_DETAILS,
    lighting,
  };
};

const saveForkliftDetails = (forklift: ForkliftDetails) => {
  return {
    type: SAVE_FORKLIFT_DETAILS,
    forklift,
  };
};

const saveSafetyAndRuntime = (safetyAndRuntime: SafetyAndRuntime) => {
  return {
    type: SAVE_SAFETY_AND_RUNTIME,
    safetyAndRuntime,
  };
};

const saveVentilationDetails = (ventilationDetails: VentilationDetails) => {
  return {
    type: SAVE_VENTILATION_DETAILS,
    ventilationDetails,
  };
};

const saveFormSectionState = (formSectionState: HeatLoadFormState) => {
  return {
    type: SAVE_FORM_SECTION_STATE,
    formSectionState,
  };
};

const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export default {
  saveWallDetails,
  saveFloorDetails,
  saveCeilingDetails,
  saveRoomDimensions,
  saveStandardRoomDetails,
  saveProductDetails,
  saveHeatLoadCapacity,
  saveHeatLoadFilters,
  saveDoorDetails,
  savePeopleDetails,
  saveSafetyAndRuntime,
  saveLightingDetails,
  saveForkliftDetails,
  saveVentilationDetails,
  saveFormSectionState,
  clearAll,
};
