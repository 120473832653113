import React, { FC } from "react";
import ProductCard from "../../../components/ProductCard/ProductCard";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";

const CondensingUnitCard: FC<{
  condensingUnit: CondensingUnitProductCapacity;
  refrigerant: string;
}> = ({ condensingUnit, refrigerant }) => {
  let { model, thumbnail, product_code, series } = condensingUnit;
  let productDescriptionValues = [
    {
      label: "Product code",
      value: product_code,
    },
    { label: "Refrigerant", value: refrigerant },
    { label: "Series", value: series },
  ];

  return (
    <ProductCard
      title={model}
      thumbnail={thumbnail}
      productDescriptionValues={productDescriptionValues}
    />
  );
};

export default CondensingUnitCard;
