interface HeatLoadSection {
  name: string;
}

export const HeatLoadSections = Object.freeze({
  VENTILATION: { name: "Ventilation" },
  PRODUCT_HEAT_LOAD: { name: "ProductLoad" },
  WALL_TRANSMISSION: { name: "WallTransmission" },
  CEILING_TRANSMISSION: { name: "CeilingTransmission" },
  FLOOR_TRANSMISSION: { name: "FloorTransmission" },
  DOOR_INFILTRATION: { name: "DoorInfiltration" },
  DOOR_TRANSMISSION: { name: "DoorTransmission" },
  DOOR_HEATED_FASCIA: { name: "DoorHeatedFascia" },
  DOOR_HEATED_GLASS: { name: "DoorHeatedGlass" },
  INTERNAL_PEOPLE: { name: "InternalPeople" },
  INTERNAL_LIGHTING: { name: "InternalLighting" },
  INTERNAL_MACHINERY: { name: "InternalMachinery" },
  FLOOR_HEATING: { name: "FloorHeating" },
});

const SectionMapping: {
  [name: string]:
    | "transmissionLoad"
    | "productLoad"
    | "infiltrationLoad"
    | "internalLoad";
} = {
  Ventilation: "infiltrationLoad",
  ProductLoad: "productLoad",
  WallTransmission: "transmissionLoad",
  CeilingTransmission: "transmissionLoad",
  FloorTransmission: "transmissionLoad",
  DoorInfiltration: "infiltrationLoad",
  DoorTransmission: "transmissionLoad",
  DoorHeatedFascia: "internalLoad",
  DoorHeatedGlass: "internalLoad",
  InternalPeople: "internalLoad",
  InternalLighting: "internalLoad",
  InternalMachinery: "internalLoad",
  FloorHeating: "internalLoad",
};

export interface LoadCapacity {
  sensible: number;
  latent: number;
}

export interface HeatLoadCapacity extends LoadCapacity {
  reference: string;
  heatLoadType: string;
}

export interface ProductHeatLoadCapacity extends HeatLoadCapacity {
  heatAboveFreeze: number;
  heatBelowFreeze: number;
  latentHeat: number;
  total: number;
}

interface HeatLoadSummary {
  transmissionLoad: LoadCapacity;
  infiltrationLoad: LoadCapacity;
  productLoad: LoadCapacity;
  internalLoad: LoadCapacity;
  totalLoad: LoadCapacity;
  otherLoad: { total: number };
  safetyLoad: { safetyFactor: number; total: number };
  runTimeTotal: { runtime: number; total: number };
  fanLoad: { fanFactor: number; fanLoad: number; total: number };
}

interface ReferenceCapacity {
  [reference: string]: HeatLoadCapacity;
}

interface Capacity {
  [section: string]: ReferenceCapacity;
}

const addLoadCapacity = (original: LoadCapacity, add: LoadCapacity) => {
  original.latent += add.latent;
  original.sensible += add.sensible;
  return original;
};

export class TotalHeatLoad {
  private readonly _heatLoads: Capacity;
  private _runtime: number;
  private _safetyFactor: number;
  private _fanFactor: number;
  private _otherLoad: number;

  constructor(r: number = 16, sf: number = 10, ff: number = 6, ol: number = 0) {
    this._heatLoads = {};
    this._runtime = r;
    this._safetyFactor = sf;
    this._fanFactor = ff;
    this._otherLoad = ol;
  }

  set runtime(runtime: number) {
    this._runtime = runtime;
  }
  get runtime() {
    return this._runtime;
  }

  set safetyFactor(safetyFactor: number) {
    this._safetyFactor = safetyFactor;
  }
  get safetyFactor() {
    return this._safetyFactor;
  }

  set fanFactor(fanFactor: number) {
    this._fanFactor = fanFactor;
  }
  get fanFactor() {
    return this._fanFactor;
  }

  set otherLoad(otherLoad: number) {
    this._otherLoad = otherLoad;
  }
  get otherLoad() {
    return this._otherLoad;
  }

  addHeatLoadCapacity(heatLoadCapacity: HeatLoadCapacity) {
    if (this._heatLoads[heatLoadCapacity.heatLoadType] === undefined) {
      this._heatLoads[heatLoadCapacity.heatLoadType] = {};
    }

    this._heatLoads[heatLoadCapacity.heatLoadType][heatLoadCapacity.reference] =
      heatLoadCapacity;
  }

  addHeatLoadCapacities(heatLoadCapacities: HeatLoadCapacity[]) {
    heatLoadCapacities.forEach((item) => {
      this.addHeatLoadCapacity(item);
    });
  }

  get heatLoads(): HeatLoadCapacity[] {
    let heatLoadCapacity: HeatLoadCapacity[] = [];
    Object.entries(this._heatLoads).forEach(([, value]) => {
      Object.entries(value).forEach(([, l]) => {
        heatLoadCapacity.push(l);
      });
    });
    return heatLoadCapacity;
  }

  total() {
    let loadSummary: HeatLoadSummary = {
      transmissionLoad: {
        sensible: 0,
        latent: 0,
      },
      infiltrationLoad: {
        sensible: 0,
        latent: 0,
      },
      productLoad: {
        sensible: 0,
        latent: 0,
      },
      internalLoad: {
        sensible: 0,
        latent: 0,
      },
      totalLoad: {
        sensible: 0,
        latent: 0,
      },
      otherLoad: {
        total: 0,
      },
      safetyLoad: { safetyFactor: 0, total: 0 },
      runTimeTotal: { runtime: 0, total: 0 },
      fanLoad: { fanFactor: 0, fanLoad: 0, total: 0 },
    };

    Object.entries(this._heatLoads).forEach(([, rc]) => {
      Object.entries(rc).forEach(([, l]) => {
        let t = SectionMapping[l.heatLoadType];
        loadSummary[t] = addLoadCapacity(loadSummary[t], l);
        loadSummary.totalLoad = addLoadCapacity(loadSummary.totalLoad, l);
      });
    });

    loadSummary.otherLoad.total = this.otherLoad;
    let totalLoad =
      loadSummary.totalLoad.sensible +
      loadSummary.totalLoad.latent +
      this.otherLoad;

    if (this.safetyFactor > 0) {
      loadSummary.safetyLoad = {
        safetyFactor: this.safetyFactor,
        total: totalLoad * (this.safetyFactor / 100),
      };
    }

    if (this.runtime > 0) {
      let totalRuntimeLoadBeforeFanLoad =
        (totalLoad + loadSummary.safetyLoad.total) * (24 / this.runtime);
      loadSummary.runTimeTotal = {
        runtime: this.runtime,
        total: totalRuntimeLoadBeforeFanLoad,
      };

      let totalLoadWithFanLoad =
        totalRuntimeLoadBeforeFanLoad * (this.fanFactor / 100);
      loadSummary.fanLoad = {
        fanFactor: this.fanFactor,
        fanLoad: totalLoadWithFanLoad,
        total: totalRuntimeLoadBeforeFanLoad + totalLoadWithFanLoad,
      };
    }

    return loadSummary;
  }

  removeSectionHeatLoadCapacity(section: HeatLoadSection) {
    this._heatLoads[section.name] = {};
  }
}
