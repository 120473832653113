import RoomFloor, { FloorInsulation, FloorHeating } from "./RoomFloor";
import Wall from "./Wall";
import { calculateInfiltrationLoad } from "./service/HeatLoadServices";
import AirCondition from "./AirCondition";
import { RoomCeiling } from "./RoomCeiling";
import {
  HeatLoadCapacity,
  HeatLoadSections,
  LoadCapacity,
} from "./load/TotalHeatLoad";
import { DoorAttributes, DoorModel, Opening, WallDoor } from "./Door";
import HeatCapacity from "./service/HeatCapacity";
import { People } from "./miscellaneous/People";
import { Lighting } from "./miscellaneous/Lighting";
import { Forklift } from "./miscellaneous/Forklift";
import { Ventilation } from "./miscellaneous/Ventilation";

function findActivityFactor(activityLevel: string) {
  if (activityLevel === "low") return 0.75;
  if (activityLevel === "high") return 1.25;
  return 1;
}

// this estimates the rate of sweating v rate of radiant heating from a person in a cooled room
// it assumes a 50/50 split in a 25 C room, going down to a 100/0 split for a -25 C room
function sensibleToLatentRatio(roomTemperature: number) {
  let ratio = (0.5 / 50) * (roomTemperature - 25) + 0.5;
  if (ratio < 0) {
    return 0;
  } else if (ratio > 1) {
    return 1;
  }
  return ratio;
}

function heatLoad(
  heatLoadType: string,
  reference: string,
  sensible: number,
  latent: number
) {
  return {
    heatLoadType: heatLoadType,
    reference: reference,
    latent: +latent.toFixed(3),
    sensible: +sensible.toFixed(3),
  };
}

const HOURS_PER_DAY = 24;
const PERSON_HEAT_EQUIVALENT_AT_ZERO = 272;
const HEAT_LOAD_PER_PERSON = 6;

class ColdStorage {
  private readonly _roomFloor: RoomFloor;
  private readonly _walls: Wall[];
  private readonly _ceiling: RoomCeiling;
  private readonly _volume: number;
  private _outsideAirCondition: AirCondition;
  private _roomAirCondition: AirCondition;
  private _doorToWall = new Map<string, string>();
  private _people: People;
  private _runtime: number;
  private _lighting: Lighting;
  private _forklift: Forklift;
  private _ventilation: Ventilation;

  constructor(
    roomFloor: RoomFloor,
    walls: Wall[],
    ceiling: RoomCeiling,
    volume: number
  ) {
    this._roomFloor = roomFloor;
    this._walls = walls;
    this._ceiling = ceiling;
    this._volume = volume;
    this._ventilation = { ventilationType: "none", airLitresPerPerson: 0 };
  }
  isEmptyRoom() {
    return this.volume === 0;
  }

  set outsideAirCondition(outsideAirCondition: AirCondition) {
    this._outsideAirCondition = outsideAirCondition;
  }

  set roomAirCondition(roomAirCondition: AirCondition) {
    this._roomAirCondition = roomAirCondition;
  }

  set people(people: People) {
    this._people = people;
  }

  set runtime(runtime: number) {
    this._runtime = runtime;
  }

  set lighting(lighting: Lighting) {
    this._lighting = lighting;
  }

  set forklift(forklift: Forklift) {
    this._forklift = forklift;
  }

  set ventilation(ventilation: Ventilation) {
    this._ventilation = ventilation;
  }

  get outsideAirCondition() {
    return this._outsideAirCondition;
  }

  get roomAirCondition() {
    return this._roomAirCondition;
  }

  get volume() {
    return this._volume;
  }

  get roomFloor() {
    return this._roomFloor;
  }

  get walls() {
    return this._walls;
  }

  get ceiling() {
    return this._ceiling;
  }

  get people() {
    return this._people;
  }

  get lighting() {
    return this._lighting;
  }

  get forklift() {
    return this._forklift;
  }

  get ventilation() {
    return this._ventilation;
  }

  get runtime() {
    if (this._runtime) {
      return this._runtime;
    }
    return HOURS_PER_DAY;
  }

  getRoomDetails() {
    let roomFloor = this._roomFloor;
    let walls = this._walls;
    return { floor: roomFloor, walls: walls };
  }

  airFlow() {
    if (this.ventilation.ventilationType === "none") {
      return 0;
    }

    let roomAirflow = 0;
    if (this.ventilation.ventilationType === "standard") {
      roomAirflow = this.volume / 3600;

      let totalDorAirflow = 0;
      this.walls.forEach((w) => {
        w.opening.forEach((d) => {
          totalDorAirflow += d.door.airflow;
        });
      });
      roomAirflow += totalDorAirflow;
    } else if (
      this.people &&
      this.people.peopleCount >= 0 &&
      this.ventilation.airLitresPerPerson >= 0
    ) {
      roomAirflow =
        (this.people.peopleCount * this.ventilation.airLitresPerPerson) / 1000;
    }

    return +roomAirflow.toFixed(4);
  }

  isValid() {
    return (
      this.roomAirCondition !== undefined &&
      this.roomAirCondition.roomHumidity >= 0 &&
      this.outsideAirCondition !== undefined &&
      this.outsideAirCondition.roomHumidity > 0
    );
  }

  setWallOutSideAirCondition(
    wallId: string,
    outsideAirCondition: AirCondition,
    facingSun: boolean
  ) {
    let wall = this.walls.filter((w) => w.id === wallId);
    if (wall === undefined || wall.length !== 1) {
      throw new Error("Wall " + wallId + " is invalid");
    }
    wall[0].outsideAirCondition = outsideAirCondition;
    wall[0].facingSun = facingSun;
  }

  setCeilingOutSideAirCondition(
    outsideAirCondition: AirCondition,
    facingSun: boolean
  ) {
    if (this.ceiling === undefined) {
      throw new Error("Ceiling not properly initialised");
    }
    this.ceiling.outsideAirCondition = outsideAirCondition;
    this.ceiling.facingSun = facingSun;
  }

  setFloorOutSideAirCondition(
    outsideAirCondition: AirCondition,
    facingSun: boolean
  ) {
    if (this.ceiling === undefined) {
      throw new Error("Floor not properly initialised");
    }
    this.roomFloor.outsideAirCondition = outsideAirCondition;
    this.roomFloor.facingSun = facingSun;
  }

  setWallConductivity(wallId: string, conductivity: number) {
    let wall = this.walls.filter((w) => w.id === wallId);
    if (wall === undefined || wall.length !== 1) {
      throw new Error("Wall " + wallId + " is invalid");
    }
    wall[0].conductivity = conductivity;
  }

  setCeilingConductivity(conductivity: number) {
    if (this.ceiling === undefined) {
      throw new Error("Ceiling not properly initialised");
    }
    this.ceiling.conductivity = conductivity;
  }

  setFloorConductivity(conductivity: number) {
    if (this.roomFloor === undefined) {
      throw new Error("Floor not properly initialised");
    }
    this.roomFloor.conductivity = conductivity;
  }

  setWallThickness(wallId: string, thickness: number) {
    let wall = this.walls.filter((w) => w.id === wallId);
    if (wall === undefined || wall.length !== 1) {
      throw new Error("Wall " + wallId + " is invalid");
    }
    wall[0].thickness = thickness;
  }

  setCeilingThickness(thickness: number) {
    if (this.ceiling === undefined) {
      throw new Error("Ceiling not properly initialised");
    }
    this.ceiling.thickness = thickness;
  }

  setFloorThickness(thickness: number) {
    if (this.ceiling === undefined) {
      throw new Error("Floor not properly initialised");
    }
    this.roomFloor.thickness = thickness;
  }

  setFloorInsulation(floorInsulation: FloorInsulation) {
    if (this.roomFloor === undefined) {
      throw new Error("Floor not properly initialised");
    }
    this.roomFloor.floorInsulation = floorInsulation;
  }

  setFloorHeating(floorHeating: FloorHeating) {
    if (this.roomFloor === undefined) {
      throw new Error("Floor not properly initialised");
    }
    this.roomFloor.floorHeating = floorHeating;
  }

  clearFloorInsulation() {
    this.roomFloor.clearFloorInsulation();
  }

  clearFloorHeating() {
    this.roomFloor.clearFloorHeating();
  }

  // Generally model does not store calculated values, this is an exception where,
  // it store airflow since it is expensive to calculate and required by
  // infiltration(ventilation) load calculations
  updateDoorAirflow(wallId: string, doorId: string, airflow: number) {
    let wallWithDoor = this.findWall(wallId);
    let opening = wallWithDoor.findDoor(doorId);
    opening.door.airflow = airflow;
  }

  infiltrationLoad = () => {
    if (!this.roomAirCondition)
      throw new TypeError(
        "Missing mandatory attributes room temperature and room humidity"
      );

    if (!this.outsideAirCondition)
      throw new TypeError(
        "Missing mandatory attributes outside temperature and outside humidity"
      );

    let request = {
      air_flow: this.airFlow(),
      room_temperature: this.roomAirCondition.roomTemperature,
      room_humidity: this.roomAirCondition.roomHumidity,
      outside_temperature: this.outsideAirCondition.roomTemperature,
      outside_humidity: this.outsideAirCondition.roomHumidity,
    };

    return calculateInfiltrationLoad(request).then((heatCapacity) => {
      return heatCapacity;
    });
  };

  wallTransmissionLoad() {
    if (!this.roomAirCondition) throw Error("Missing room temperature");
    let ts: HeatLoadCapacity[] = [];

    this.walls.forEach((w) => {
      let t = w.transmissionLoad(this.roomAirCondition.roomTemperature);
      ts.push({
        ...t,
        reference: w.id,
        heatLoadType: HeatLoadSections.WALL_TRANSMISSION.name,
      });
    });

    return ts;
  }

  ceilingTransmissionLoad(): HeatLoadCapacity {
    if (!this.roomAirCondition) throw Error("Missing room temperature");

    let ceilingTransmissionLoad = this.ceiling.transmissionLoad(
      this.roomAirCondition.roomTemperature
    );

    return {
      ...ceilingTransmissionLoad,
      reference: "ceiling",
      heatLoadType: HeatLoadSections.CEILING_TRANSMISSION.name,
    };
  }

  floorTransmissionLoad(): HeatLoadCapacity {
    if (!this.roomAirCondition) throw Error("Missing room temperature");
    let floorTransmissionLoad = this.roomFloor.transmissionLoad(
      this.roomAirCondition.roomTemperature
    );

    return {
      ...floorTransmissionLoad,
      reference: this.roomFloor.id,
      heatLoadType: HeatLoadSections.FLOOR_TRANSMISSION.name,
    };
  }

  floorHeatingLoad(): HeatLoadCapacity {
    if (
      this.roomFloor.floorHeating === undefined ||
      this.roomFloor.floorHeating.wattagePerSqm === undefined
    )
      throw Error("Missing floor heating");
    let floorHeatingLoad = this.roomFloor.floorHeatingLoad(
      this.roomFloor.floorHeating.wattagePerSqm
    );

    return {
      ...floorHeatingLoad,
      reference: this.roomFloor.id,
      heatLoadType: HeatLoadSections.FLOOR_HEATING.name,
    };
  }

  private removeAllDoors() {
    this._doorToWall.forEach((wId, dId) => {
      let wall = this.findWall(wId);
      wall.removeDoor(dId);
    });
    this._doorToWall = new Map<string, string>();
  }

  updateDoors(doors: DoorModel[]): WallDoor[] {
    this.removeAllDoors();

    doors.forEach((d) => {
      let wd = this.addOrUpdateDoor(
        d.wallId,
        d.doorId,
        d.height,
        d.width,
        d.doorAttributes,
        d.numberOfDoors
      );
      this._doorToWall.set(wd.doorId, wd.wallId);
    });

    let updated: WallDoor[] = [];
    this._doorToWall.forEach((wId, dId) => {
      updated.push({
        doorId: dId,
        wallId: wId,
      });
    });
    return updated;
  }

  addOrUpdateDoor(
    wallId: string,
    doorId: string | undefined,
    height: number,
    width: number,
    doorAttributes: DoorAttributes,
    noOfDoors: number
  ): WallDoor {
    let wallWithDoor = this.findWall(wallId);
    let dId = wallWithDoor.addOrUpdateDoor(
      doorId,
      height,
      width,
      doorAttributes,
      noOfDoors
    );
    let currentWall = this._doorToWall.get(dId);
    if (currentWall && currentWall !== wallId) {
      this.findWall(currentWall).removeDoor(dId);
      this._doorToWall.delete(dId);
    }
    this._doorToWall.set(dId, wallId);
    return {
      doorId: dId,
      wallId: wallId,
    };
  }

  async doorInfiltrationLoad(
    wallId: string,
    doorId: string
  ): Promise<HeatCapacity> {
    let wallWithDoor = this.findWall(wallId);
    return await wallWithDoor
      .infiltrationLoad(doorId, this.roomAirCondition)
      .then((load) => load);
  }

  transmissionLoadForDoors(): HeatLoadCapacity[] {
    let ts: HeatLoadCapacity[] = [];
    this._doorToWall.forEach((wallId, doorId) => {
      let load = this.doorTransmissionLoad(wallId, doorId);
      ts.push({
        ...load,
        reference: doorId,
        heatLoadType: HeatLoadSections.DOOR_TRANSMISSION.name,
      });
    });
    return ts;
  }

  doorTransmissionLoad(wallId: string, doorId: string): HeatLoadCapacity {
    let wallWithDoor = this.findWall(wallId);
    let opening = wallWithDoor.findDoor(doorId);
    let load: LoadCapacity = opening.transmissionLoad(
      this.roomAirCondition.roomTemperature
    );

    return {
      latent: load.latent,
      sensible: load.sensible,
      reference: wallId,
      heatLoadType: HeatLoadSections.DOOR_TRANSMISSION.name,
    };
  }

  heatingLoadForDoors(): HeatLoadCapacity[] {
    let ts: HeatLoadCapacity[] = [];
    this._doorToWall.forEach((wallId, doorId) => {
      let [heatedFasciaLoad, heatedGlassLoad] = this.doorHeatingLoad(
        wallId,
        doorId
      );
      ts.push({
        ...heatedFasciaLoad,
        reference: doorId,
        heatLoadType: HeatLoadSections.DOOR_HEATED_FASCIA.name,
      });
      if (heatedGlassLoad.sensible) {
        ts.push({
          ...heatedGlassLoad,
          reference: doorId,
          heatLoadType: HeatLoadSections.DOOR_HEATED_GLASS.name,
        });
      }
    });
    return ts;
  }

  doorHeatingLoad(wallId: string, doorId: string): any {
    // let matchedDoorObj = doorDetails.filter((v) => v.id === wd.doorId)[0];
    let wallWithDoor = this.findWall(wallId);
    let opening: Opening = wallWithDoor.findDoor(doorId);
    let door = opening.door;

    // Heated fascia uses perimeter of door (in metre)
    let heatedFasciaLoad =
      opening.noOfDoors *
      door.doorAttributes.heatedFascia *
      (door.perimeter / 1e3);

    // Heated glass uses area of door (in metre squared)
    let heatedGlassLoad =
      opening.noOfDoors * door.doorAttributes.heatedGlass * (door.area / 1e6);

    return [
      {
        latent: 0,
        sensible: heatedFasciaLoad,
        reference: wallId,
        heatLoadType: HeatLoadSections.DOOR_HEATED_FASCIA.name,
      },
      {
        latent: 0,
        sensible: heatedGlassLoad,
        reference: wallId,
        heatLoadType: HeatLoadSections.DOOR_HEATED_GLASS.name,
      },
    ];
  }

  findWall(wallId: string) {
    let wall = this.walls.find((w) => w.id === wallId);
    if (!wall) throw Error("Wall does not exist with id:" + wallId);
    return wall;
  }

  peopleLoad() {
    let latent = 0;
    let sensible = 0;
    let activityFactor = 1;
    if (
      this.people !== undefined &&
      this.people.peopleCount > 0 &&
      this.people.hours > 0 &&
      this.people.activityFactor > 0
    ) {
      activityFactor = findActivityFactor(this.people.activityLevel);
      let load =
        (activityFactor *
          (PERSON_HEAT_EQUIVALENT_AT_ZERO -
            HEAT_LOAD_PER_PERSON * this.roomAirCondition.roomTemperature) *
          this.people.peopleCount *
          this.people.hours) /
        HOURS_PER_DAY;
      let heatRate = sensibleToLatentRatio(
        this.roomAirCondition.roomTemperature
      );
      latent = heatRate * load;
      sensible = (1 - heatRate) * load;
    }

    return heatLoad(
      HeatLoadSections.INTERNAL_PEOPLE.name,
      "people",
      sensible,
      latent
    );
  }

  lightingLoad() {
    let sensible = 0;
    if (this.lighting && this.roomFloor && this.roomFloor.surfaceArea() > 0) {
      sensible =
        (this.lighting.heatGenerated *
          (this.roomFloor.surfaceArea() / (1000 * 1000)) *
          this.lighting.hours) /
        HOURS_PER_DAY;
    }

    return heatLoad(
      HeatLoadSections.INTERNAL_LIGHTING.name,
      "lighting",
      sensible,
      0
    );
  }

  forkliftLoad() {
    let sensible = 0;

    if (
      this.forklift !== undefined &&
      this.forklift.heatGain > 0 &&
      this.forklift.hours > 0
    ) {
      sensible = (this.forklift.heatGain * this.forklift.hours) / HOURS_PER_DAY;
    }

    return heatLoad(
      HeatLoadSections.INTERNAL_MACHINERY.name,
      "forklift",
      sensible,
      0
    );
  }
}

export default ColdStorage;
