import React, { FC } from "react";
import ProductCard from "../../../components/ProductCard/ProductCard";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";

const EvaporatorCard: FC<{
  evaporator: EvaporatorProductCapacity;
  refrigerant: string;
}> = ({ evaporator, refrigerant }) => {
  let { model, thumbnail, product_code, series } = evaporator;
  let productDescriptionValues = [
    {
      label: "Product code",
      value: product_code,
    },
    { label: "Refrigerant", value: refrigerant },
    { label: "Series", value: series },
  ];

  return (
    <ProductCard
      title={model}
      thumbnail={thumbnail}
      productDescriptionValues={productDescriptionValues}
    />
  );
};

export default EvaporatorCard;
