import React, { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ProductFilter } from "../../../../types/ProductFilter";
import { DoorDetails as DoorModel, RoomDimensions } from "../../../types/Room";
import DoorDetails from "./DoorDetails";
import actionSelector from "../../../duck/actions";
import isNaNReturnDefault from "../../../../helpers/isNaNReturnNumber";
import {
  heatedFasciaDirty,
  heatedGlassDoorDirty,
  validateDoors,
} from "./DoorDetailsValidation";
import { StandardRoom } from "../../../../types/HeatLoad";
import { EMPTY_FILTER } from "../../../hooks/ExtractHeatloadFilters";
import { logPageEventWithData } from "../../../../helpers/amplitude";

export interface DoorDetailsForm {
  doors: Door[];
}

interface Door {
  doorId: string;
  type: string;
  material?: string;
  glazing?: string;
  usage?: string;
  height?: number;
  width?: number;
  location: string; // wall id
  numberOfDoors: number;
  heatedFascia?: string;
  customHeatedFascia?: number;
  heatedGlass?: string;
  customHeatedGlass?: number;
}

const defaultValues: DoorDetailsForm = {
  doors: [
    {
      doorId: "1",
      type: "solid",
      height: undefined,
      width: undefined,
      location: "",
      numberOfDoors: 1,
    },
  ], //Initialise produce with 1 entry
};

const convertDoors = (
  d: Door,
  i: number,
  doorCategoryFilter: ProductFilter,
  doorMaterialFilter: ProductFilter,
  glassGlazingFilter: ProductFilter,
  doorUsageFilter: ProductFilter
): DoorModel | undefined => {
  let doorTypeExtendedValues;

  if (d.type && doorCategoryFilter.options) {
    let option = doorCategoryFilter.options.find((o) => o.key === d.type);
    if (option && "extended_values" in option) {
      doorTypeExtendedValues = option["extended_values"];
    }
  }

  let doorUsageExtendedValues;

  if (d.usage && doorUsageFilter.options) {
    let option = doorUsageFilter.options.find((o) => o.key === d.usage);
    if (option && "extended_values" in option) {
      doorUsageExtendedValues = option["extended_values"];
    }
  }

  if (doorTypeExtendedValues && doorUsageExtendedValues) {
    let glazingExtendedValues;
    let materialExtendedValues;
    let uValue: number = NaN;
    let conductivity: number = NaN;

    if (d.type === "glass") {
      if (d.glazing !== "" && glassGlazingFilter.options) {
        let option = glassGlazingFilter.options.find(
          (o) => o.key === d.glazing
        );
        if (option && "extended_values" in option) {
          glazingExtendedValues = option["extended_values"];
          if (glazingExtendedValues && "uValue" in glazingExtendedValues) {
            uValue = parseFloat(glazingExtendedValues["uValue"]);
          }
        }
      }
    }

    if (d.type === "solid") {
      if (d.material !== "" && doorMaterialFilter.options) {
        let option = doorMaterialFilter.options.find(
          (o) => o.key === d.material
        );
        if (option && "extended_values" in option) {
          materialExtendedValues = option["extended_values"];
          if (
            materialExtendedValues &&
            "conductivity" in materialExtendedValues
          ) {
            conductivity = parseFloat(materialExtendedValues["conductivity"]);
          }
        }
      }
    }

    return {
      id: `${i + 1}`,
      type: d.type,
      material: d.material ?? "",
      glazing: d.glazing ?? "",
      usage: d.usage ?? "",
      height: isNaNReturnDefault(d.height ?? 0),
      width: isNaNReturnDefault(d.width ?? 0),
      location: d.location,
      numberOfDoors: d.numberOfDoors,
      heatedFascia: d.heatedFascia ?? "",
      ...(d.heatedGlass !== undefined && { heatedGlass: d.heatedGlass }),
      ...(d.heatedFascia === "custom" && {
        customHeatedFascia: d.customHeatedFascia,
      }),
      ...(d.heatedGlass === "custom" && {
        customHeatedGlass: d.customHeatedGlass,
      }),
      extendedValues: {
        doorFactor: parseFloat(doorTypeExtendedValues["door_factor"]),
        uValue: uValue,
        conductivity: conductivity,
        usageFactor: parseFloat(doorUsageExtendedValues["usage_factor"]),
      },
    };
  }

  return undefined;
};

const validDoorType = (d: Door) => {
  return (
    (d.type === "glass" && d.glazing && d.glazing.length > 0) ||
    (d.type === "solid" && d.material && d.material.length > 0)
  );
};

const DoorDetailsContainer: FC<{}> = () => {
  const methods = useForm<DoorDetailsForm>({
    defaultValues,
    mode: "onBlur",
  });

  const dispatch = useDispatch();
  const { watch, setValue, resetField, getValues } = methods;
  const { errors, dirtyFields, isValidating } = methods.formState;

  const roomDimensions: RoomDimensions = useSelector((state: any) => {
    return state.heatLoad.roomDimensions;
  });

  const roomDetails: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const doorwayMaterialFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["door_material"]
      : EMPTY_FILTER
  );

  const doorwayCategoryFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors.doorway_category
      : EMPTY_FILTER
  );

  const glassGlazingFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["glass_glaze"]
      : EMPTY_FILTER
  );

  const doorUsageFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["door_usage"]
      : EMPTY_FILTER
  );

  const heatedFasciaFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["doorway_heated_frame"]
      : EMPTY_FILTER
  );

  const heatedGlassFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.doors
      ? state.heatLoad.formHeatLoadFilters.doors["doorway_heated_glass"]
      : EMPTY_FILTER
  );

  const doorDetailsEnabled: boolean = useSelector((state: any) => {
    return state.heatLoad.formSections.doorDetailsEnabled;
  });

  let removeDoorCallBack = useCallback(() => {
    const doorsForm = watch("doors");
    let newDoors: DoorModel[] = [];

    doorsForm.forEach((d, i) => {
      if (validateDoors(errors, dirtyFields, i)) {
        let doorDetails = convertDoors(
          d,
          i,
          doorwayCategoryFilter,
          doorwayMaterialFilter,
          glassGlazingFilter,
          doorUsageFilter
        );
        if (doorDetails !== undefined) {
          newDoors.push(doorDetails);
        }
      }
    });

    dispatch(actionSelector.saveDoorDetails(newDoors));

    logPageEventWithData("Heat Load - Doors updated", {
      newDoors,
    });
  }, [
    watch,
    dispatch,
    errors,
    dirtyFields,
    doorwayCategoryFilter,
    doorwayMaterialFilter,
    glassGlazingFilter,
    doorUsageFilter,
  ]);

  const switchDoorType = useCallback(
    (index: number) => {
      setValue(`doors.${index}.material`, undefined, {
        shouldDirty: true,
      });

      setValue(`doors.${index}.glazing`, undefined, {
        shouldDirty: true,
      });

      setValue(`doors.${index}.usage`, undefined, {
        shouldDirty: true,
      });

      resetField(`doors.${index}.height`, {
        keepDirty: true,
      });

      resetField(`doors.${index}.width`, {
        keepDirty: true,
      });

      const doorType = getValues(`doors.${index}.type`);

      if (doorType === "solid") {
        resetField(`doors.${index}.heatedGlass`, {
          keepDirty: false,
        });
      }

      setValue(`doors.${index}.numberOfDoors`, 1, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue, resetField, getValues]
  );

  useEffect(() => {
    if (doorDetailsEnabled && !dirtyFields.doors) {
      setValue("doors.0.location", roomDimensions.walls[0].id, {
        shouldDirty: true,
      });
    }
  }, [setValue, roomDimensions.walls, doorDetailsEnabled, dirtyFields]);

  useEffect(() => {
    const doors = watch("doors");
    if (doors && isValidating) {
      let newDoors: DoorModel[] = [];
      doors.forEach((d, i) => {
        if (validateDoors(errors, dirtyFields, i) && validDoorType(d)) {
          let doorDetails = convertDoors(
            d,
            i,
            doorwayCategoryFilter,
            doorwayMaterialFilter,
            glassGlazingFilter,
            doorUsageFilter
          );

          if (doorDetails !== undefined) {
            newDoors.push(doorDetails);
          }
        }
      });

      dispatch(actionSelector.saveDoorDetails(newDoors));
    }
  }, [
    errors,
    isValidating,
    dirtyFields,
    dispatch,
    doorwayCategoryFilter,
    doorUsageFilter,
    watch,
    doorwayMaterialFilter,
    glassGlazingFilter,
  ]);

  useEffect(() => {
    // Pre-select door heated fascia & heated glass options based on room temperature
    const doorsForm = watch("doors");

    if (
      doorsForm !== undefined &&
      !isNaN(roomDetails.temperature) &&
      doorDetailsEnabled
    ) {
      doorsForm.forEach((d, i) => {
        let isGlassDoorType = d.type === "glass";

        if (!heatedFasciaDirty(dirtyFields, i)) {
          setValue(
            `doors.${i}.heatedFascia`,
            roomDetails.defaultHeatedFasciaValue.toString(),
            {
              shouldDirty: true,
            }
          );
        }

        if (!heatedGlassDoorDirty(dirtyFields, i) && isGlassDoorType) {
          setValue(
            `doors.${i}.heatedGlass`,
            roomDetails.defaultGlassFasciaValue.toString(),
            {
              shouldDirty: true,
            }
          );
        }
      });
    }
  }, [
    isValidating,
    roomDetails.temperature,
    setValue,
    dirtyFields,
    doorDetailsEnabled,
    roomDetails.defaultHeatedFasciaValue,
    roomDetails.defaultGlassFasciaValue,
    watch,
  ]);

  return (
    <FormProvider {...methods}>
      <form>
        <DoorDetails
          doorwayCategoryFilter={doorwayCategoryFilter}
          doorwayMaterialFilter={doorwayMaterialFilter}
          glassGlazingFilter={glassGlazingFilter}
          doorUsageFilter={doorUsageFilter}
          heatedFasciaFilter={heatedFasciaFilter}
          heatedGlassFilter={heatedGlassFilter}
          roomDimensions={roomDimensions}
          removeDoorHandler={removeDoorCallBack}
          roomDetails={roomDetails}
          formEnabled={doorDetailsEnabled}
          switchDoorTypeHandler={switchDoorType}
        />
      </form>
    </FormProvider>
  );
};

export default DoorDetailsContainer;
