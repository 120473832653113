import React, { FC } from "react";
import {
  RegisterOptions,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import { ProductFilter } from "../../../../types/ProductFilter";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import { RoomDimensions, WallDimensions } from "../../../types/Room";
import {
  doorMeasurementRules,
  heatedFasciaAndGlassRules,
} from "./DoorDetailsValidation";
import { StandardRoom } from "../../../../types/HeatLoad";
import { findWallSize } from "../../../../helpers/SimpleCoolRoom";
import Switch, { SwitchValue } from "../../../../components/Switch/Switch";
import InfoText from "../../../../components/InfoText/InfoText";
import FormRow from "../../../../components/FormRow/FormRow";
import { logPageEventWithData } from "../../../../helpers/amplitude";

const AddDoorButton: FC<{
  onClickHandler: () => void;
  disableButton?: boolean;
}> = ({ onClickHandler, disableButton, children }) => {
  return (
    <div className={heatLoadStyles.addButtonContainer}>
      <Button
        buttonStyle="secondary"
        onClickHandler={onClickHandler}
        isDisabled={disableButton}
        imageFilename="add"
        dataTestId="addDoorButton"
      >
        {children}
      </Button>
    </div>
  );
};

interface DoorDetailsProps {
  doorwayCategoryFilter: ProductFilter;
  doorwayMaterialFilter: ProductFilter;
  glassGlazingFilter: ProductFilter;
  doorUsageFilter: ProductFilter;
  heatedFasciaFilter: ProductFilter;
  heatedGlassFilter: ProductFilter;
  roomDimensions: RoomDimensions;
  removeDoorHandler: () => void;
  roomDetails: StandardRoom;
  formEnabled: boolean;
  switchDoorTypeHandler: (index: number) => void;
}

const DoorDetails: FC<DoorDetailsProps> = ({
  doorwayCategoryFilter,
  doorwayMaterialFilter,
  glassGlazingFilter,
  doorUsageFilter,
  heatedFasciaFilter,
  heatedGlassFilter,
  roomDimensions,
  removeDoorHandler,
  roomDetails,
  formEnabled,
  switchDoorTypeHandler,
}) => {
  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "doors",
  });

  const doorTypeOptions: SwitchValue[] = doorwayCategoryFilter.options.map(
    (doorCategory) => {
      return {
        label: doorCategory.value,
        value: doorCategory.key,
      };
    }
  );
  const materialOptions = doorwayMaterialFilter.options;
  const glazingOptions = glassGlazingFilter.options;
  const doorUsageOptions = doorUsageFilter.options;
  const heatedFasciaOptions = heatedFasciaFilter.options;
  const heatedGlassOptions = heatedGlassFilter.options;

  const locationOptions = roomDimensions.walls.map((wall, i) => {
    return {
      key: wall.id,
      value: `Wall ${i + 1}`,
    };
  });

  const handleAddDoor = () => {
    append({
      type: "solid",
      material: "",
      usage: "",
      glazing: "",
      height: undefined,
      width: undefined,
      location: "",
      numberOfDoors: 1,
    });

    logPageEventWithData("Heat Load - Add Door Clicked", {
      numberOfDoors: fields.length,
    });
  };

  const handleDeleteDoor = (index: number) => {
    remove(index);
    removeDoorHandler();
    logPageEventWithData("Heat Load - Delete Door Clicked", {
      numberOfDoors: fields.length,
    });
  };

  const maxRoomWidth = roomDetails.width !== 0 ? roomDetails.width : 150000;
  const maxRoomLength = roomDetails.length !== 0 ? roomDetails.length : 150000;
  let maxDoorWidth =
    maxRoomWidth > maxRoomLength ? maxRoomWidth : maxRoomLength;
  const maxDoorHeight = roomDetails.height !== 0 ? roomDetails.height : 150000;

  const doorWidthRule = (
    maxWidth: number,
    index: number,
    wallOptions: WallDimensions[]
  ): RegisterOptions => {
    let max = maxWidth;
    const wallId = getValues(`doors.${index}.location`);
    const numberOfDoors = getValues(`doors.${index}.numberOfDoors`);
    let wall = wallOptions.find((w) => w.id === wallId);

    if (wall) {
      let wallSize = findWallSize(wall);
      max = wallSize.length / numberOfDoors;
    }

    return {
      required: "Required",
      min: {
        value: 0,
        message: `Must be between 0 and ${max.toFixed(0)}`,
      },
      max: {
        value: max,
        message: `Must be between 0 and ${max.toFixed(0)}`,
      },
      valueAsNumber: true,
      validate: {
        validWidth: (width) => {
          return width <= max || `Must be between 0 and ${max.toFixed(0)}`;
        },
        validDoorWidthPattern: (value) => {
          const matches = value.toString().match(/^\d*\.?\d*$/);
          return matches?.length > 0 || "Must be a number";
        },
      },
    };
  };

  const locationRule = (index: number): RegisterOptions => {
    return {
      required: "Required",
      validate: {
        validateWidth: (option) => {
          if (!option) return true;

          let w = getValues(`doors.${index}.width`);

          setValue(`doors.${index}.width`, w ? w : "", {
            shouldValidate: true,
          });
          return true;
        },
      },
    };
  };

  const numberOfDoorsRule = (index: number): RegisterOptions => {
    let doorWidth = getValues(`doors.${index}.width`);
    const wallId = getValues(`doors.${index}.location`);
    let wall = roomDimensions.walls.find((w) => w.id === wallId);
    let maxNumberOfDoors = 50;
    if (wall) {
      let wallSize = findWallSize(wall);
      maxNumberOfDoors = Math.floor(wallSize.length / doorWidth);
    }

    let errorMessage =
      maxNumberOfDoors === 1
        ? "Must be only 1"
        : `Must be between 1 and ${maxNumberOfDoors}`;

    return {
      required: "Required",
      min: {
        value: 1,
        message: errorMessage,
      },
      max: {
        value: maxNumberOfDoors,
        message: errorMessage,
      },
      valueAsNumber: true,
      validate: {
        validNoOfDoorsPattern: (value) => {
          const matches = value.toString().match(/^\d*\.?\d*$/);
          return matches?.length > 0 || "Must be a number";
        },
      },
    };
  };

  let isCustomHeatedFascia = (index: number) => {
    return watch(`doors.${index}.heatedFascia`) === "custom";
  };

  let isCustomHeatedGlass = (index: number) => {
    return watch(`doors.${index}.heatedGlass`) === "custom";
  };

  return (
    <div
      data-testid="door-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      {formEnabled && fields.length > 0 && (
        <>
          <div>
            <p className="section-description">
              Provide details of entry, exit and safety doors in the room.
            </p>
          </div>

          {fields.map((field, index) => {
            let isGlassDoorType = watch(`doors.${index}.type`) === "glass";

            return (
              <div key={field.id}>
                <label className="labelBoldText">Door style {index + 1}</label>

                <div>
                  <FormRow>
                    <Select
                      {...register(
                        `doors.${index}.location` as const,
                        locationRule(index)
                      )}
                      labelText="Door location"
                      options={locationOptions}
                      hasErrors={errors.doors?.[index]?.location}
                      errorMessage={errors.doors?.[index]?.location?.message}
                      fieldSize="small"
                    />

                    <Switch
                      {...register(`doors.${index}.type` as const, {
                        required: "Required",
                        onChange: (e) => switchDoorTypeHandler(index),
                      })}
                      labelText="Door type"
                      values={doorTypeOptions}
                      hasErrors={errors.doors?.[index]?.type}
                      errorMessage={errors.doors?.[index]?.type?.message}
                    />
                  </FormRow>

                  <FormRow>
                    {!isGlassDoorType && (
                      <Select
                        {...register(`doors.${index}.material` as const, {
                          required: "Required",
                        })}
                        labelText="Door material"
                        options={materialOptions}
                        hasErrors={errors.doors?.[index]?.material}
                        errorMessage={errors.doors?.[index]?.material?.message}
                        fieldSize="large"
                      />
                    )}

                    {isGlassDoorType && (
                      <Select
                        {...register(`doors.${index}.glazing` as const, {
                          required: "Required",
                        })}
                        labelText="Glazing"
                        options={glazingOptions}
                        hasErrors={errors.doors?.[index]?.glazing}
                        errorMessage={errors.doors?.[index]?.glazing?.message}
                        fieldSize="small"
                      />
                    )}

                    <Input
                      {...register(
                        `doors.${index}.numberOfDoors` as const,
                        numberOfDoorsRule(index)
                      )}
                      labelText="No. of doors"
                      placeholderText="1 to 50"
                      hasErrors={errors.doors?.[index]?.numberOfDoors}
                      errorMessage={
                        errors.doors?.[index]?.numberOfDoors?.message
                      }
                      fieldSize="small"
                    />
                  </FormRow>

                  <FormRow>
                    <Input
                      {...register(
                        `doors.${index}.width` as const,
                        doorWidthRule(maxDoorWidth, index, roomDimensions.walls)
                      )}
                      labelText="Width"
                      placeholderText={`0 to ${maxDoorWidth}`}
                      innerLabel="mm"
                      hasErrors={errors.doors?.[index]?.width}
                      errorMessage={errors.doors?.[index]?.width?.message}
                      fieldSize="small"
                    />

                    <Input
                      {...register(
                        `doors.${index}.height` as const,
                        doorMeasurementRules(maxDoorHeight)
                      )}
                      labelText="Height"
                      placeholderText={`0 to ${maxDoorHeight}`}
                      innerLabel="mm"
                      hasErrors={errors.doors?.[index]?.height}
                      errorMessage={errors.doors?.[index]?.height?.message}
                      fieldSize="small"
                    />

                    <Select
                      {...register(`doors.${index}.usage` as const, {
                        required: "Required",
                      })}
                      labelText="Usage"
                      options={doorUsageOptions}
                      hasErrors={errors.doors?.[index]?.usage}
                      errorMessage={errors.doors?.[index]?.usage?.message}
                      fieldSize="small"
                    />
                  </FormRow>

                  <FormRow>
                    <Select
                      {...register(`doors.${index}.heatedFascia` as const, {
                        required: "Required",
                      })}
                      labelText="Heated fascia"
                      options={heatedFasciaOptions}
                      hasErrors={errors.doors?.[index]?.heatedFascia}
                      errorMessage={
                        errors.doors?.[index]?.heatedFascia?.message
                      }
                      fieldSize="small"
                    />

                    {isCustomHeatedFascia(index) && (
                      <Input
                        {...register(
                          `doors.${index}.customHeatedFascia` as const,
                          heatedFasciaAndGlassRules("W/m")
                        )}
                        labelText="Wattage"
                        placeholderText="1 to 500"
                        innerLabel="W/m"
                        hasErrors={errors.doors?.[index]?.customHeatedFascia}
                        errorMessage={
                          errors.doors?.[index]?.customHeatedFascia?.message
                        }
                        fieldSize="small"
                      />
                    )}
                  </FormRow>

                  {isGlassDoorType && (
                    <FormRow>
                      <Select
                        {...register(`doors.${index}.heatedGlass` as const)}
                        labelText="Heated glass"
                        options={heatedGlassOptions}
                        hasErrors={errors.doors?.[index]?.heatedGlass}
                        errorMessage={
                          errors.doors?.[index]?.heatedGlass?.message
                        }
                        fieldSize="small"
                      />

                      {isCustomHeatedGlass(index) && (
                        <Input
                          {...register(
                            `doors.${index}.customHeatedGlass` as const,
                            heatedFasciaAndGlassRules(`W/m\u00B2`)
                          )}
                          labelText="Wattage"
                          placeholderText="1 to 500"
                          innerLabel={`W/m\u00B2`}
                          hasErrors={errors.doors?.[index]?.customHeatedGlass}
                          errorMessage={
                            errors.doors?.[index]?.customHeatedGlass?.message
                          }
                          fieldSize="small"
                        />
                      )}
                    </FormRow>
                  )}

                  <div className={heatLoadStyles.deleteButtonContainer}>
                    {index > 0 && (
                      <Button
                        onClickHandler={() => {
                          handleDeleteDoor(index);
                        }}
                        buttonType="button"
                        buttonStyle="secondary_red"
                        imageFilename="delete_outline_red"
                        dataTestId="deleteDoorButton"
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <AddDoorButton
            onClickHandler={handleAddDoor}
            disableButton={fields.length >= 10}
          >
            Add door style
          </AddDoorButton>
        </>
      )}

      {!formEnabled && (
        <InfoText testid="no-door-details">
          Add your Door details after you’ve defined your room and transmission
          details.
        </InfoText>
      )}
    </div>
  );
};

export default DoorDetails;
