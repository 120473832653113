// Room
export const SAVE_STANDARD_ROOM_DETAILS = "SAVE_STANDARD_ROOM_DETAILS";
export const SAVE_L_SHAPED_ROOM_DETAILS = "SAVE_L_SHAPED_ROOM_DETAILS";
export const SAVE_WALL_DETAILS = "SAVE_WALL_DETAILS";
export const SAVE_CEILING_DETAILS = "SAVE_CEILING_DETAILS";
export const SAVE_FLOOR_DETAILS = "SAVE_FLOOR_DETAILS";
export const SAVE_ROOM_DIMENSIONS = "SAVE_ROOM_DIMENSIONS";

// Product
export const SAVE_PRODUCT_DETAILS = "SAVE_PRODUCT_DETAILS";

// Heat load
export const SAVE_HEAT_LOAD_CAPACITY = "SAVE_HEAT_LOAD_CAPACITY";
export const DISPLAY_HEAT_LOAD_FILTERS = "DISPLAY_HEAT_LOAD_FILTERS";
export const SAVE_HEAT_LOAD_FILTERS = "SAVE_HEAT_LOAD_FILTERS";

// Doors
export const SAVE_DOOR_DETAILS = "SAVE_DOOR_DETAILS";

// Additional loads
export const SAVE_PEOPLE_DETAILS = "SAVE_PEOPLE_DETAILS";
export const SAVE_LIGHTING_DETAILS = "SAVE_LIGHTING_DETAILS";
export const SAVE_VENTILATION_DETAILS = "SAVE_VENTILATION_DETAILS";

// Forklifts
export const SAVE_FORKLIFT_DETAILS = "SAVE_FORKLIFT_DETAILS";

// Safety factor, fan factor and run time
export const SAVE_SAFETY_AND_RUNTIME = "SAVE_SAFETY_AND_RUNTIME";

export const SAVE_FORM_SECTION_STATE = "SAVE_FORM_SECTION_STATE";

// Clear redux store
export const CLEAR_ALL = "CLEAR_ALL";

export default {
  //Room
  SAVE_STANDARD_ROOM_DETAILS,
  SAVE_L_SHAPED_ROOM_DETAILS,
  SAVE_WALL_DETAILS,
  SAVE_CEILING_DETAILS,
  SAVE_FLOOR_DETAILS,
  SAVE_ROOM_DIMENSIONS,

  //Product
  SAVE_PRODUCT_DETAILS,

  // Heat load
  SAVE_HEAT_LOAD_CAPACITY,
  SAVE_HEAT_LOAD_FILTERS,

  // Doors
  SAVE_DOOR_DETAILS,

  // Miscellaneous
  SAVE_PEOPLE_DETAILS,
  SAVE_LIGHTING_DETAILS,
  SAVE_VENTILATION_DETAILS,

  // Forklifts
  SAVE_FORKLIFT_DETAILS,

  // Additional
  SAVE_SAFETY_AND_RUNTIME,

  // Form section enabled/disabled state
  SAVE_FORM_SECTION_STATE,

  // Clear any state
  CLEAR_ALL,
};
