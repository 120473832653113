import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import { ProductFilter } from "../../../../types/ProductFilter";
import { usageRules, heatGainRules } from "./LightingDetailsValidation";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import FormRow from "../../../../components/FormRow/FormRow";

const LightingDetails: FC<{
  lightingOptions: ProductFilter;
}> = ({ lightingOptions }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const lightingTypeOptions = lightingOptions.options;
  const lightingType = watch("lightingType");

  useEffect(() => {
    let extendedValues;
    let option = lightingTypeOptions.find((o) => o.key === lightingType);

    if (option && "extended_values" in option) {
      extendedValues = option["extended_values"];
      if (extendedValues)
        setValue("heatGenerated", extendedValues["heat_generated"]);
    }
  }, [lightingType, setValue, lightingTypeOptions]);

  return (
    <div
      data-testid="light-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      <div>
        <FormRow>
          <Select
            {...register("lightingType", {
              required: "Required",
            })}
            labelText="Light options"
            options={lightingTypeOptions}
            hasErrors={errors.lightingType}
            errorMessage={errors.lightingType?.message}
            fieldSize="small"
          />

          <Input
            {...register("heatGenerated", heatGainRules)}
            labelText="Heat gain"
            innerLabel="W/m²"
            placeholderText="1 to 10000"
            hasErrors={errors.heatGain}
            errorMessage={errors.heatGain?.message}
            fieldSize="medium"
          />
        </FormRow>

        <Input
          {...register("lightUsage", usageRules)}
          labelText="Usage per day"
          innerLabel="hrs"
          placeholderText="0 to 24"
          hasErrors={errors.lightUsage}
          errorMessage={errors.lightUsage?.message}
          fieldSize="small"
        />
      </div>
    </div>
  );
};

export default LightingDetails;
