import React, { FC, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import styles from "./HeatLoad.module.css";
import RoomDetailsContainer from "../sections/RoomDetails/RoomDetailsContainer";
import ProductDetailsContainer from "../sections/ProductDetails/ProductDetailsContainer";
import TransmissionDetailsContainer from "../sections/TransmissionDetails/TransmissionDetailsContainer";
import HeatLoadCalculationContainer from "../sections/HeatLoadCalculaton/HeatLoadCalculationContainer";
import { SummaryViewContainer } from "../sections/CalculationSummary/SummaryViewContainer";
import { PreviewContainer } from "../sections/RoomPreview/PreviewContainer";
import useHeatLoadFilters from "../hooks/useHeatLoadFilters";
import { useDispatch } from "react-redux";
import DoorDetailsContainer from "../sections/TransmissionDetails/DoorDetails/DoorDetailsContainer";
import AdditionalLoadsContainer from "../sections/AdditionalLoads/AdditionalLoadsContainer";
import SafetyAndRuntimeDetailsContainer from "../sections/SafetyAndRuntimeDetails/SafetyAndRuntimeDetailsContainer";
import actionSelector from "../duck/selectors";
import Accordion from "../../components/Accordion/Accordion";
import AccordionSection from "../../components/AccordionSection/AccordionSection";
declare const window: any;
const HEAT_LOAD_TEST_LOGS_ENABLE = `${window.__runtime_configuration.HEAT_LOAD_TEST_LOGS_ENABLE}`;

const HeatLoad: FC = () => {
  useHeatLoadFilters();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actionSelector.clearAll());
    };
  }, [dispatch]);

  return (
    <Layout>
      <div className="section-header">Heat load calculation</div>

      <div className={styles.heatLoadContainer}>
        <div>
          <Accordion>
            <AccordionSection title="Location details" isOpen={true}>
              <RoomDetailsContainer />
            </AccordionSection>
            <AccordionSection title="Transmission details">
              <TransmissionDetailsContainer />
            </AccordionSection>
            <AccordionSection title="Door details">
              <DoorDetailsContainer />
            </AccordionSection>
            <AccordionSection title="Product details">
              <ProductDetailsContainer />
            </AccordionSection>
            <AccordionSection title="Additional loads">
              <AdditionalLoadsContainer />
            </AccordionSection>
            <AccordionSection title="Safety factor and runtime" isOpen={true}>
              <SafetyAndRuntimeDetailsContainer />
            </AccordionSection>
          </Accordion>

          <HeatLoadCalculationContainer
            enableHeatLoadLogs={HEAT_LOAD_TEST_LOGS_ENABLE === "true"}
          />
        </div>

        <div className={styles.summaryContainer}>
          <Accordion>
            <AccordionSection title="Preview" isOpen={true}>
              <PreviewContainer />
            </AccordionSection>
            <AccordionSection title="Summary" isOpen={true}>
              <SummaryViewContainer />
            </AccordionSection>
          </Accordion>
        </div>
      </div>
    </Layout>
  );
};

export default HeatLoad;
