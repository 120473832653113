import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RoomDetails from "./RoomDetails";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import { StandardRoom } from "../../../types/HeatLoad";
import isNaNReturnDefault from "../../../helpers/isNaNReturnNumber";
import { validateRoomDetails } from "./RoomDetailsValidation";
import { ProductFilter } from "../../../types/ProductFilter";
import { EMPTY_FILTER } from "../../hooks/ExtractHeatloadFilters";
import { GroupedOption } from "../../../types/SearchSelect";
import { buildProductGroupOptionsWithGroupAppended } from "../ProductDetails/ProductDetailsHelper";
import { logPageEventWithData } from "../../../helpers/amplitude";

export interface RoomDetailsForm {
  locationSelected: { label: string; value: string };
  temperature: number;
  humidity: number;
  length: number;
  width: number;
  height: number;
  outsideTemperature: number;
  outsideHumidity: number;
  groundTemperature?: number;
}

const LOW_TEMP_RUNTIME = 18;
const MID_TEMP_RUNTIME = 16;
const LOW_TEMP_FAN_LOAD_RATIO = 14;
const MID_TEMP_FAN_LOAD_RATIO = 6;
const LOW_TEMP_HEATED_FASCIA = 16;
const MID_TEMP_HEATED_FASCIA = 9;
const LOW_TEMP_GLASS_FASCIA = 86;
const MID_TEMP_GLASS_FASCIA = 43;

const RoomDetailsContainer: FC<{}> = () => {
  const methods = useForm<RoomDetailsForm>({
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const { isValidating, errors, dirtyFields } = methods.formState;

  const locationFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.locations
      ? state.heatLoad.formHeatLoadFilters.locations.location
      : EMPTY_FILTER
  );

  const locationCategoryFilter: ProductFilter = useSelector((state: any) =>
    state.heatLoad.formHeatLoadFilters.locations
      ? state.heatLoad.formHeatLoadFilters.locations.location_state
      : EMPTY_FILTER
  );

  const [groupedOptions, setGroupOption] = useState<GroupedOption[]>([]);

  useEffect(() => {
    setGroupOption(
      buildProductGroupOptionsWithGroupAppended(
        locationFilter,
        locationCategoryFilter
      )
    );
  }, [locationFilter, locationCategoryFilter, setGroupOption]);

  useEffect(() => {
    const { watch } = methods;
    const room = watch();

    // If there are no errors in the room object, dispatch to redux..
    if (isValidating && validateRoomDetails(errors, dirtyFields)) {
      let roomTemperature = isNaNReturnDefault(room.temperature);
      let runtime = roomTemperature > 0 ? MID_TEMP_RUNTIME : LOW_TEMP_RUNTIME;
      let fanFactor =
        roomTemperature > 0 ? MID_TEMP_FAN_LOAD_RATIO : LOW_TEMP_FAN_LOAD_RATIO;
      let heatedFasciaValue =
        roomTemperature > 0 ? MID_TEMP_HEATED_FASCIA : LOW_TEMP_HEATED_FASCIA;
      let glassFasciaValue =
        roomTemperature > 0 ? MID_TEMP_GLASS_FASCIA : LOW_TEMP_GLASS_FASCIA;
      let outsideTemperature = room.outsideTemperature
        ? room.outsideTemperature
        : roomTemperature;
      let outsideHumidity = room.outsideHumidity
        ? room.outsideHumidity
        : room.humidity;

      const roomDetails: StandardRoom = {
        temperature: roomTemperature,
        humidity: isNaNReturnDefault(room.humidity),
        length: isNaNReturnDefault(room.length),
        width: isNaNReturnDefault(room.width),
        height: isNaNReturnDefault(room.height),
        outsideTemperature: isNaNReturnDefault(outsideTemperature),
        outsideHumidity: isNaNReturnDefault(outsideHumidity),
        groundTemperature: isNaNReturnDefault(room.groundTemperature ?? NaN),
        defaultRuntime: runtime,
        defaultFanFactor: fanFactor,
        defaultHeatedFasciaValue: heatedFasciaValue,
        defaultGlassFasciaValue: glassFasciaValue,
      };

      dispatch(actionSelector.saveStandardRoomDetails(roomDetails));
      dispatch(
        actionSelector.saveFormSectionState({
          transmissionDetailsEnabled: true,
        })
      );

      logPageEventWithData("Heat Load - Location Details Saved", {
        location: room.locationSelected.value,
        outsideTemperature: isNaNReturnDefault(outsideTemperature),
        outsideHumidity: isNaNReturnDefault(outsideHumidity),
        groundTemperature: room.groundTemperature,
      });

      logPageEventWithData("Heat Load - Room Details Saved", {
        temperature: roomTemperature,
        humidity: isNaNReturnDefault(room.humidity),
        length: isNaNReturnDefault(room.length),
        width: isNaNReturnDefault(room.width),
        height: isNaNReturnDefault(room.height),
      });
    }
  }, [dirtyFields, errors, isValidating, dispatch, methods]);

  return (
    <FormProvider {...methods}>
      <div>
        <form>
          <RoomDetails
            locationGroupedOptions={groupedOptions}
            locationFilter={locationFilter}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default RoomDetailsContainer;
