import { navigate } from "hookrouter";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import RadioGroup, {
  RadioValue,
} from "../../../components/RadioGroup/RadioGroup";
import Section from "../../../components/Section/Section";
import styles from "./SearchTypeSelection.module.css";

const SearchTypeSelection: FC<{ defaultValue: string }> = ({
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm<any>({
    defaultValues: { searchTypeSelection: defaultValue },
    mode: "onBlur",
  });
  const watchSearchType = watch("searchTypeSelection");
  const radioValues: RadioValue[] = [
    {
      label: "Condensing unit & evaporator combination",
      value: "/equipment-selection",
    },
    {
      label: "Condensing Unit only",
      value: "/condensing-unit-search",
    },
    {
      label: "Evaporator only",
      value: "/evaporator-search",
    },
  ];

  useEffect(() => {
    navigate(watchSearchType);
  }, [watchSearchType]);

  return (
    <form className={styles.formContainer}>
      <div className="section-header">Selection Type</div>
      <Section>
        <RadioGroup
          {...register("searchTypeSelection")}
          labelText="What type of selection are you after?"
          values={radioValues}
          hasErrors={errors.searchTypeSelection}
          hasBorder={true}
        />
      </Section>
    </form>
  );
};

export default SearchTypeSelection;
