import React, { FC, useEffect } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import Input from "../../../components/Input/Input";
import {
  outsideTemperatureRules,
  roomLengthRules,
  roomHumidityRules,
  outsideHumidityRules,
} from "./RoomDetailsValidation";
import heatLoadStyles from "../../page/HeatLoad.module.css";
import SearchGroupSelect from "../../../hook-form-components/SearchSelect/SearchGroupSelect";
import { GroupedOption } from "../../../types/SearchSelect";
import { ProductFilter } from "../../../types/ProductFilter";
import calcRelativeHumidity from "../../../calculations/humidity/RelativeHumidityGivenBulbTemp";
import FormRow from "../../../components/FormRow/FormRow";

const RoomDetails: FC<{
  locationGroupedOptions: GroupedOption[];
  locationFilter: ProductFilter;
}> = ({ locationGroupedOptions, locationFilter }) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    formState,
  } = useFormContext();

  const locationOptions = locationFilter.options;
  const [locationSelected, outsideTemperature, wetBulbTemperature] = watch([
    "locationSelected",
    "outsideTemperature",
    "wetBulbTemperature",
  ]);

  useEffect(() => {
    // Fill location related fields based on location selected
    let extendedValues;
    let option = locationOptions.find((o) => o.key === locationSelected?.value);

    if (option && "extended_values" in option) {
      extendedValues = option["extended_values"];
      if (extendedValues) {
        setValue("outsideTemperature", extendedValues["dry_bulb_temp_celsius"]);
        setValue("wetBulbTemperature", extendedValues["wet_bulb_temp_celsius"]);
        trigger(["outsideTemperature", "wetBulbTemperature"]);

        setValue("groundTemperature", extendedValues.ground_temp_celsius);
      }
    }
  }, [locationSelected, setValue, locationOptions, trigger]);

  useEffect(() => {
    // Recalculate relative humidity on user inputs
    const outsideTemperature = getValues("outsideTemperature");
    const wetBulbTemperature = getValues("wetBulbTemperature");

    if (
      formState.isValidating &&
      !isNaN(outsideTemperature) &&
      !isNaN(wetBulbTemperature)
    ) {
      setValue(
        "outsideHumidity",
        calcRelativeHumidity(
          Number(outsideTemperature),
          Number(wetBulbTemperature)
        )
      );
      trigger("outsideHumidity");
    }
  }, [
    outsideTemperature,
    wetBulbTemperature,
    formState.isValidating,
    getValues,
    setValue,
    trigger,
  ]);

  const temperatureRules: RegisterOptions = {
    required: "Required",
    min: {
      value: -40,
      message: "Must be between -40\u00b0 and +30\u00b0 Celsius",
    },
    max: {
      value: 30,
      message: "Must be between -40\u00b0 and +30\u00b0 Celsius",
    },
    valueAsNumber: true,
    validate: {
      setFreezerDefault: (v) => {
        if (v < 0) {
          setValue("humidity", 0, {
            shouldDirty: true,
          });
        }
        return true;
      },
      validRoomTemp: (value) => {
        const matches = value.toString().match(/^-?\d*\.?\d*$/);
        return matches?.length > 0 || "Must be a number";
      },
    },
  };

  return (
    <div data-testid="room-details">
      <div className={heatLoadStyles.narrowFormContainer}>
        <div>
          <p className="section-description">
            Provide the location of the room to determine site specific details.
          </p>
        </div>

        <div>
          <SearchGroupSelect
            name="locationSelected"
            labelText="Room location"
            options={locationGroupedOptions}
            hasErrors={errors.locationSelected?.message}
            errorMessage={errors.locationSelected?.message}
            control={control}
            rules={{ required: "Required" }}
          />

          <FormRow>
            <Input
              {...register("outsideTemperature", outsideTemperatureRules)}
              labelText="Dry bulb temp."
              placeholderText="-40° to +45°"
              innerLabel="°C"
              hasErrors={errors.outsideTemperature}
              errorMessage={errors.outsideTemperature?.message}
              fieldSize="medium"
            />

            <Input
              {...register("wetBulbTemperature", outsideTemperatureRules)}
              labelText="Wet bulb temp."
              placeholderText="-40° to +45°"
              innerLabel="°C"
              hasErrors={errors.wetBulbTemperature}
              errorMessage={errors.wetBulbTemperature?.message}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("outsideHumidity", outsideHumidityRules)}
              labelText="Outside humidity"
              placeholderText=""
              innerLabel="%"
              disabled={true}
              hasErrors={errors.outsideHumidity}
              errorMessage={errors.outsideHumidity?.message}
              fieldSize="medium"
            />

            <Input
              {...register("groundTemperature")}
              labelText="Ground temp."
              placeholderText=""
              innerLabel="°C"
              disabled={true}
              hasErrors={errors.groundTemperature}
              errorMessage={errors.groundTemperature?.message}
              fieldSize="medium"
            />
          </FormRow>
        </div>
      </div>
      <div className={heatLoadStyles.narrowFormContainer}>
        <div>
          <div className="section-title">Room details</div>
          <p className="section-description">
            Provide details of temperature and dimensions of the room.
          </p>
        </div>

        <div>
          <FormRow>
            <Input
              {...register("temperature", temperatureRules)}
              labelText="Desired room temp."
              placeholderText="-40° to +30°"
              innerLabel="°C"
              hasErrors={errors.temperature}
              errorMessage={errors.temperature?.message}
              fieldSize="medium"
            />

            <Input
              {...register("humidity", roomHumidityRules)}
              labelText="Desired room humidity"
              placeholderText="0% to 100%"
              innerLabel="%"
              hasErrors={errors.humidity}
              errorMessage={errors.humidity?.message}
              fieldSize="medium"
            />
          </FormRow>

          <FormRow>
            <Input
              {...register("length", roomLengthRules)}
              labelText="Length"
              placeholderText="100 to 150000"
              innerLabel="mm"
              hasErrors={errors.length}
              errorMessage={errors.length?.message}
              fieldSize="medium"
            />

            <Input
              {...register("width", roomLengthRules)}
              labelText="Width"
              placeholderText="100 to 150000"
              innerLabel="mm"
              hasErrors={errors.width}
              errorMessage={errors.width?.message}
              fieldSize="medium"
            />
          </FormRow>

          <Input
            {...register("height", roomLengthRules)}
            labelText="Height"
            placeholderText="100 to 150000"
            innerLabel="mm"
            hasErrors={errors.height}
            errorMessage={errors.height?.message}
            fieldSize="medium"
          />
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
