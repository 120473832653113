import React, { FC, RefObject } from "react";
import styles from "./RoomPreview.module.css";
import { RoomDimensions } from "../../types/Room";
import InfoText from "../../../components/InfoText/InfoText";

const RoomPreview: FC<{
  roomDimensions: RoomDimensions;
  canvasRef: RefObject<HTMLCanvasElement>;
}> = ({ roomDimensions, canvasRef }) => {
  let roomFloorArea = roomDimensions.floor.surfaceArea / (1000 * 1000);

  return (
    <div data-testid="room-preview">
      {roomFloorArea > 0 && (
        <div className={styles.roomPreviewContainer}>
          <div className={styles.canvasContainer}>
            <canvas id="canvas" ref={canvasRef} width="340" height="200" />
          </div>
          <div className={styles.totalsContainer}>
            <div>Room volume</div>
            <div>
              {roomDimensions.volume?.toFixed(1)} {"\u33A5"}
            </div>
            <div>Room floor area</div>
            <div>
              {roomFloorArea.toFixed(1)} {"\u33A1"}
            </div>
          </div>
        </div>
      )}
      {roomFloorArea === 0 && (
        <InfoText testid="no-room-preview">
          Your preview will appear here as you add room details.
        </InfoText>
      )}
    </div>
  );
};

export default RoomPreview;
