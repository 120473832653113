import React, { FC, useState } from "react";
import Section from "../Section/Section";
import Image from "../Image/Image";
import styles from "./AccordionSection.module.css";
import cx from "classnames";
export interface AccordionSectionProps {
  isOpen?: boolean;
  title: string;
}

const AccordionSection: FC<AccordionSectionProps> = ({
  isOpen = false,
  title,
  children,
}) => {
  const [openSection, setOpen] = useState<boolean>(isOpen ?? false);

  const onClick = () => {
    setOpen(!openSection);
  };

  return (
    <Section>
      <button
        onClick={onClick}
        className={styles.accordionContainer}
        data-testid={title}
      >
        <div className={styles.accordionTitle}>
          {title}
          <div style={{ float: "right" }}>
            <Image
              filename={
                openSection ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }
              altText={
                openSection
                  ? `Close the ${title} section`
                  : `Open the ${title} section`
              }
            />
          </div>
        </div>
      </button>
      <div className={cx(!openSection && styles.hideSection)}>{children}</div>
    </Section>
  );
};

export default AccordionSection;
