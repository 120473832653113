import React from "react";
import { A } from "hookrouter";
import styles from "./Header.module.css";
import cx from "classnames";
import logo from "../../assets/actrol.svg";
import { logoutRedirect } from "../../authentication/CognitoTokenService";

declare const window: any;
const ENVIRONMENT = `${window.__runtime_configuration.ENVIRONMENT}`;

const goToLogout = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  // redirect to logout
  let logoutUrl = logoutRedirect();
  window.location.assign(logoutUrl);
};

const Header = () => (
  <header
    className={cx(
      styles.header,
      ENVIRONMENT === "TEST" && styles.testHeader,
      ENVIRONMENT === "STAGING" && styles.stagingHeader
    )}
    data-testid="header"
  >
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <A href="/">
          <img src={logo} alt="Actrol logo" />
        </A>
      </div>
      <div>
        <h6 className={styles.heading}>Virtual Engineer</h6>
        <span className="small-label-text">Australia</span>
      </div>
      <div className={styles.userMenu}>
        <a href="#" onClick={(e) => goToLogout(e)}>
          Sign out
        </a>
      </div>
    </div>
  </header>
);

export default Header;
