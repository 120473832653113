import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import actionSelector from "../../duck/selectors";
import gridStyles from "../../../css/GridForm.module.css";
import styles from "./CapitalEquipmentFilter.module.css";
import HeatLoadSection from "./FormSections/HeatLoadSection";
import calculateSst from "../../../calculations/sst/calculateSst";
import DesignDetailsSection from "./FormSections/DesignDetailsSection";
import ClimateDetailsSection from "./FormSections/ClimateDetailsSection";
import { CapitalEquipmentFilterModel } from "../../../types/CapitalEquipmentFilterModel";
import Button from "../../../components/Button/Button";
import { SelectOption } from "../../../types/SelectOption";
import { logPageEvent } from "../../../helpers/amplitude";

interface EquipmentSelectionForm {
  load: string;
  refrigerant: string;
  runtime: string;
  ambient: string;
  roomTemp: string;
  td: string;
  humidity: string;
  noOfCondensingUnits: string;
  noOfEvaporatorUnits: string;
  mid_or_dew: string;
  standardsRating: string;
  compressorInletSuperheat: string;
  liquidSubcooling: string;
}

const CapitalEquipmentFilter: React.FC<{
  scrollToEquipmentSelection: () => void;
}> = ({ scrollToEquipmentSelection }) => {
  let dispatch = useDispatch();

  const validSst = (sst: number) => {
    return sst > -50 && sst < 40;
  };

  const defaultValues: EquipmentSelectionForm = {
    load: "3600",
    refrigerant: "R404A",
    runtime: "16",
    ambient: "32",
    roomTemp: "2",
    td: "6",
    humidity: "80",
    noOfCondensingUnits: "1",
    noOfEvaporatorUnits: "1",
    mid_or_dew: "mid",
    standardsRating: "EN12900",
    compressorInletSuperheat: "15",
    liquidSubcooling: "2",
  };

  const submit = (values: EquipmentSelectionForm) => {
    let sst = calculateSst(+values.roomTemp, +values.td);
    if (validSst(sst)) {
      let submitValues: CapitalEquipmentFilterModel = {
        refrigerant: values.refrigerant,
        load: +values.load,
        runtime: +values.runtime,
        ambient: +values.ambient,
        td: +values.td,
        roomTemp: +values.roomTemp,
        noOfCondensingUnits: +values.noOfCondensingUnits,
        noOfEvaporatorUnits: +values.noOfEvaporatorUnits,
        mid_or_dew: values.mid_or_dew,
        standardsRating: values.standardsRating,
        compressorInletSuperheat: +values.compressorInletSuperheat,
        liquidSubcooling: +values.liquidSubcooling,
      };
      dispatch(actionSelector.loadingBalancePerformance(true));
      dispatch(actionSelector.searchCondensingUnits(submitValues));
      scrollToEquipmentSelection();
    } else {
      console.log(`Invalid calculated Sst value: ${sst}`);
    }
  };

  const methods = useForm<EquipmentSelectionForm>({
    defaultValues,
    mode: "onBlur",
  });

  const { setValue, handleSubmit } = methods;

  const refrigerantOptions: SelectOption[] = useSelector(
    (state: any) => state.product.equipmentRefrigerantOptions
  );

  useEffect(() => {
    setValue("refrigerant", "R404A");
  }, [refrigerantOptions, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className={styles.formContainer}>
        <div className={gridStyles.gridContainer}>
          <div>
            <div className="section-title">Refrigeration heat load details</div>
            <p className="section-description">
              Provide heat load, refrigerant & run time details.
            </p>
            <HeatLoadSection refrigerantOptions={refrigerantOptions} />
          </div>
          <div>
            <div className="section-title">Temperature details</div>
            <p className="section-description">
              Provide design temperature details.
            </p>
            <DesignDetailsSection />
          </div>
          <div>
            <div className="section-title">Ambient conditions</div>
            <p className="section-description">
              Provide ambient temperature the condensing unit is exposed to.
            </p>
            <ClimateDetailsSection />
          </div>
        </div>
        <div>
          <Button
            buttonStyle="primary"
            buttonType="submit"
            dataTestId="display_equipment"
            onClickHandler={(e) =>
              logPageEvent("Display equipment - Equipment Selection")
            }
          >
            Display equipment
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CapitalEquipmentFilter;
