import { FieldError, RegisterOptions } from "react-hook-form";

interface DoorDetailsErrors {
  doors?:
    | {
        doorId?: FieldError | undefined;
        type?: FieldError | undefined;
        material?: FieldError | undefined;
        glazing?: FieldError | undefined;
        usage?: FieldError | undefined;
        height?: FieldError | undefined;
        width?: FieldError | undefined;
        location?: FieldError | undefined;
        numberOfDoors?: FieldError | undefined;
        heatedFascia?: FieldError | undefined;
        customHeatedFascia?: FieldError | undefined;
        heatedGlass?: FieldError | undefined;
        customHeatedGlass?: FieldError | undefined;
      }[]
    | undefined;
}

interface DoorDetailsDirtyFields {
  doors?:
    | {
        doorId?: boolean | undefined;
        type?: boolean | undefined;
        material?: boolean | undefined;
        glazing?: boolean | undefined;
        usage?: boolean | undefined;
        height?: boolean | undefined;
        width?: boolean | undefined;
        location?: boolean | undefined;
        numberOfDoors?: boolean | undefined;
        heatedFascia?: boolean | undefined;
        customHeatedFascia?: boolean | undefined;
        heatedGlass?: boolean | undefined;
        customHeatedGlass?: boolean | undefined;
      }[]
    | undefined;
}

const doorMeasurementRules = (maxWidth: number): RegisterOptions => {
  return {
    required: "Required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Must be a number",
    },
    min: {
      value: 0,
      message: `Must be between 0mm and ${maxWidth}mm`,
    },
    max: {
      value: maxWidth,
      message: `Must be between 0mm and ${maxWidth}mm`,
    },
    valueAsNumber: true,
    validate: {
      validDoorWidthRules: (value) => {
        const matches = value.toString().match(/^\d*\.?\d*$/);
        return matches?.length > 0 || "Must be a number";
      },
    },
  };
};

const heatedFasciaAndGlassRules = (uom: string): RegisterOptions => {
  return {
    required: "Required",
    min: {
      value: 1,
      message: `Must be between 1${uom} and 500${uom}`,
    },
    max: {
      value: 500,
      message: `Must be between 1${uom} and 500${uom}`,
    },
    valueAsNumber: true,
    validate: {
      validFasciaRule: (value) => {
        const matches = value.toString().match(/^\d*\.?\d*$/);
        return matches?.length > 0 || "Must be a number";
      },
    },
  };
};

const isErrorDoors = (errors: DoorDetailsErrors, index: number): boolean => {
  let doorsError = errors.doors;
  return doorsError !== undefined && doorsError[index] !== undefined;
};

const allDoorFieldsDirty = (
  dirtyFields: DoorDetailsDirtyFields,
  index: number
): boolean => {
  let doorDirty = dirtyFields.doors;

  let doorDirtyFlag =
    doorDirty &&
    doorDirty[index] !== undefined &&
    (doorDirty[index]?.material !== undefined ||
      doorDirty[index]?.glazing !== undefined) &&
    doorDirty?.[index]?.usage !== undefined &&
    doorDirty?.[index]?.height !== undefined &&
    doorDirty?.[index]?.width !== undefined &&
    doorDirty?.[index]?.location !== undefined &&
    doorDirty?.[index]?.heatedFascia !== undefined;

  return doorDirtyFlag ?? false;
};

const heatedFasciaDirty = (
  dirtyFields: DoorDetailsDirtyFields,
  index: number
): boolean => {
  let doorDirty = dirtyFields.doors;

  let doorDirtyFlag =
    doorDirty !== undefined &&
    doorDirty[index] !== undefined &&
    doorDirty?.[index]?.heatedFascia !== undefined;

  return doorDirtyFlag ?? false;
};

const heatedGlassDoorDirty = (
  dirtyFields: DoorDetailsDirtyFields,
  index: number
): boolean => {
  let doorDirty = dirtyFields.doors;

  let doorDirtyFlag =
    doorDirty &&
    doorDirty[index] !== undefined &&
    doorDirty?.[index]?.heatedGlass !== undefined;

  return doorDirtyFlag ?? false;
};

const validateDoors = (
  errors: DoorDetailsErrors,
  dirtyFields: DoorDetailsDirtyFields,
  i: number
): boolean => {
  return !isErrorDoors(errors, i) && allDoorFieldsDirty(dirtyFields, i);
};

export {
  validateDoors,
  isErrorDoors,
  doorMeasurementRules,
  heatedFasciaAndGlassRules,
  heatedFasciaDirty,
  heatedGlassDoorDirty,
};
