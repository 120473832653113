import React, { FC } from "react";
import styles from "./CalculationSummary.module.css";
import CircleChart from "../CircleChart/CircleChart";

import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";
import InfoText from "../../../components/InfoText/InfoText";
import Image from "../../../components/Image/Image";

const CalculationSummary: FC<{ heatLoadSummary: HeatLoadTotalCapacity }> = ({
  heatLoadSummary,
}) => {
  let totalHeatLoad =
    heatLoadSummary.totalLoad.sensible + heatLoadSummary.totalLoad.latent;

  return (
    <div data-testid="calculation-summary">
      {totalHeatLoad > 0 && (
        <div className={styles.calcSummaryContainer}>
          <div className={styles.donutContainer}>
            <CircleChart totalLoadCapacity={heatLoadSummary}>
              <div
                className={styles.donutTotalsValue}
                data-testid="pieWheelTotalCapacity"
              >
                {heatLoadSummary.fanLoad.total.toFixed(0)} W
              </div>
              <div className={styles.donutTotalsLabel}>
                Required equipment capacity
              </div>
              <div className={styles.runtimeContainer}>
                <Image filename="update_timer_black" altText="Runtime" />
                <div
                  className={styles.runtimeText}
                  data-testid="pieWheelTotalRuntime"
                >
                  {heatLoadSummary.runTimeTotal.runtime} hr runtime
                </div>
              </div>
            </CircleChart>
          </div>
          <div>
            <div className={styles.loadTypeContainer}>
              <div className={`${styles.loadTypeHeading} ${styles.alignLeft}`}>
                Load type
              </div>
              <div className={styles.loadTypeHeading}>Sensible</div>
              <div className={styles.loadTypeHeading}>Latent</div>
              <div className={styles.alignLeft}>
                <span className={`${styles.circle} ${styles.orangeCircle}`}>
                  &nbsp;
                </span>
                Transmission
              </div>
              <div>
                {heatLoadSummary.transmissionLoad.sensible.toFixed(0)} W
              </div>
              <div>{heatLoadSummary.transmissionLoad.latent.toFixed(0)} W</div>
              <div className={styles.alignLeft}>
                <span className={`${styles.circle} ${styles.greenCircle}`}>
                  &nbsp;
                </span>
                Infiltration
              </div>
              <div>
                {heatLoadSummary.infiltrationLoad.sensible.toFixed(0)} W
              </div>
              <div>{heatLoadSummary.infiltrationLoad.latent.toFixed(0)} W</div>
              <div className={styles.alignLeft}>
                <span className={`${styles.circle} ${styles.purpleCircle}`}>
                  &nbsp;
                </span>
                Product
              </div>
              <div>{heatLoadSummary.productLoad.sensible.toFixed(0)} W</div>
              <div>{heatLoadSummary.productLoad.latent.toFixed(0)} W</div>
              <div className={styles.alignLeft}>
                <span className={`${styles.circle} ${styles.blueCircle}`}>
                  &nbsp;
                </span>
                Additional loads
              </div>
              <div>{heatLoadSummary.internalLoad.sensible.toFixed(0)} W</div>
              <div>{heatLoadSummary.internalLoad.latent.toFixed(0)} W</div>
              <div
                className={`${styles.loadTypeTotals} ${styles.spanTwoColumns}`}
              >
                Subtotal loads
              </div>
              <div
                data-testid="totalHeatLoad"
                className={styles.loadTypeTotals}
              >
                {totalHeatLoad.toFixed(0)} W
              </div>
            </div>
            <div className={styles.totalsContainer}>
              <div className={styles.totalsLabel}>Other loads</div>
              <div data-testid="otherHeatLoad" className={styles.totalsValue}>
                {heatLoadSummary.otherLoad.total.toFixed(0)} W
              </div>
              <div className={styles.totalsLabel}>
                <span className={`${styles.circle} ${styles.yellowCircle}`}>
                  &nbsp;
                </span>
                Safety ({heatLoadSummary.safetyLoad.safetyFactor}%)
              </div>
              <div data-testid="safetyHeatLoad" className={styles.totalsValue}>
                {heatLoadSummary.safetyLoad.total.toFixed(0)} W
              </div>
              <div className={styles.totalsLabel}>
                <span className={`${styles.circle} ${styles.redCircle}`}>
                  &nbsp;
                </span>
                Fan load ({heatLoadSummary.fanLoad.fanFactor}%)
              </div>
              <div data-testid="fanLoad" className={styles.totalsValue}>
                {heatLoadSummary.fanLoad.fanLoad.toFixed(0)} W
              </div>
              <div className={`${styles.totalsLabel} ${styles.finalTotals}`}>
                Runtime total ({heatLoadSummary.runTimeTotal.runtime} hours)
              </div>
              <div
                data-testid="runtimeTotal"
                className={`${styles.totalsValue} ${styles.finalTotals}`}
              >
                {heatLoadSummary.runTimeTotal.total.toFixed(0)} W
              </div>
            </div>
            <div className={styles.finalTotalContainer}>
              <div className={`${styles.totalsLabel} ${styles.finalTotals}`}>
                Required equipment capacity
              </div>
              <div
                data-testid="totalWithFanLoad"
                className={`${styles.totalsValue} ${styles.finalTotalValue}`}
              >
                {heatLoadSummary.fanLoad.total.toFixed(0)} W
              </div>
            </div>
          </div>
        </div>
      )}
      {totalHeatLoad === 0 && (
        <InfoText testid="no-heat-load-summary">
          Your heat load summary will appear here as you add room details.
        </InfoText>
      )}
    </div>
  );
};

export default CalculationSummary;
