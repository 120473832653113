import React, { forwardRef } from "react";
import styles from "./Select.module.css";
import { SelectOption } from "../../types/SelectOption";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import cx from "classnames";
import { FieldSize } from "../../types/FieldSize";
import { UseFormRegister } from "react-hook-form";

interface SelectProps {
  labelText: string;
  options: SelectOption[];
  hasErrors: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hideDefaultOption?: boolean;
  defaultValue?: string;
  fieldSize?: FieldSize;
}

const generateSelectCss = (fieldSize: FieldSize) => {
  return cx(
    styles.selectContainer,
    fieldSize === "x-small" && styles.selectXSmall,
    fieldSize === "small" && styles.selectSmall,
    fieldSize === "medium" && styles.selectMedium,
    fieldSize === "large" && styles.selectLarge,
    fieldSize === "x-large" && styles.selectXLarge
  );
};

const Select = forwardRef<
  HTMLSelectElement,
  SelectProps & ReturnType<UseFormRegister<any>>
>(
  (
    {
      name,
      labelText,
      options,
      hasErrors,
      errorMessage = "",
      onChange,
      hideDefaultOption = false,
      defaultValue = "",
      fieldSize = "x-large",
      onBlur,
    },
    ref
  ) => (
    <div className={generateSelectCss(fieldSize)}>
      <label htmlFor={name} className={hasErrors ? styles.errorTextState : ""}>
        {labelText}
      </label>
      <select
        id={name}
        name={name}
        className={cx(styles.selectStyle, hasErrors && styles.errorState)}
        data-testid={name}
        ref={ref}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
      >
        {!hideDefaultOption && (
          <option value="" data-testid="select-option">
            Select
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((opt) => {
            return (
              <option value={opt.key} key={opt.key} data-testid="select-option">
                {opt.value}
              </option>
            );
          })}
      </select>
      {hasErrors && <ErrorMessage errorText={errorMessage} />}
    </div>
  )
);

export default Select;
