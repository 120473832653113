import React, { Fragment } from "react";
import styles from "./ProductCard.module.css";
import Thumbnail from "../Thumbnail/Thumbnail";

export interface ProductDescriptionValues {
  label: string;
  value: string | React.ReactNode;
}

interface Props {
  title: string;
  productDescriptionValues: ProductDescriptionValues[];
  thumbnail?: string | null;
  additionalInfo?: string | React.ReactNode;
}

const ProductCard: React.FC<Props> = ({
  title,
  productDescriptionValues,
  thumbnail,
  additionalInfo,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.grid}>
        <div className={styles.thumbnailContainer}>
          <Thumbnail thumbnailUrl={thumbnail} />
        </div>
        <div>
          {productDescriptionValues.map((productDesc, i) => {
            return (
              <Fragment key={i}>
                {productDesc.value && (
                  <div className={styles.productDescription}>
                    {productDesc.label}: <b>{productDesc.value}</b>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      <div>{additionalInfo}</div>
    </div>
  );
};

export default ProductCard;
