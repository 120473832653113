import React, { forwardRef } from "react";
import cx from "classnames";
import styles from "./Input.module.css";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { UseFormRegister } from "react-hook-form";
import { FieldSize } from "../../types/FieldSize";

interface InputProps {
  labelText: string;
  placeholderText?: string;
  innerLabel?: string;
  helpText?: string;
  hasErrors: boolean;
  errorMessage?: string;
  disabled?: boolean;
  defaultValue?: string;
  fieldSize?: FieldSize;
}

const generateInputCss = (fieldSize: FieldSize) => {
  return cx(
    styles.inputDisplay,
    fieldSize === "x-small" && styles.inputXSmall,
    fieldSize === "small" && styles.inputSmall,
    fieldSize === "medium" && styles.inputMedium,
    fieldSize === "large" && styles.inputLarge,
    fieldSize === "x-large" && styles.inputXLarge
  );
};

const Input = forwardRef<
  HTMLInputElement,
  InputProps & ReturnType<UseFormRegister<any>>
>(
  (
    {
      name,
      labelText,
      placeholderText = "",
      innerLabel = "",
      helpText = "",
      hasErrors,
      errorMessage = "",
      disabled = false,
      defaultValue = "",
      fieldSize = "x-large",
      onChange,
      onBlur,
    },
    ref
  ) => {
    return (
      <div className={generateInputCss(fieldSize)}>
        <label
          htmlFor={name}
          className={cx(
            hasErrors && styles.errorTextState,
            disabled && styles.disabledState
          )}
        >
          {labelText}
        </label>
        <div
          className={cx(
            innerLabel !== "" && styles.inputGroup,
            innerLabel === "" && styles.basicInput,
            hasErrors && styles.errorInputState
          )}
        >
          <div className={innerLabel !== "" ? styles.inputGroupArea : ""}>
            <input
              id={name}
              name={name}
              type="text"
              aria-invalid={hasErrors ? "true" : "false"}
              placeholder={placeholderText}
              className={cx(
                styles.inputStyle,
                hasErrors && styles.errorTextState
              )}
              data-lpignore="true"
              autoComplete="off"
              data-testid={name}
              ref={ref}
              disabled={disabled}
              defaultValue={defaultValue}
              data-hj-allow
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          {innerLabel !== "" && (
            <div className={styles.inputGroupLabel}>{innerLabel}</div>
          )}
        </div>
        {hasErrors && <ErrorMessage errorText={errorMessage} />}
        {helpText !== "" && !hasErrors && (
          <label className={styles.helpText}>{helpText}</label>
        )}
      </div>
    );
  }
);

export default Input;
