import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import {
  wallThicknessRules,
  surfaceTempRules,
} from "../SurfaceDetailsValidation";
import { ProductFilter } from "../../../../types/ProductFilter";
import FormRow from "../../../../components/FormRow/FormRow";

const CeilingDetails: FC<{ ceilingInsulationFilter: ProductFilter }> = ({
  ceilingInsulationFilter,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const ceilingInsulationOptions = ceilingInsulationFilter.options;

  return (
    <div
      data-testid="ceiling-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      <div>
        <label className="labelBoldText">Ceiling</label>

        <Select
          {...register("ceiling.material", { required: "Required" })}
          labelText="Material"
          options={ceilingInsulationOptions}
          hasErrors={errors.ceiling?.material}
          errorMessage={errors.ceiling?.material?.message}
        />

        <FormRow>
          <Input
            {...register("ceiling.thickness", wallThicknessRules)}
            labelText="Thickness"
            placeholderText="30 to 250"
            innerLabel="mm"
            hasErrors={errors.ceiling?.thickness}
            errorMessage={errors.ceiling?.thickness?.message}
            fieldSize="medium"
          />

          <Input
            {...register("ceiling.ambientTemp", surfaceTempRules)}
            labelText="Ambient temp."
            placeholderText="-40° to +45°"
            innerLabel="°C"
            hasErrors={errors.ceiling?.ambientTemp}
            errorMessage={errors.ceiling?.ambientTemp?.message}
            fieldSize="medium"
          />
        </FormRow>
      </div>
    </div>
  );
};

export default CeilingDetails;
