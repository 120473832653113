import React from "react";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import styles from "./SelectedEquipment.module.css";
import isAnEmptyObject from "../../../helpers/emptyObject";
import SelectedProductPlaceholder from "../../../components/SelectedProductPlaceholder/SelectedProductPlaceholder";
import EvaporatorCard from "../EvaporatorCard/EvaporatorCard";
import CondensingUnitCard from "../CondensingUnitCard/CondensingUnitCard";

type Props = Ready | Loading;

interface Ready {
  noOfCondensingUnits: number;
  noOfEvaporatorUnits: number;
  condensingUnit: CondensingUnitProductCapacity;
  evaporatorUnit: EvaporatorProductCapacity;
  refrigerant: string;
  loading?: false;
}

interface Loading {
  noOfCondensingUnits?: number;
  noOfEvaporatorUnits?: number;
  condensingUnit?: CondensingUnitProductCapacity;
  evaporatorUnit?: EvaporatorProductCapacity;
  refrigerant?: string;
  loading?: true;
}

const SelectedEquipment: React.FC<Props> = ({
  noOfCondensingUnits,
  noOfEvaporatorUnits,
  condensingUnit,
  evaporatorUnit,
  refrigerant,
  loading,
}) => {
  const SelectedEquipmentCards = () => (
    <>
      {!isAnEmptyObject(condensingUnit) && (
        <>
          <p className={styles.productHeading}>
            Number of condensing units: <b>{noOfCondensingUnits}</b>
          </p>
          <CondensingUnitCard
            condensingUnit={condensingUnit!}
            refrigerant={refrigerant!}
          />
        </>
      )}
      {!isAnEmptyObject(evaporatorUnit) && (
        <>
          <p className={styles.productHeading}>
            Number of evaporators: <b>{noOfEvaporatorUnits}</b>
          </p>
          <EvaporatorCard
            evaporator={evaporatorUnit!}
            refrigerant={refrigerant!}
          />
        </>
      )}
    </>
  );

  return (
    <section className={styles.container}>
      <div className="section-header">Selected equipment</div>
      <p className={styles.subheading}>
        List of equipment selected to calculate the balanced performance
      </p>
      {loading ? (
        <SelectedProductPlaceholder
          heading="No equipment selected"
          subheading="Add details of equipments to select equipment"
        />
      ) : (
        <SelectedEquipmentCards />
      )}
    </section>
  );
};

export default SelectedEquipment;
