import React, { FC, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import NavigationBar from "../NavigationBar/NavigationBar";
import styles from "./Layout.module.css";
import ErrorBoundary from "../../ErrorBoundary";
import { logPageVisit, registerAmplitudeAPI } from "../../helpers/amplitude";

const Layout: FC = ({ children }) => {
  const [showNavBar, setShowNavBar] = useState(true);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setShowNavBar(false);
    }

    registerAmplitudeAPI();
    logPageVisit();
  }, []);

  return (
    <div className={styles.appContainer}>
      <div className={styles.applicationHeader}>
        <Header />
      </div>
      <div className={styles.appNavigationBar}>
        {showNavBar && <NavigationBar linkName="Back" linkURL="/dashboard" />}
      </div>

      <div className={styles.applicationBody}>
        <div className={`${styles.contentContainer}`}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Layout;
