import React, { useEffect, useState } from "react";
import CalculationSummary from "./CalculationSummary";
import { useSelector } from "react-redux";
import { HeatLoadTotalCapacity } from "../../types/HeatLoadCapacity";

export const SummaryViewContainer: React.FC<{}> = () => {
  const [heatLoadSummaryView, setHeatLoadSummaryView] = useState({
    productLoad: { sensible: 0, latent: 0 },
    transmissionLoad: { sensible: 0, latent: 0 },
    infiltrationLoad: { sensible: 0, latent: 0 },
    internalLoad: { sensible: 0, latent: 0 },
    totalLoad: { sensible: 0, latent: 0 },
    otherLoad: { total: 0 },
    safetyLoad: { safetyFactor: 0, total: 0 },
    fanLoad: { fanFactor: 0, fanLoad: 0, total: 0 },
    runTimeTotal: { runtime: 0, total: 0 },
  });

  const heatLoadCapacity: HeatLoadTotalCapacity = useSelector(
    (state: any) => state.heatLoad.heatLoadCapacity
  );

  useEffect(() => {
    setHeatLoadSummaryView(heatLoadCapacity);
  }, [heatLoadCapacity]);

  return (
    <>
      <CalculationSummary heatLoadSummary={heatLoadSummaryView} />
    </>
  );
};
