import React from "react";
import { A } from "hookrouter";
import styles from "./DashboardCard.module.css";

export interface DashboardCardProps {
  title: string;
  link?: string;
  disabled?: boolean;
  middleLabelText?: string;
  imageFilename: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  link = "/",
  disabled = false,
  middleLabelText = "",
  imageFilename,
}) => {
  const thumbnailImg = require(`../../assets/${imageFilename}`);

  return (
    <div className={styles.container}>
      <A
        href={link}
        className={`${styles.cardContainer} 
                    ${disabled && styles.disabledCard}`}
      >
        <div className={styles.cardThumbnailContainer}>
          <img src={thumbnailImg} alt={title} className={styles.imageLayout} />
        </div>
        <div className={styles.cardTextContainer}>{title}</div>
      </A>
      {middleLabelText !== "" && (
        <div
          className={`${styles.innerPillContainer}
        ${disabled && styles.disabledPill} ${!disabled && styles.enabledPill}`}
        >
          {middleLabelText}
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
