import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import { ProductFilter } from "../../../../types/ProductFilter";
import { numberOfHoursRules } from "./PeopleDetailsValidation";
import heatLoadStyles from "../../../page/HeatLoad.module.css";
import FormRow from "../../../../components/FormRow/FormRow";

const PeopleDetails: FC<{
  peopleActivityFilter: ProductFilter;
  ventilationFilter: ProductFilter;
}> = ({ peopleActivityFilter, ventilationFilter }) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const activityOptions = peopleActivityFilter.options;
  const ventilationOptions = ventilationFilter.options;

  const noOfPeopleRules: RegisterOptions = {
    required: "Required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Must be a number",
    },
    min: {
      value: 0,
      message: "Must be between 0 and 100",
    },
    max: {
      value: 100,
      message: "Must be between 0 and 100",
    },
    valueAsNumber: true,
    validate: {
      validateVentilation: (v) => {
        const ventilation = getValues("ventilation");
        return (
          ventilation === "none" ||
          ventilation === "standard" ||
          v > 0 ||
          "Must be > zero for selected ventilation"
        );
      },
    },
  };

  const ventilationRules: RegisterOptions = {
    validate: {
      validateNoOfPeople: (v) => {
        const numberOfPeople = getValues("numberOfPeople");
        const activity = getValues("activity");
        const numberOfHours = getValues("numberOfHours");
        return (
          !v ||
          v === "none" ||
          v === "standard" ||
          (numberOfPeople > 0 &&
            numberOfHours > 0 &&
            activity !== undefined &&
            activity.length > 0) ||
          "People details must be entered"
        );
      },
    },
  };

  return (
    <div
      data-testid="ventilation-people-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      <div>
        <FormRow>
          <Input
            {...register("numberOfPeople", noOfPeopleRules)}
            labelText="People"
            placeholderText="99"
            hasErrors={errors.numberOfPeople}
            errorMessage={errors.numberOfPeople?.message}
            fieldSize="x-small"
          />

          <Select
            {...register("activity", {
              required: "Required",
            })}
            labelText="Level of activity"
            options={activityOptions}
            hasErrors={errors.activity}
            errorMessage={errors.activity?.message}
            fieldSize="small"
          />

          <Input
            {...register("numberOfHours", numberOfHoursRules)}
            labelText="Activity per day"
            placeholderText="0 to 24"
            innerLabel="hrs"
            hasErrors={errors.numberOfHours}
            errorMessage={errors.numberOfHours?.message}
            fieldSize="small"
          />
        </FormRow>

        <Select
          {...register("ventilation", ventilationRules)}
          labelText="Ventilation"
          options={ventilationOptions}
          hasErrors={errors.ventilation}
          errorMessage={errors.ventilation?.message}
          hideDefaultOption
          fieldSize="medium"
        />
      </div>
    </div>
  );
};

export default PeopleDetails;
