import React, { useEffect, useState } from "react";
import { SimpleCoolStorage } from "../../../calculations/heatload";
import AirCondition from "../../../calculations/heatload/AirCondition";
import {
  HeatLoadSections,
  TotalHeatLoad,
} from "../../../calculations/heatload/load/TotalHeatLoad";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../duck/selectors";
import { StandardRoom } from "../../../types/HeatLoad";
import {
  CeilingDetails,
  DoorDetails,
  FloorDetails,
  ForkliftDetails,
  LightingDetails,
  PeopleDetails,
  VentilationDetails,
  WallDetails,
  WallDimensions,
} from "../../types/Room";
import { ProductLine } from "../../types/products";
import { RefrigerationProduceList } from "../../../calculations/heatload/product/Product";
import { DoorModel } from "../../../calculations/heatload/Door";
import styles from "./HeatLoadCalculationContainer.module.css";
import { SafetyAndRuntime } from "../../types/SafetyAndRuntime";

// REGRESSION TEST TEST COMMENT 24

const heatLoadLogs = (
  totalHeatLoad: TotalHeatLoad,
  produceList: RefrigerationProduceList
) => {
  return (
    <>
      <div>
        <div className="section-title">Heat Loads (***Test Only)</div>
      </div>
      {totalHeatLoad.heatLoads.map((v) => {
        return (
          <div className={styles.cals} key={v.heatLoadType + v.reference}>
            <div>{v.heatLoadType}</div>
            <div>Reference: {v.reference}</div>
            <div>Latent: {v.latent}</div>
            <div>Sensible: {v.sensible}</div>
            <div>Total: {v.latent + v.sensible}</div>
          </div>
        );
      })}
      <div>
        <br />
        <div className="section-title">Product Heat Loads (***Test Only)</div>
      </div>
      {produceList.totalLoadDetails().map((v) => {
        return (
          <div className={styles.cals} key={v.heatLoadType + v.reference}>
            <div>{v.heatLoadType}</div>
            <div>Reference: {v.reference}</div>
            <div>AboveFreeze: {v.heatAboveFreeze}</div>
            <div>Latent: {v.latentHeat}</div>
            <div>BelowFreeze: {v.heatBelowFreeze}</div>
          </div>
        );
      })}
    </>
  );
};

const HeatLoadCalculationContainer: React.FC<{
  enableHeatLoadLogs: boolean;
}> = ({ enableHeatLoadLogs = false }) => {
  const dispatch = useDispatch();

  const [coolStorage, setCoolStorage] = useState(
    new SimpleCoolStorage(0, 0, 0)
  );

  const [produceList] = useState<RefrigerationProduceList>(
    new RefrigerationProduceList()
  );

  const [totalHeatLoad] = useState<TotalHeatLoad>(new TotalHeatLoad());

  const [totalLoadCapacity, setTotalLoadCapacity] = useState({
    ...new TotalHeatLoad().total(),
  });

  const [airflowUpdated, setAirflowUpdated] = useState(0);

  const [lastRoom, setLastRoom] = useState<StandardRoom>({
    temperature: 0,
    humidity: 0,
    length: 0,
    width: 0,
    height: 0,
    outsideTemperature: 0,
    outsideHumidity: 0,
    defaultRuntime: 0,
    defaultFanFactor: 0,
    defaultHeatedFasciaValue: 0,
    defaultGlassFasciaValue: 0,
  });

  const room: StandardRoom = useSelector((state: any) => {
    return state.heatLoad.room;
  });

  const productLines: ProductLine[] = useSelector((state: any) => {
    return state.heatLoad.productLines;
  });

  const wallDetails: WallDetails[] = useSelector((state: any) => {
    return state.heatLoad.walls;
  });

  const ceilingDetails: CeilingDetails = useSelector((state: any) => {
    return state.heatLoad.ceiling;
  });

  const floorDetails: FloorDetails = useSelector((state: any) => {
    return state.heatLoad.floor;
  });

  const doorDetails: DoorDetails[] = useSelector((state: any) => {
    return state.heatLoad.doors;
  });

  const peopleDetails: PeopleDetails = useSelector((state: any) => {
    return state.heatLoad.people;
  });

  const ventilationDetails: VentilationDetails = useSelector((state: any) => {
    return state.heatLoad.ventilation;
  });

  const lightingDetails: LightingDetails = useSelector((state: any) => {
    return state.heatLoad.lighting;
  });

  const forkliftDetails: ForkliftDetails = useSelector((state: any) => {
    return state.heatLoad.forklift;
  });

  const safetyAndRuntime: SafetyAndRuntime = useSelector((state: any) => {
    return state.heatLoad.safetyAndRuntime;
  });

  const roomConditionsChanged = (
    lastRoom: StandardRoom,
    room: StandardRoom
  ) => {
    return (
      room !== lastRoom &&
      (lastRoom.length !== room.length ||
        lastRoom.width !== room.width ||
        lastRoom.height !== room.height ||
        lastRoom.temperature !== room.temperature ||
        lastRoom.humidity !== room.humidity ||
        lastRoom.outsideTemperature !== room.outsideTemperature ||
        lastRoom.outsideHumidity !== room.outsideHumidity)
    );
  };
  const isValidProduct = (p: ProductLine) => {
    return (
      p.product &&
      p.massFlow >= 0 &&
      p.pullDownTime >= 0.05 &&
      p.temperatureIn !== undefined &&
      p.temperatureOut !== undefined
    );
  };

  const canCalculateTransmissionLoad = (
    thickness: number,
    ambientTemperature: number
  ) => {
    return (
      thickness >= 30 &&
      thickness <= 250 &&
      ambientTemperature >= -40 &&
      ambientTemperature <= 40
    );
  };

  const canCalculateDoorTransmissionLoad = (door: DoorDetails) => {
    return (
      door.location &&
      door.id &&
      door.height > 0 &&
      door.width > 0 &&
      door.numberOfDoors >= 1 &&
      door.extendedValues
    );
  };

  const canCalculateDoorHeatingLoad = (door: DoorDetails) => {
    // This function checks that required fields have been filled in by the user especially if a Custom selection is picked
    // Eg if they select Custom heated glass in the dropdown, they must then fill in the custom values in the corresponding textbox
    let isCustomHeatedFascia = door.heatedFascia === "custom";
    let isCustomHeatedGlass = door.heatedGlass === "custom";

    if (isCustomHeatedFascia && isCustomHeatedGlass) {
      return door.customHeatedFascia && door.customHeatedGlass;
    } else if (isCustomHeatedFascia) {
      return door.customHeatedFascia;
    } else if (isCustomHeatedGlass) {
      return door.customHeatedGlass;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let simpleCoolStorage = new SimpleCoolStorage(
      room.length,
      room.width,
      room.height
    );
    simpleCoolStorage.roomAirCondition = new AirCondition(
      room.temperature,
      room.humidity
    );
    simpleCoolStorage.outsideAirCondition = new AirCondition(
      room.outsideTemperature,
      room.outsideHumidity
    );

    simpleCoolStorage.runtime = room.defaultRuntime;

    if (simpleCoolStorage.isValid() && roomConditionsChanged(lastRoom, room)) {
      setCoolStorage(simpleCoolStorage);
      setLastRoom(room);
    }
  }, [room, lastRoom]);

  // Room, infiltration load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }
    let walls: WallDimensions[] = coolStorage.walls.map((w) => {
      return {
        id: w.id,
        vertices: w.edgers.map((e) => e.vertexStart),
        surfaceArea: w.surfaceArea(),
      };
    });

    let roomDimensions = {
      walls: walls,
      ceiling: {
        id: coolStorage.ceiling.id,
        vertices: coolStorage.ceiling.edgers.map((e) => e.vertexStart),
        surfaceArea: coolStorage.ceiling.surfaceArea(),
      },
      floor: {
        id: coolStorage.roomFloor.id,
        vertices: coolStorage.roomFloor.edgers.map((e) => e.vertexStart),
        surfaceArea: coolStorage.roomFloor.surfaceArea(),
      },
      volume: coolStorage.volume,
    };
    dispatch(selectors.saveRoomDimensions(roomDimensions));
  }, [coolStorage, dispatch, totalHeatLoad]);

  // Produce load
  useEffect(() => {
    let validLines = productLines.filter((p) => isValidProduct(p));
    produceList.clearProducts();
    totalHeatLoad.removeSectionHeatLoadCapacity(
      HeatLoadSections.PRODUCT_HEAT_LOAD
    );

    if (validLines && validLines.length > 0) {
      validLines.forEach((p) => {
        let productLine: ProductLine = {
          lineId: p.lineId,
          product: p.product,
          temperatureIn: p.temperatureIn,
          temperatureOut: p.temperatureOut,
          massFlow: p.massFlow * 1000,
          flowPeriod: p.flowPeriod * 60 * 60,
          pullDownTime: p.flowPeriod * 60 * 60,
        };
        produceList.addProduct(productLine);
      });
    }
    totalHeatLoad.addHeatLoadCapacities(produceList.totalLoadDetails());

    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [productLines, totalHeatLoad, produceList]);

  // wall, transmission load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    let validWalls: WallDetails[] = wallDetails.filter(
      (w) =>
        canCalculateTransmissionLoad(w.thickness, w.ambientTemp) &&
        w.extendedValues &&
        w.extendedValues["conductivity"]
    );

    if (validWalls && validWalls.length === 4) {
      validWalls.forEach((w, index) => {
        coolStorage.setWallConductivity(
          "wall_" + index,
          w.extendedValues?.conductivity ?? 0
        );
        coolStorage.setWallThickness("wall_" + index, w.thickness);
        coolStorage.setWallOutSideAirCondition(
          "wall_" + index,
          new AirCondition(w.ambientTemp, 90),
          false
        );
      });

      let wallT = coolStorage.wallTransmissionLoad();
      totalHeatLoad.addHeatLoadCapacities(wallT);

      setTotalLoadCapacity({
        ...totalHeatLoad.total(),
      });
    }
  }, [wallDetails, totalHeatLoad, coolStorage]);

  // Ceiling, transmission load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    if (
      canCalculateTransmissionLoad(
        ceilingDetails.thickness,
        ceilingDetails.ambientTemp
      ) &&
      ceilingDetails.extendedValues &&
      ceilingDetails.extendedValues["conductivity"]
    ) {
      let conductivity = ceilingDetails.extendedValues["conductivity"];
      coolStorage.setCeilingConductivity(conductivity);
      coolStorage.setCeilingThickness(ceilingDetails.thickness);
      coolStorage.setCeilingOutSideAirCondition(
        new AirCondition(ceilingDetails.ambientTemp, 90),
        false
      );

      totalHeatLoad.addHeatLoadCapacity(coolStorage.ceilingTransmissionLoad());

      setTotalLoadCapacity({
        ...totalHeatLoad.total(),
      });
    }
  }, [ceilingDetails, totalHeatLoad, coolStorage]);

  // Floor, transmission load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    if (
      floorDetails.floorCover &&
      canCalculateTransmissionLoad(
        floorDetails.floorCover.thickness,
        floorDetails.groundTemp
      ) &&
      floorDetails.floorCover.extendedValues &&
      floorDetails.floorCover.extendedValues["conductivity"]
    ) {
      let conductivity = floorDetails.floorCover.extendedValues.conductivity;
      coolStorage.setFloorConductivity(conductivity);
      coolStorage.setFloorThickness(floorDetails.floorCover.thickness);
      coolStorage.setFloorOutSideAirCondition(
        new AirCondition(floorDetails.groundTemp, 90),
        false
      );
      coolStorage.clearFloorInsulation();
      coolStorage.clearFloorHeating();
      if (
        floorDetails.floorInsulation &&
        floorDetails.floorInsulation.thickness &&
        floorDetails.floorInsulation.extendedValues &&
        floorDetails.floorInsulation.extendedValues["conductivity"]
      ) {
        coolStorage.setFloorInsulation({
          thickness: floorDetails.floorInsulation?.thickness,
          conductivity:
            floorDetails.floorInsulation?.extendedValues["conductivity"],
        });
      }

      if (
        floorDetails.floorHeating &&
        floorDetails.floorHeating.wattagePerSqm
      ) {
        coolStorage.setFloorHeating({
          wattagePerSqm: floorDetails.floorHeating?.wattagePerSqm,
        });
      }

      totalHeatLoad.addHeatLoadCapacity(coolStorage.floorTransmissionLoad());
      totalHeatLoad.addHeatLoadCapacity(coolStorage.floorHeatingLoad());

      setTotalLoadCapacity({
        ...totalHeatLoad.total(),
      });
    }
  }, [floorDetails, totalHeatLoad, coolStorage]);

  // Doors, infiltration load
  // TODO: Service error handling
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    let doors: DoorModel[] = [];
    doorDetails.forEach((d) => {
      if (
        canCalculateDoorTransmissionLoad(d) &&
        canCalculateDoorHeatingLoad(d)
      ) {
        let storedHeatedFascia;
        if (d.heatedFascia === "custom") {
          storedHeatedFascia = Number(d.customHeatedFascia);
        } else if (d.heatedFascia === "none") {
          storedHeatedFascia = 0;
        } else {
          storedHeatedFascia = Number(d.heatedFascia);
        }

        let storedHeatedGlass;
        if (d.heatedGlass === "custom") {
          storedHeatedGlass = Number(d.customHeatedGlass);
        } else if (d.heatedGlass === undefined || d.heatedGlass === "none") {
          storedHeatedGlass = 0;
        } else {
          storedHeatedGlass = Number(d.heatedGlass);
        }

        doors.push({
          wallId: d.location,
          doorId: d.id,
          height: d.height,
          width: d.width,
          numberOfDoors: d.numberOfDoors,
          doorAttributes: {
            doorwayUseFactor: d.extendedValues["usageFactor"],
            uValue: d.extendedValues["uValue"],
            conductivity: d.extendedValues["conductivity"],
            doorwayOpeningFactor: d.extendedValues["doorFactor"],
            openingTime: 5,
            effectiveness: 0,
            heatedFascia: storedHeatedFascia,
            heatedGlass: storedHeatedGlass,
          },
        });
      }
    });

    let wallDoors = coolStorage.updateDoors(doors);
    totalHeatLoad.removeSectionHeatLoadCapacity(
      HeatLoadSections.DOOR_INFILTRATION
    );

    let doorsUpdated = false;
    wallDoors.forEach((wd) => {
      coolStorage.doorInfiltrationLoad(wd.wallId, wd.doorId).then((load) => {
        try {
          coolStorage.updateDoorAirflow(wd.wallId, wd.doorId, load.airflow);
          totalHeatLoad.addHeatLoadCapacity({
            sensible: load.sensible,
            latent: load.latent,
            reference: wd.doorId,
            heatLoadType: HeatLoadSections.DOOR_INFILTRATION.name,
          });
          setTotalLoadCapacity({
            ...totalHeatLoad.total(),
          });
          doorsUpdated = true;
        } catch (error) {
          // ignore intermittent errors cause by concurrency
        }
      });
    });
    // Any change to door airflow should trigger recalculation of ventilation.
    // one way to do that is to duplicate door calculation inside ventilation,
    // but this is a workaround for that.
    if (doorsUpdated) {
      setAirflowUpdated(airflowUpdated + 1);
    }

    totalHeatLoad.removeSectionHeatLoadCapacity(
      HeatLoadSections.DOOR_TRANSMISSION
    );
    totalHeatLoad.removeSectionHeatLoadCapacity(
      HeatLoadSections.DOOR_HEATED_FASCIA
    );
    totalHeatLoad.removeSectionHeatLoadCapacity(
      HeatLoadSections.DOOR_HEATED_GLASS
    );
    totalHeatLoad.addHeatLoadCapacities(coolStorage.transmissionLoadForDoors());
    totalHeatLoad.addHeatLoadCapacities(coolStorage.heatingLoadForDoors());

    // Since door affect wall surface area, wall transmission can change as well
    try {
      let wallT = coolStorage.wallTransmissionLoad();
      totalHeatLoad.addHeatLoadCapacities(wallT);
    } catch (e) {}

    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [doorDetails, coolStorage, totalHeatLoad, wallDetails, airflowUpdated]);

  // People, infiltration load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    coolStorage.people = peopleDetails;

    totalHeatLoad.addHeatLoadCapacity(coolStorage.peopleLoad());

    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [peopleDetails, coolStorage, totalHeatLoad]);

  // lighting, infiltration load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    coolStorage.lighting = lightingDetails;

    totalHeatLoad.addHeatLoadCapacity(coolStorage.lightingLoad());

    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [lightingDetails, coolStorage, totalHeatLoad]);

  // Forklifts, infiltration load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    coolStorage.forklift = forkliftDetails;

    totalHeatLoad.addHeatLoadCapacity(coolStorage.forkliftLoad());

    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [forkliftDetails, coolStorage, totalHeatLoad]);

  // Ventilation load
  useEffect(() => {
    if (coolStorage.isEmptyRoom()) {
      return;
    }

    if (ventilationDetails) coolStorage.ventilation = ventilationDetails;

    if (coolStorage.isValid()) {
      totalHeatLoad.addHeatLoadCapacity({
        sensible: 0,
        latent: 0,
        reference: "room",
        heatLoadType: HeatLoadSections.VENTILATION.name,
      });
      setTotalLoadCapacity({
        ...totalHeatLoad.total(),
      });

      coolStorage.airFlow() &&
        coolStorage.infiltrationLoad().then((load) => {
          totalHeatLoad.addHeatLoadCapacity({
            sensible: load.sensible,
            latent: load.latent,
            reference: "room",
            heatLoadType: HeatLoadSections.VENTILATION.name,
          });
          setTotalLoadCapacity({
            ...totalHeatLoad.total(),
          });
        });
    }
  }, [ventilationDetails, coolStorage, totalHeatLoad]);

  useEffect(() => {
    coolStorage.runtime = safetyAndRuntime.equipmentRuntime;
    totalHeatLoad.runtime = safetyAndRuntime.equipmentRuntime;
    totalHeatLoad.fanFactor = safetyAndRuntime.fanFactor;
    totalHeatLoad.safetyFactor = safetyAndRuntime.safetyFactor;
    totalHeatLoad.otherLoad = safetyAndRuntime.otherLoads;
    setTotalLoadCapacity({
      ...totalHeatLoad.total(),
    });
  }, [safetyAndRuntime, coolStorage, totalHeatLoad]);

  useEffect(() => {
    dispatch(selectors.saveHeatLoadCapacity(totalLoadCapacity));
  }, [totalLoadCapacity, dispatch]);

  return enableHeatLoadLogs ? heatLoadLogs(totalHeatLoad, produceList) : <></>;
};

export default HeatLoadCalculationContainer;
