import React, { useCallback } from "react";
import styles from "../../../../css/ResponsiveTable.module.css";
import { EvaporatorProductCapacity } from "../../../../types/EvaporatorProductCapacity";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
} from "../../../../components/Table/Table";
import { ProductFilter } from "../../../../types/ProductFilter";
import Filters from "../../../../components/Filters/Filters";
import Thumbnail from "../../../../components/Thumbnail/Thumbnail";
import { FilterValues } from "../../../../types/FilterValues";
import TablePlaceholder from "../../../../components/TablePlaceholder/TablePlaceholder";
import ProductCard from "../../../../components/ProductCard/ProductCard";

const EvaporatorProductItem: React.FC<{
  products: EvaporatorProductCapacity[];
  selectedEvaporator: EvaporatorProductCapacity | undefined;
  handleEvaporatorSelect: Function;
  evaporatorFilters: ProductFilter[];
  filterSelections: FilterValues;
  setFilterValues: (filterType: string, value: string) => void;
  resetEvaporatorFilters: () => void;
}> = ({
  products,
  selectedEvaporator,
  handleEvaporatorSelect,
  evaporatorFilters,
  filterSelections,
  setFilterValues,
  resetEvaporatorFilters,
}) => {
  const handleSelect = useCallback(
    (product: EvaporatorProductCapacity, event) => {
      event.preventDefault();
      handleEvaporatorSelect(product, true);
    },
    [handleEvaporatorSelect]
  );

  products.sort((a, b) => {
    return a.operating_capacity.capacity - b.operating_capacity.capacity;
  });

  return (
    <div>
      <Filters
        filters={evaporatorFilters}
        filterValues={filterSelections}
        setFilterValues={setFilterValues}
        handleReset={resetEvaporatorFilters}
      />
      {products.length === 0 && (
        <TablePlaceholder
          heading="No results"
          subheading="Update details to see matching products"
        />
      )}
      {products.length > 0 && (
        <>
          <div className={styles.tableContainer}>
            <Table>
              <Header>
                <Row>
                  <HeaderCell>Preview</HeaderCell>
                  <HeaderCell>Model</HeaderCell>
                  <HeaderCell>Product Code</HeaderCell>
                  <HeaderCell>Product range</HeaderCell>
                  <HeaderCell>Operating capacity</HeaderCell>
                  <HeaderCell>Fan phase</HeaderCell>
                </Row>
              </Header>
              <Body>
                {products.map((item) => (
                  <Row
                    selected={
                      selectedEvaporator !== undefined &&
                      selectedEvaporator.model === item.model
                    }
                    key={item.model}
                    onClick={(e) => handleSelect(item, e)}
                  >
                    <Cell>
                      <Thumbnail thumbnailUrl={item.thumbnail} />
                    </Cell>
                    <Cell>{item.model}</Cell>
                    <Cell>{item.product_code}</Cell>
                    <Cell dataTestId="product_range">{item.series}</Cell>
                    <Cell dataTestId="operating_capacity">
                      {Math.round(item.operating_capacity.capacity)}
                    </Cell>
                    <Cell dataTestId="fan_phase">{item.fan_phase}</Cell>
                  </Row>
                ))}
              </Body>
            </Table>
          </div>
          <div className={styles.gridContainer}>
            {products.map((item) => {
              let productDescriptionValues = [
                {
                  label: "Product code",
                  value: item.product_code,
                },
                { label: "Series", value: item.series },
                {
                  label: "Operating capacity",
                  value: Math.round(
                    item.operating_capacity.capacity
                  ).toString(),
                },
                { label: "Fan phase", value: item.fan_phase },
              ];

              return (
                <ProductCard
                  title={item.model}
                  thumbnail={item.thumbnail}
                  productDescriptionValues={productDescriptionValues}
                  key={item.model}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default EvaporatorProductItem;
