import { FieldError, RegisterOptions } from "react-hook-form";
import isAnEmptyObject from "../../../helpers/emptyObject";

interface RoomDetailsErrors {
  locationSelected?:
    | {
        label?: FieldError | undefined;
        value?: FieldError | undefined;
      }
    | undefined;
  temperature?: FieldError | undefined;
  humidity?: FieldError | undefined;
  length?: FieldError | undefined;
  width?: FieldError | undefined;
  height?: FieldError | undefined;
  outsideTemperature?: FieldError | undefined;
  outsideHumidity?: FieldError | undefined;
  groundTemperature?: FieldError | undefined;
}

interface RoomDetailsDirtyFields {
  locationSelected?:
    | {
        label?: boolean | undefined;
        value?: boolean | undefined;
      }
    | undefined;
  temperature?: boolean | undefined;
  humidity?: boolean | undefined;
  length?: boolean | undefined;
  width?: boolean | undefined;
  height?: boolean | undefined;
  outsideTemperature?: boolean | undefined;
  outsideHumidity?: boolean | undefined;
  groundTemperature?: boolean | undefined;
}

const outsideTemperatureRules: RegisterOptions = {
  required: "Required",
  valueAsNumber: true,
  min: {
    value: -40,
    message: "Must be between -40\u00b0 and +45\u00b0 Celsius",
  },
  max: {
    value: 45,
    message: "Must be between -40\u00b0 and +45\u00b0 Celsius",
  },
  validate: {
    validOutsideTemp: (value) => {
      const matches = value.toString().match(/^-?\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const roomHumidityRules: RegisterOptions = {
  required: "Required",
  min: {
    value: 0,
    message: "Must be between 0% and 100%",
  },
  max: {
    value: 100,
    message: "Must be between 0% and 100%",
  },
  valueAsNumber: true,
  validate: {
    validRoomHumidity: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const outsideHumidityRules: RegisterOptions = {
  required: "Required",
  pattern: {
    value: /^\d*\.?\d*$/,
    message: "Must be a number",
  },
  min: {
    value: 0,
    message: "Resulting value must be between 0% and 100%",
  },
  max: {
    value: 100,
    message: "Resulting value must be between 0% and 100%",
  },
  valueAsNumber: true,
};

const roomLengthRules: RegisterOptions = {
  required: "Required",
  min: {
    value: 100,
    message: "Must be between 100mm and 150000mm",
  },
  max: {
    value: 150000,
    message: "Must be between 100mm and 150000mm",
  },
  valueAsNumber: true,
  validate: {
    validLength: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const isErrorRoomDetails = (errors: RoomDetailsErrors): boolean => {
  return !isAnEmptyObject(errors);
};

const allRoomDetailsFieldsDirty = (
  dirtyFields: RoomDetailsDirtyFields
): boolean => {
  let roomDetailsDirtyFlag =
    dirtyFields.length &&
    dirtyFields.width &&
    dirtyFields.height &&
    dirtyFields.temperature &&
    dirtyFields.humidity;

  return roomDetailsDirtyFlag ?? false;
};

const validateRoomDetails = (
  errors: RoomDetailsErrors,
  dirtyFields: RoomDetailsDirtyFields
): boolean => {
  return !isErrorRoomDetails(errors) && allRoomDetailsFieldsDirty(dirtyFields);
};

export {
  outsideTemperatureRules,
  roomHumidityRules,
  outsideHumidityRules,
  roomLengthRules,
  validateRoomDetails,
};
