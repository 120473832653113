import {
  HeatLoadSections,
  ProductHeatLoadCapacity,
} from "../load/TotalHeatLoad";

interface Product {
  category: string;
  heatAboveFreeze: number;
  heatBelowFreeze: number | undefined;
  freezingPoint: number | undefined;
  latentHeat: number | undefined;
}

export interface RefrigerationProduct {
  lineId: string;
  product: Product;
  temperatureIn: number;
  temperatureOut: number;
  massFlow: number;
  flowPeriod: number;
}

interface ProductHeatLoad {
  total: number;
}

interface ReferenceCapacity {
  [reference: string]: RefrigerationProduct;
}

interface Capacity {
  [section: string]: ReferenceCapacity;
}

const DEFAULT_FREEZER_POINT = -2.0;

const calculateProductHeatLoad = (
  massFlow: number,
  sensibleHeatPerUnitOfMass: number,
  tDifference: number
) => {
  return massFlow * sensibleHeatPerUnitOfMass * tDifference;
};

class RefrigerationProduceList {
  private readonly _refrigerationProduce: Capacity;

  constructor() {
    this._refrigerationProduce = {};
    this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name] = {};
  }

  addProduct(product: RefrigerationProduct) {
    this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name][
      product.lineId
    ] = Object.assign({}, product);
  }

  removeProduct(productId: string) {
    let products =
      this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name];
    if (products !== undefined && !products[productId] !== undefined) {
      delete products[productId];
    }
  }

  clearProducts() {
    this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name] = {};
  }

  getRefrigerationProduce(productId: string): RefrigerationProduct {
    let p: RefrigerationProduct =
      this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name][
        productId
      ];
    return Object.assign({}, p);
  }

  private productHeatLoad(
    refProduct: RefrigerationProduct
  ): ProductHeatLoadCapacity {
    let heatLoad: ProductHeatLoadCapacity = {
      heatAboveFreeze: 0,
      heatBelowFreeze: 0,
      latentHeat: 0,
      total: 0,
      reference: refProduct.lineId,
      heatLoadType: HeatLoadSections.PRODUCT_HEAT_LOAD.name,
      sensible: 0,
      latent: 0,
    };
    let product = refProduct.product;
    let massFlowRate = refProduct.massFlow / refProduct.flowPeriod;
    let load: number;

    let freezingPoint = product.freezingPoint
      ? product.freezingPoint
      : DEFAULT_FREEZER_POINT;

    if (
      !product.heatBelowFreeze &&
      refProduct.temperatureOut <= freezingPoint
    ) {
      throw new Error("Invalid heatBelowFreeze");
    }
    if (!product.latentHeat && refProduct.temperatureOut <= freezingPoint) {
      throw new Error("Invalid latentHeat");
    }

    let heatBelowFreeze = product.heatBelowFreeze ?? NaN;
    let latentHeat = product.latentHeat ?? NaN;

    if (refProduct.temperatureIn < freezingPoint) {
      load = calculateProductHeatLoad(
        massFlowRate,
        heatBelowFreeze,
        refProduct.temperatureIn - refProduct.temperatureOut
      );
      heatLoad.heatBelowFreeze = +load.toFixed(3);
    } else {
      if (freezingPoint < refProduct.temperatureOut) {
        load = calculateProductHeatLoad(
          massFlowRate,
          product.heatAboveFreeze,
          refProduct.temperatureIn - refProduct.temperatureOut
        );
        heatLoad.heatAboveFreeze = +load.toFixed(3);
      } else {
        load = calculateProductHeatLoad(
          massFlowRate,
          product.heatAboveFreeze,
          refProduct.temperatureIn - freezingPoint
        );
        heatLoad.heatAboveFreeze = +load.toFixed(3);

        load = calculateProductHeatLoad(massFlowRate, latentHeat, 1);
        heatLoad.latentHeat = +load.toFixed(3);

        load = calculateProductHeatLoad(
          massFlowRate,
          heatBelowFreeze,
          freezingPoint - refProduct.temperatureOut
        );
        heatLoad.heatBelowFreeze = +load.toFixed(3);
      }
    }

    load =
      heatLoad.heatBelowFreeze + heatLoad.latentHeat + heatLoad.heatAboveFreeze;
    heatLoad.total = +load.toFixed(3);
    heatLoad.sensible = +(
      heatLoad.heatBelowFreeze + heatLoad.heatAboveFreeze
    ).toFixed(3);
    heatLoad.latent = +heatLoad.latentHeat.toFixed(3);
    return heatLoad;
  }

  totalLoad(): ProductHeatLoad {
    let total: number = 0;
    Object.entries(
      this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name]
    ).forEach(([, load]) => {
      total += this.productHeatLoad(load).total;
    });

    return { total: +total.toFixed(2) };
  }

  totalLoadDetails(): ProductHeatLoadCapacity[] {
    let total: ProductHeatLoadCapacity[] = [];
    Object.entries(
      this._refrigerationProduce[HeatLoadSections.PRODUCT_HEAT_LOAD.name]
    ).forEach(([, load]) => {
      total.push(this.productHeatLoad(load));
    });

    return total;
  }
}

export { RefrigerationProduceList };
