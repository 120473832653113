import React, { FC } from "react";
import {
  RegisterOptions,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Placeholder from "../../../components/Placeholder/Placeholder";
import heatLoadStyles from "../../page/HeatLoad.module.css";
import {
  enteringTempRules,
  productAmountRules,
  pullDownTimeRules,
} from "./ProductDetailsValidation";
import { ExtendedValues, ProductFilter } from "../../../types/ProductFilter";
import SearchGroupSelect from "../../../hook-form-components/SearchSelect/SearchGroupSelect";
import { GroupedOption, Option } from "../../../types/SearchSelect";
import { filterExtendedValues } from "./ProductDetailsHelper";
import InfoText from "../../../components/InfoText/InfoText";
import FormRow from "../../../components/FormRow/FormRow";
import { logPageEventWithData } from "../../../helpers/amplitude";

const AddProductButton: FC<{
  onClickHandler: () => void;
  disableButton?: boolean;
}> = ({ onClickHandler, disableButton }) => {
  return (
    <div className={heatLoadStyles.addButtonContainer}>
      <Button
        buttonStyle="secondary"
        onClickHandler={onClickHandler}
        isDisabled={disableButton}
        imageFilename="add"
        dataTestId="addProductButton"
      >
        Add product
      </Button>
    </div>
  );
};

function verifyProductHeatloadProperties(
  selectedProduct: Option,
  productFilter: ProductFilter,
  temperatureOut: number
) {
  let extendedValues: ExtendedValues = filterExtendedValues(
    selectedProduct.value,
    productFilter
  );
  let freezingPoint = extendedValues["freezingPoint"] ?? -2.0;

  if (temperatureOut) {
    if (temperatureOut > +freezingPoint) {
      return true;
    }

    if (extendedValues["latentHeat"] && extendedValues["heatBelowFreeze"]) {
      return true;
    }

    return "Product missing heatload properties";
  }

  return true;
}

const ProductDetails: FC<{
  productFilter: ProductFilter;
  productGroupedOptions: GroupedOption[];
  removeProductHandler: (i: number, r: (i: number) => void) => void;
  roomTemperature: number;
  formEnabled: boolean;
}> = ({
  productFilter,
  productGroupedOptions,
  removeProductHandler,
  roomTemperature,
  formEnabled,
}) => {
  const {
    control,
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const handleAddProduct = () => {
    append({
      product: { label: "", value: "" },
      amount: undefined,
      enteringTemp: undefined,
      finalTemp: undefined,
      pullDownTime: undefined,
    });

    logPageEventWithData("Heat Load - Add Product Clicked", {
      numberOfProducts: fields.length,
    });
  };

  const handleDeleteProduct = (index: number) => {
    removeProductHandler(index, remove);
    logPageEventWithData("Heat Load - Delete Product Clicked", {
      numberOfProducts: fields.length,
    });
  };

  let missingProductInfoRule = (index: number): RegisterOptions => {
    return {
      required: "Required",
      validate: {
        missingProductAttributes: () => {
          let temperatureOut = getValues(`products.${index}.finalTemp`);
          let selectedProduct = getValues(`products.${index}.product`);

          return verifyProductHeatloadProperties(
            selectedProduct,
            productFilter,
            temperatureOut
          );
        },
      },
    };
  };

  const finalTempRules = (
    roomTemperature: number,
    index: number
  ): RegisterOptions => {
    return {
      required: "Required",
      pattern: {
        value: /^-?\d*\.?\d*$/,
        message: "Must be a number",
      },
      min: {
        value: roomTemperature,
        message: `Must be between ${roomTemperature}\u00b0 and +30\u00b0 Celsius`,
      },
      max: {
        value: 30,
        message: `Must be between ${roomTemperature}\u00b0 and +30\u00b0 Celsius`,
      },
      valueAsNumber: true,
      validate: {
        missingProductAttributes: (temperatureOut: number) => {
          let selectedProduct = getValues(`products.${index}.product`);

          return verifyProductHeatloadProperties(
            selectedProduct,
            productFilter,
            temperatureOut
          );
        },
      },
    };
  };

  return (
    <div
      data-testid="product-details"
      className={heatLoadStyles.narrowFormContainer}
    >
      {formEnabled && fields.length > 0 && (
        <>
          <div>
            <p className="section-description">
              Provide details of the types of products planning to be stored.
            </p>
          </div>

          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <SearchGroupSelect
                  name={`products.${index}.product`}
                  labelText="Product"
                  options={productGroupedOptions}
                  hasErrors={errors.products?.[index]?.product}
                  errorMessage={errors.products?.[index]?.product?.message}
                  control={control}
                  rules={missingProductInfoRule(index)}
                  nameAsConst
                />

                <FormRow>
                  <Input
                    {...register(
                      `products.${index}.amount` as const,
                      productAmountRules
                    )}
                    labelText="Amount"
                    placeholderText="0 to 600000"
                    innerLabel="kg"
                    hasErrors={errors.products?.[index]?.amount}
                    errorMessage={errors.products?.[index]?.amount?.message}
                    fieldSize="medium"
                  />

                  <Input
                    {...register(
                      `products.${index}.enteringTemp` as const,
                      enteringTempRules
                    )}
                    labelText="Entering temp."
                    placeholderText="-40° to +100°"
                    innerLabel="°C"
                    hasErrors={errors.products?.[index]?.enteringTemp}
                    errorMessage={
                      errors.products?.[index]?.enteringTemp?.message
                    }
                    fieldSize="medium"
                  />
                </FormRow>

                <FormRow>
                  <Input
                    {...register(
                      `products.${index}.finalTemp` as const,
                      finalTempRules(roomTemperature, index)
                    )}
                    labelText="Storage temp."
                    placeholderText={`${roomTemperature}° to +30°`}
                    innerLabel="°C"
                    hasErrors={errors.products?.[index]?.finalTemp}
                    errorMessage={errors.products?.[index]?.finalTemp?.message}
                    fieldSize="medium"
                  />

                  <Input
                    {...register(
                      `products.${index}.pullDownTime` as const,
                      pullDownTimeRules
                    )}
                    labelText="Pull down time"
                    placeholderText="1 to 120"
                    innerLabel="hrs"
                    hasErrors={errors.products?.[index]?.pullDownTime}
                    errorMessage={
                      errors.products?.[index]?.pullDownTime?.message
                    }
                    fieldSize="medium"
                  />
                </FormRow>

                <div className={heatLoadStyles.deleteButtonContainer}>
                  <Button
                    onClickHandler={() => {
                      handleDeleteProduct(index);
                    }}
                    buttonType="button"
                    buttonStyle="secondary_red"
                    imageFilename="delete_outline_red"
                    dataTestId="deleteProductButton"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          })}

          <AddProductButton
            onClickHandler={handleAddProduct}
            disableButton={fields.length >= 10}
          >
            Add product
          </AddProductButton>
        </>
      )}

      {formEnabled && fields.length === 0 && (
        <section>
          <Placeholder
            heading="No products to display"
            subheading="Add details of products that will be stored in the room"
          >
            <AddProductButton onClickHandler={handleAddProduct}>
              Add product
            </AddProductButton>
          </Placeholder>
        </section>
      )}

      {!formEnabled && (
        <InfoText testid="no-product-details">
          Add your Product details after you’ve defined your room and
          transmission details.
        </InfoText>
      )}
    </div>
  );
};

export default ProductDetails;
