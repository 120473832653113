import {
  FilterOption,
  ProductFilter,
  ProductFilterMap,
  SectionFilterMap,
} from "../../types/ProductFilter";

export const EMPTY_FILTER = {
  name: "",
  type: "",
  options: [{ key: "", value: "" }],
};

const FilterFormGroups: {
  [name: string]:
    | "miscellaneous"
    | "transmission"
    | "doors"
    | "products"
    | "locations";
} = {
  ventilation: "miscellaneous",
  people_activity: "miscellaneous",
  lighting_type: "miscellaneous",
  forklift_type: "miscellaneous",
  wall_insulation: "transmission",
  ceiling_insulation: "transmission",
  floor_material: "transmission",
  floor_insulation: "transmission",
  floor_heating: "transmission",
  door_usage: "doors",
  doorway_category: "doors",
  doorway_heated_frame: "doors",
  doorway_heated_glass: "doors",
  glass_glaze: "doors",
  door_material: "doors",
  product_category: "products",
  product: "products",
  location: "locations",
  location_state: "locations",
};

const defaultExtendedValueSort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["extended_values"] || !b || !b["extended_values"]) return -1;

  let valueA = parseInt(b["extended_values"]["sortedOrder"]);
  let valueB = parseInt(a["extended_values"]["sortedOrder"]);

  return valueB - valueA;
};

const doorUsageSort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["extended_values"] || !b || !b["extended_values"]) return -1;

  let valueA = parseFloat(b["extended_values"]["usage_factor"]);
  let valueB = parseFloat(a["extended_values"]["usage_factor"]);

  return valueB - valueA;
};

const peopleActivitySort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["extended_values"] || !b || !b["extended_values"]) return -1;

  let valueA = parseFloat(b["extended_values"]["activity_factor"]);
  let valueB = parseFloat(a["extended_values"]["activity_factor"]);

  return valueB - valueA;
};

const heatGeneratedSort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["extended_values"] || !b || !b["extended_values"]) return -1;

  let valueA = parseFloat(b["extended_values"]["heat_generated"]);
  let valueB = parseFloat(a["extended_values"]["heat_generated"]);

  return valueB - valueA;
};

const wallInsulationSort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["extended_values"] || !b || !b["extended_values"]) return -1;

  let valueA = parseFloat(b["extended_values"]["conductivity"]);
  let valueB = parseFloat(a["extended_values"]["conductivity"]);

  return valueB - valueA;
};

const defaultNameSort: (a: FilterOption, b: FilterOption) => number = (
  a: FilterOption,
  b: FilterOption
) => {
  if (!a || !a["value"] || !b || !b["value"]) return -1;

  let valueA = b["value"];
  let valueB = a["value"];

  if (valueA < valueB) {
    return 1;
  }
  if (valueA > valueB) {
    return -1;
  }
  return 0;
};

const FilterSort: {
  [name: string]: (a: FilterOption, b: FilterOption) => number;
} = {
  ventilation: defaultExtendedValueSort,
  people_activity: peopleActivitySort,
  lighting_type: heatGeneratedSort,
  forklift_type: heatGeneratedSort,
  wall_insulation: wallInsulationSort,
  ceiling_insulation: wallInsulationSort,
  floor_material: wallInsulationSort,
  floor_insulation: wallInsulationSort,
  floor_heating: defaultExtendedValueSort,
  door_usage: doorUsageSort,
  doorway_heated_frame: defaultExtendedValueSort,
  doorway_heated_glass: defaultExtendedValueSort,
  glass_glaze: defaultExtendedValueSort,
  door_material: defaultExtendedValueSort,
  doorway_category: defaultExtendedValueSort,
};

const sortedFilterOptions = (
  f: ProductFilter,
  s: (a: FilterOption, b: FilterOption) => number
) => {
  f.options = f.options.sort(s);
  return f;
};

const findComparator = (name: string) => {
  let comparator = FilterSort[name];
  if (!comparator) {
    return defaultNameSort;
  }
  return comparator;
};

const findFilterFormSection = (filterName: string) => {
  let filterFormGroup = FilterFormGroups[filterName];
  if (!filterFormGroup) {
    return "unknownCategory";
  }

  return filterFormGroup;
};

const extractFilterGroups = (filters: ProductFilter[]) => {
  let sectionMap: SectionFilterMap = {};

  filters.forEach((f) => {
    let filterMap: ProductFilterMap = {};
    let filterFormGroup = findFilterFormSection(f.type);
    if (sectionMap[filterFormGroup]) {
      filterMap = sectionMap[filterFormGroup];
    }
    filterMap[f.type] = sortedFilterOptions(f, findComparator(f.type));
    sectionMap[filterFormGroup] = filterMap;
  });

  return sectionMap;
};

export { extractFilterGroups, findFilterFormSection };
