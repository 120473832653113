import { FieldError, RegisterOptions } from "react-hook-form";
import isAnEmptyObject from "../../../helpers/emptyObject";

interface SafetyAndRuntimeDetailsDirtyFields {
  otherLoads?: boolean | undefined;
  equipmentRuntime?: boolean | undefined;
  safetyFactor?: boolean | undefined;
  fanFactor?: boolean | undefined;
}

interface SafetyAndRuntimeDetailsErrors {
  otherLoads?: FieldError | undefined;
  equipmentRuntime?: FieldError | undefined;
  safetyFactor?: FieldError | undefined;
  fanFactor?: FieldError | undefined;
}

const equipmentRuntimeRule: RegisterOptions = {
  required: "Required",
  min: {
    value: 12,
    message: "Must be between 12 and 24 Hours",
  },
  max: {
    value: 24,
    message: "Must be between 12 and 24 Hours",
  },
  valueAsNumber: true,
  validate: {
    validEquipRuntime: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const safetyFactorRule: RegisterOptions = {
  required: "Required",
  min: {
    value: 0,
    message: "Must be between 0 and 100 Percent",
  },
  max: {
    value: 100,
    message: "Must be between 0 and 100 Percent",
  },
  valueAsNumber: true,
  validate: {
    validSafetyFactor: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

const otherLoadsRule: RegisterOptions = {
  required: "Required",
  min: {
    value: 1,
    message: "Must be between 1 and 100000 Watts",
  },
  max: {
    value: 100000,
    message: "Must be between 1 and 100000 Watts",
  },
  valueAsNumber: true,
  validate: {
    validOtherLoads: (value) => {
      const matches = value.toString().match(/^\d*\.?\d*$/);
      return matches?.length > 0 || "Must be a number";
    },
  },
};

function safetyAndRuntimeDetailsChanged(
  dirtyFields: SafetyAndRuntimeDetailsDirtyFields
) {
  return (
    dirtyFields.safetyFactor ||
    dirtyFields.equipmentRuntime ||
    dirtyFields.otherLoads
  );
}

function safetyAndRuntimeDetailsHasNoErrors(
  errors: SafetyAndRuntimeDetailsErrors
) {
  return isAnEmptyObject(errors);
}

export {
  equipmentRuntimeRule,
  safetyFactorRule,
  otherLoadsRule,
  safetyAndRuntimeDetailsHasNoErrors,
  safetyAndRuntimeDetailsChanged,
};
