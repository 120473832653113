import React, { FC } from "react";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import Select, { GroupBase } from "react-select";
import { GroupedOption } from "../../types/SearchSelect";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SearchIndicator from "./SearchIndicator";
import { errorStyle, selectStyles } from "./SearchSelectStyles";
import styles from "../../components/Select/Select.module.css";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  lineHeight: "1",
  minWidth: 1,
  padding: "0.1em 0.5em",
};

const formatGroupLabel = (data: GroupBase<GroupedOption>) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

interface SearchGroupSelectProps {
  name: string;
  labelText: string;
  options: GroupedOption[];
  hasErrors: boolean;
  errorMessage?: string;
  defaultValue?: string;
  control?: Control<FieldValues> | undefined;
  rules?: RegisterOptions;
  nameAsConst?: boolean;
}

const SearchGroupSelect: FC<SearchGroupSelectProps> = ({
  name,
  labelText,
  options,
  hasErrors,
  errorMessage = "",
  defaultValue = "",
  control,
  rules,
  nameAsConst = false,
}) => {
  return (
    <div className={styles.selectContainer} data-testid={name}>
      <label htmlFor={name} className={hasErrors ? styles.errorTextState : ""}>
        {labelText}
      </label>
      <Controller
        name={nameAsConst ? (`${name}` as const) : name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            components={{ DropdownIndicator: SearchIndicator }}
            options={options}
            styles={{ ...selectStyles, ...(hasErrors && errorStyle) }}
            isClearable
            classNamePrefix={name.replace(/\./g, "-")} // Do this for regression tests, can't find CSS class with .
            placeholder="Search..."
            formatGroupLabel={formatGroupLabel}
          />
        )}
      />

      {hasErrors && <ErrorMessage errorText={errorMessage} />}
    </div>
  );
};

export default SearchGroupSelect;
