import React, { useEffect, useRef } from "react";
import styles from "./ProductSelection.module.css";
import { BalancedPerformanceContainer } from "../sections/BalancedPerformance/BalancedPerformanceContainer";
import CapitalEquipmentFilter from "../sections/CapitalEquipmentFilter/CapitalEquipmentFilter";
import Layout from "../../components/Layout/Layout";
import EquipmentSelectionContainer from "../sections/EquipmentSelection/EquipmentSelectionContainer";
import SelectedEquipmentContainer from "../sections/SelectedEquipment/SelectedEquipmentContainer";
import Section from "../../components/Section/Section";
import useFilters from "../hooks/useFilters";
import ResultInformation from "../sections/ResultInformation/ResultInformation";
import SearchTypeSelection from "../sections/SearchTypeSelection/SearchTypeSelection";
import { SearchStatus } from "../../types/SearchStatus";
import { useDispatch, useSelector } from "react-redux";
import { default as actionSelector } from "../duck/selectors";

const ProductSelection: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const refEquipmentSelection = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    return () => {
      dispatch(actionSelector.clearCondensingUnitSearch());
      dispatch(actionSelector.clearEvaporatorSearch());
      dispatch(actionSelector.clearSelectedProducts());
    };
  }, [dispatch]);

  const scrollToEqupmentSelection = () => {
    if (null !== refEquipmentSelection.current) {
      window.scrollTo({
        top: refEquipmentSelection.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useFilters();

  const condensingUnitSearchStatus: SearchStatus = useSelector(
    (state: any) => state.product.condensingUnitSearchStatus
  );

  const evaporatorSearchStatus: SearchStatus = useSelector(
    (state: any) => state.product.evaporatorSearchStatus
  );

  return (
    <Layout>
      <SearchTypeSelection defaultValue="/equipment-selection" />
      <div className="section-header">Heat load data</div>
      <Section>
        <CapitalEquipmentFilter
          scrollToEquipmentSelection={scrollToEqupmentSelection}
        />
        <div className={styles.equipmentSelectionGrid}>
          <EquipmentSelectionContainer ref={refEquipmentSelection} />
          <aside className={styles.equipmentSelectionAside}>
            <BalancedPerformanceContainer />
            <ResultInformation
              condensingUnitSearchStatus={condensingUnitSearchStatus}
              evaporatorSearchStatus={evaporatorSearchStatus}
            />
            <SelectedEquipmentContainer />
          </aside>
        </div>
      </Section>
    </Layout>
  );
};

export default ProductSelection;
