import React, { useEffect, useRef, useState } from "react";
import RoomPreview from "./RoomPreview";
import { RoomDimensions } from "../../types/Room";
import { useSelector } from "react-redux";

interface Coordinate {
  x: number;
  y: number;
  z: number;
}

const DRAW_AREA_RATIO = 0.5;
const CANVAS_SIZE = { x: 340, y: 200 };
const LABEL_COORDINATES: { [key: number]: Coordinate } = {
  0: { x: CANVAS_SIZE.x / 2 - 10, y: 10, z: 0 },
  1: { x: CANVAS_SIZE.x - 70, y: CANVAS_SIZE.y / 2, z: 0 },
  2: { x: CANVAS_SIZE.x / 2 - 10, y: CANVAS_SIZE.y - 5, z: 0 },
  3: { x: 50, y: CANVAS_SIZE.y / 2, z: 0 },
};

export const PreviewContainer: React.FC<{}> = () => {
  const roomDimensions: RoomDimensions = useSelector((state: any) => {
    return state.heatLoad.roomDimensions;
  });

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

  function calculateMaxCoordinates(floorVertex: Coordinate[]): Coordinate {
    let bigX: number = 0,
      bigY: number = 0;
    floorVertex.forEach((v) => {
      if (bigX < v.x) bigX = v.x;
      if (bigY < v.y) bigY = v.y;
    });
    return { x: bigX, y: bigY, z: 0 };
  }

  function calculateLengthRatio(
    canvasSize: { x: number; y: number },
    maxXY: Coordinate
  ) {
    let lengthRatio = (canvasSize.y / maxXY.y) * DRAW_AREA_RATIO;
    if (canvasSize.x / maxXY.x < canvasSize.y / maxXY.y) {
      lengthRatio = (canvasSize.x / maxXY.x) * DRAW_AREA_RATIO;
    }
    return lengthRatio;
  }

  function calculateDrawOffset(
    maxCoordinates: Coordinate,
    lengthRatio: number
  ) {
    return {
      x: (CANVAS_SIZE.x - maxCoordinates.x * lengthRatio) / 2,
      y: (CANVAS_SIZE.y - maxCoordinates.y * lengthRatio) / 2,
    };
  }

  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        renderCtx.clearRect(0, 0, CANVAS_SIZE.x, CANVAS_SIZE.y);
        setContext(renderCtx);
      }
    }

    if (
      roomDimensions &&
      roomDimensions.floor &&
      roomDimensions.floor.surfaceArea > 0 &&
      roomDimensions.floor.vertices
    ) {
      let floorVertex = [...roomDimensions.floor.vertices];
      let maxCoordinates = calculateMaxCoordinates(floorVertex);
      let lengthRatio = calculateLengthRatio(CANVAS_SIZE, maxCoordinates);
      let drawOffset = calculateDrawOffset(maxCoordinates, lengthRatio);

      if (context) {
        context.beginPath();
        context.moveTo(drawOffset.x, drawOffset.y);

        floorVertex.forEach((v, index) => {
          context.lineTo(
            drawOffset.x + v.x * lengthRatio,
            drawOffset.y + v.y * lengthRatio
          );
          if (index in LABEL_COORDINATES) {
            let dl = LABEL_COORDINATES[index];
            if (dl) {
              context.fillText("Wall " + (index + 1), dl.x, dl.y);
            }
          }
        });
        context.closePath();

        context.strokeStyle = "#666666";
        context.lineWidth = 3;
        context.stroke();
      }
    }
  }, [context, roomDimensions]);

  return (
    <>
      <RoomPreview roomDimensions={roomDimensions} canvasRef={canvasRef} />
    </>
  );
};
