import React, { forwardRef, useCallback, useEffect, useState } from "react";
import Tabs from "../../../components/Tabs/Tabs";
import CondensingUnitProductItemContainer from "./CondensingUnitProductItem/CondensingUnitProductItemContainer";
import EvaporatorProductItemContainer from "./EvaporatorUnitProductItem/EvaporatorProductItemContainer";
import useCapitalProductService from "../../hooks/useCondensingUnitService";
import useEvaporatorProductService from "../../hooks/useEvaporatorProductsService";
import { CondensingUnitProductCapacity } from "../../../types/CondensingUnitProductCapacity";
import { default as actionSelector } from "../../duck/selectors";
import { useDispatch, useSelector } from "react-redux";
import { EvaporatorProductCapacity } from "../../../types/EvaporatorProductCapacity";
import styles from "./EquipmentSelectionContainer.module.css";
import { findBestMatchWithImage } from "../../../helpers/findBestMatchWithImage";
import { logPageEvent } from "../../../helpers/amplitude";

const EquipmentSelectionContainer = forwardRef<HTMLHeadingElement, {}>(
  (_, ref) => {
    const dispatch = useDispatch();
    const [selectedTab, selectTab] = useState("Condensing Units");
    const { condenserUnitService } = useCapitalProductService();
    const { evaporatorService } = useEvaporatorProductService();

    const searchEvaporators = useCallback(
      (selectedCondensingUnit: CondensingUnitProductCapacity) => {
        dispatch(actionSelector.loadingBalancePerformance(true));
        dispatch(actionSelector.searchEvaporators(selectedCondensingUnit));
      },
      [dispatch]
    );

    const searchEvaporatorsOnCondensingUnitSelect = useCallback(
      (selectedCondensingUnit: CondensingUnitProductCapacity) => {
        dispatch(actionSelector.loadingBalancePerformance(true));
        dispatch(actionSelector.selectCondensingUnit(selectedCondensingUnit));
        dispatch(actionSelector.searchEvaporators(selectedCondensingUnit));
      },
      [dispatch]
    );

    const selectCondensingUnit = useCallback(
      (selectedCondensingUnit: CondensingUnitProductCapacity) => {
        dispatch(actionSelector.loadingBalancePerformance(true));
        dispatch(actionSelector.selectCondensingUnit(selectedCondensingUnit));
        dispatch(actionSelector.loadingBalancePerformance(false));
      },
      [dispatch]
    );

    const selectEvaporator = useCallback(
      (
        selectedEvaporator: EvaporatorProductCapacity,
        triggerLoading: boolean
      ) => {
        if (triggerLoading) {
          dispatch(actionSelector.loadingBalancePerformance(true));
        }
        dispatch(actionSelector.selectEvaporator(selectedEvaporator));
        if (triggerLoading) {
          setTimeout(() => {
            dispatch(actionSelector.loadingBalancePerformance(false));
          }, 200);
        }
      },
      [dispatch]
    );

    useEffect(() => {
      if (
        condenserUnitService.status === "loaded" &&
        condenserUnitService.payload.results.length
      ) {
        let bestMatch = findBestMatchWithImage(
          condenserUnitService.payload.results,
          condenserUnitService.payload.best_match
        );
        if (bestMatch !== undefined) {
          selectCondensingUnit(bestMatch as CondensingUnitProductCapacity);
        }
      }

      if (condenserUnitService.status === "loaded") {
        dispatch(
          actionSelector.setCondensingUnitSearchStatus(
            condenserUnitService.payload.search_status
          )
        );
      } else {
        dispatch(actionSelector.setCondensingUnitSearchStatus("NOT_FOUND"));
      }
    }, [condenserUnitService, selectCondensingUnit, dispatch]);

    const resetEvaporatorResults = () => {
      if (
        evaporatorService.status === "loaded" &&
        evaporatorService.payload.results.length
      ) {
        evaporatorService.payload.results = [];
      }
    };

    const searchEvaporatorsOnCondensingUnitLoaded = () => {
      if (
        condenserUnitService.status === "loaded" &&
        condenserUnitService.payload.results.length &&
        condenserUnitService.payload.best_match !== undefined
      ) {
        searchEvaporators(condenserUnitService.payload.best_match);
      }
    };

    const resetEvaporatorSearchStatus = () => {
      // If condensing unit service has loaded but there are no results
      // OR
      // when condensing unit service is NOT loaded
      // SET evaporator search status back to NOT_FOUND
      if (
        (condenserUnitService.status === "loaded" &&
          condenserUnitService.payload.results.length === 0) ||
        condenserUnitService.status !== "loaded"
      ) {
        dispatch(actionSelector.setEvaporatorSearchStatus("NOT_FOUND"));
      }
    };

    // not to make 2 search requests, image enrichment is ignored since it does not change status
    useEffect(() => {
      resetEvaporatorResults();
      searchEvaporatorsOnCondensingUnitLoaded();
      resetEvaporatorSearchStatus();
      // eslint-disable-next-line
    }, [condenserUnitService.status]);

    useEffect(() => {
      if (
        evaporatorService.status === "loaded" &&
        evaporatorService.payload.results.length
      ) {
        let bestMatch = findBestMatchWithImage(
          evaporatorService.payload.results,
          evaporatorService.payload.best_match
        );
        if (bestMatch !== undefined) {
          selectEvaporator(bestMatch as EvaporatorProductCapacity, false);
        }
      }

      if (evaporatorService.status === "loaded") {
        dispatch(
          actionSelector.setEvaporatorSearchStatus(
            evaporatorService.payload.search_status
          )
        );
      } else {
        dispatch(actionSelector.setEvaporatorSearchStatus("NOT_FOUND"));
      }
    }, [evaporatorService, selectEvaporator, dispatch]);

    const selectedCondensingUnit: CondensingUnitProductCapacity = useSelector(
      (state: any) => state.product.selectedCondensingUnit
    );

    const selectedEvaporator: EvaporatorProductCapacity = useSelector(
      (state: any) => state.product.selectedEvaporator
    );

    return (
      <section>
        <div ref={ref} className={styles.resultsContainer}>
          <Tabs
            tabs={["Condensing Units", "Evaporators"]}
            selectedTab={selectedTab}
            onSelect={(e) => {
              selectTab(e.selectedTabName);
              logPageEvent(`${e.selectedTabName} results tab`);
            }}
            testId="equipment-selection-tab-field"
          />
          {selectedTab === "Condensing Units" ? (
            <CondensingUnitProductItemContainer
              condenserUnitService={condenserUnitService}
              handleCondenserUnitSelect={
                searchEvaporatorsOnCondensingUnitSelect
              }
              selectedCondensingUnit={selectedCondensingUnit}
            />
          ) : (
            <EvaporatorProductItemContainer
              evaporatorService={evaporatorService}
              handleEvaporatorSelect={selectEvaporator}
              selectedEvaporator={selectedEvaporator}
            />
          )}
        </div>
      </section>
    );
  }
);

export default EquipmentSelectionContainer;
